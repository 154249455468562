export const PDC_UNIT_TYPES = {
    MONOBLOCK: {text: 'Monobloc', value: 1},
    SPLIT: {text: 'Split', value: 2},
}

export const PDC_MANUFACTURERS = {
    SISTEMA: {text: 'Sistema', value: 1},
    DOMUSA: {text: 'Domusa', value: 2},
    MITSUBISHI: {text: 'Mitsubishi', value: 3},
}

export const PDC_CURRENTS = {
    MONO: {text: 'Monofazic', value: 1},
    TRI: {text: 'Trifazic', value: 2}
}


export const pdcUnits = [
    {
        product_code: '1001.08.57',
        name: "Unitate exterioara \"Eco Inverter\" 4,0 kw (220V) 1x16A - nou",
        type: PDC_UNIT_TYPES.SPLIT.value,
        manufacturer: PDC_MANUFACTURERS.MITSUBISHI.value,
        current: PDC_CURRENTS.MONO.value,
        pump_power: {
            "-15": 4.3,
            "-7": 5,
            "2": 4,
            "7": 4,
        },
        availableInternalUnits: ['1004.06.57', '1003.04.57'],
    },
    {
        product_code: '1001.09.57',
        name: "Unitate exterioara \"Eco Inverter\" 6,0 kw (220V) 1x16A - nou",
        type: PDC_UNIT_TYPES.SPLIT.value,
        manufacturer: PDC_MANUFACTURERS.MITSUBISHI.value,
        current: PDC_CURRENTS.MONO.value,
        pump_power: {
            "-15": 5.7,
            "-7": 6,
            "2": 5,
            "7": 6,
        },
        availableInternalUnits: ['1004.06.57', '1003.04.57'],
    },
    {
        product_code: '1001.10.57',
        name: "Unitate exterioara \"Eco Inverter\" 7,5 kw (220V) 1x16A - nou",
        type: PDC_UNIT_TYPES.SPLIT.value,
        manufacturer: PDC_MANUFACTURERS.MITSUBISHI.value,
        current: PDC_CURRENTS.MONO.value,
        pump_power: {
            "-15": 6,
            "-7": 6.8,
            "2": 6.5,
            "7": 7.5,
        },
        availableInternalUnits: ['1004.06.57', '1003.04.57'],
    },
    {
        product_code: '1002.07.57',
        name: "Unitate exterioara \"Zubadan\" 8,0 kw (380V) 3x16A (R32 cu racire)",
        type: PDC_UNIT_TYPES.SPLIT.value,
        manufacturer: PDC_MANUFACTURERS.MITSUBISHI.value,
        current: PDC_CURRENTS.TRI.value,
        pump_power: {
            "-15": 8,
            "-7": 8,
            "2": 8,
            "7": 6,
        },
        availableInternalUnits: ['1004.08.57', '1003.05.57'],
    },
    {
        product_code: '1002.08.57',
        name: "Unitate exterioara \"Zubadan\" 10,0 kw (380V) 3x16A (R32 cu racire)",
        type: PDC_UNIT_TYPES.SPLIT.value,
        manufacturer: PDC_MANUFACTURERS.MITSUBISHI.value,
        current: PDC_CURRENTS.TRI.value,
        pump_power: {
            "-15": 10,
            "-7": 10,
            "2": 10,
            "7": 8,
        },
        availableInternalUnits: ['1004.08.57', '1003.05.57'],
    },
    {
        product_code: '1002.09.57',
        name: "Unitate exterioara \"Zubadan\" 12,0 kw (380V) 3x16A (R32 cu racire)",
        type: PDC_UNIT_TYPES.SPLIT.value,
        manufacturer: PDC_MANUFACTURERS.MITSUBISHI.value,
        current: PDC_CURRENTS.TRI.value,
        pump_power: {
            "-15": 12,
            "-7": 12,
            "2": 12,
            "7": 10,
        },
        availableInternalUnits: ['1004.08.57', '1003.05.57'],
    },
    {
        product_code: '1002.10.57',
        name: "Unitate exterioara \"Zubadan\" 14,0 kw (380V) 3x16A (R32 cu racire)",
        type: PDC_UNIT_TYPES.SPLIT.value,
        manufacturer: PDC_MANUFACTURERS.MITSUBISHI.value,
        current: PDC_CURRENTS.TRI.value,
        pump_power: {
            "-15": 14,
            "-7": 14,
            "2": 14,
            "7": 12,
        },
        availableInternalUnits: ['1004.08.57', '1003.05.57'],
    },
    {
        product_code: '1002.06.57',
        name: "Unitate exterioara \"Zubadan\" 23,0 kw (380V) 3x25A",
        type: PDC_UNIT_TYPES.SPLIT.value,
        manufacturer: PDC_MANUFACTURERS.MITSUBISHI.value,
        current: PDC_CURRENTS.TRI.value,
        pump_power: {
            "-15": 22.9,
            "-7": 23,
            "2": 23,
            "7": 23,
        },
        availableInternalUnits: [ '1003.03.57' ],
    },
    {
        product_code: '1010.09.94',
        name: "Pompa de caldura \"Sistema-Home D\" H/C 4 Kw",
        type: PDC_UNIT_TYPES.MONOBLOCK.value,
        manufacturer: PDC_MANUFACTURERS.SISTEMA.value,
        current: PDC_CURRENTS.MONO.value,
        pump_power: {
            "-15": 3.95,
            "-7": 3.96,
            "2": 4.37,
            "7": 4.76,
        },
    },
    {
        product_code: '1010.11.94',
        name: "Pompa de caldura \"Sistema-Home D\" H/C 6 Kw",
        type: PDC_UNIT_TYPES.MONOBLOCK.value,
        manufacturer: PDC_MANUFACTURERS.SISTEMA.value,
        current: PDC_CURRENTS.MONO.value,
        pump_power: {
            "-15": 5.85,
            "-7": 6,
            "2": 5.92,
            "7": 6.08,
        },
    },
    {
        product_code: '1010.13.94',
        name: "Pompa de caldura \"Sistema-Home D\" H/C 8 Kw",
        type: PDC_UNIT_TYPES.MONOBLOCK.value,
        manufacturer: PDC_MANUFACTURERS.SISTEMA.value,
        current: PDC_CURRENTS.MONO.value,
        pump_power: {
            "-15": 6.48,
            "-7": 6.6,
            "2": 6.61,
            "7": 7.81,
        },
    },
    {
        product_code: '1010.15.94',
        name: "Pompa de caldura \"Sistema-Home D\" H/C 10 Kw",
        type: PDC_UNIT_TYPES.MONOBLOCK.value,
        manufacturer: PDC_MANUFACTURERS.SISTEMA.value,
        current: PDC_CURRENTS.MONO.value,
        pump_power: {
            "-15": 8.25,
            "-7": 8.3,
            "2": 9.5,
            "7": 10.1,
        },
    },
    {
        product_code: '1010.17.94',
        name: "Pompa de caldura \"Sistema-Home DT\" H/C 10 Kw",
        type: PDC_UNIT_TYPES.MONOBLOCK.value,
        manufacturer: PDC_MANUFACTURERS.SISTEMA.value,
        current: PDC_CURRENTS.TRI.value,
        pump_power: {
            "-15": 8.25,
            "-7": 8.3,
            "2": 9.5,
            "7": 10.1,
        },
    },
    {
        product_code: '1010.19.94',
        name: "Pompa de caldura \"Sistema-Home D\" H/C 12 Kw",
        type: PDC_UNIT_TYPES.MONOBLOCK.value,
        manufacturer: PDC_MANUFACTURERS.SISTEMA.value,
        current: PDC_CURRENTS.MONO.value,
        pump_power: {
            "-15": 8.94,
            "-7": 8.9,
            "2": 10.3,
            "7": 11.8,
        },
    },
    {
        product_code: '1010.21.94',
        name: "Pompa de caldura \"Sistema-Home DT\" H/C 12 Kw",
        type: PDC_UNIT_TYPES.MONOBLOCK.value,
        manufacturer: PDC_MANUFACTURERS.SISTEMA.value,
        current: PDC_CURRENTS.TRI.value,
        pump_power: {
            "-15": 8.94,
            "-7": 8.9,
            "2": 10.3,
            "7": 11.8,
        },
    },
    {
        product_code: '1010.23.94',
        name: "Pompa de caldura \"Sistema-Home D\" H/C 14 Kw",
        type: PDC_UNIT_TYPES.MONOBLOCK.value,
        manufacturer: PDC_MANUFACTURERS.SISTEMA.value,
        current: PDC_CURRENTS.MONO.value,
        pump_power: {
            "-15": 10.8,
            "-7": 10.7,
            "2": 13,
            "7": 14.1,
        },
    },
    {
        product_code: '1010.25.94',
        name: "Pompa de caldura \"Sistema-Home DT\" H/C 14 Kw",
        type: PDC_UNIT_TYPES.MONOBLOCK.value,
        manufacturer: PDC_MANUFACTURERS.SISTEMA.value,
        current: PDC_CURRENTS.TRI.value,
        pump_power: {
            "-15": 10.8,
            "-7": 10.7,
            "2": 13,
            "7": 14.1,
        },
    },
    {
        product_code: '1010.27.94',
        name: "Pompa de caldura \"Sistema-Home D\" H/C 16 Kw",
        type: PDC_UNIT_TYPES.MONOBLOCK.value,
        manufacturer: PDC_MANUFACTURERS.SISTEMA.value,
        current: PDC_CURRENTS.MONO.value,
        pump_power: {
            "-15": 10.2,
            "-7": 12,
            "2": 14.1,
            "7": 16.3,
        },
    },
    {
        product_code: '1010.29.94',
        name: "Pompa de caldura \"Sistema-Home DT\" H/C 16 Kw",
        type: PDC_UNIT_TYPES.MONOBLOCK.value,
        manufacturer: PDC_MANUFACTURERS.SISTEMA.value,
        current: PDC_CURRENTS.TRI.value,
        pump_power: {
            "-15": 10.2,
            "-7": 12,
            "2": 14.1,
            "7": 16.3,
        },
    },
    {
        product_code: '1010.31.94',
        name: "Pompa de caldura \"Sistema-Home DT\" H/C 18 Kw",
        type: PDC_UNIT_TYPES.MONOBLOCK.value,
        manufacturer: PDC_MANUFACTURERS.SISTEMA.value,
        current: PDC_CURRENTS.TRI.value,
        pump_power: {
            "-15": 11.8,
            "-7": 12.6,
            "2": 15.1,
            "7": 17.9,
        },
    },
    {
        product_code: '1010.01.85',
        name: "Pompa de caldura Aer-Apa 6,21 kW (220V)",
        type: PDC_UNIT_TYPES.MONOBLOCK.value,
        manufacturer: PDC_MANUFACTURERS.DOMUSA.value,
        current: PDC_CURRENTS.MONO.value,
        pump_power: {
            "-15": 3.5
        },
    },
    {
        product_code: '1010.06.85',
        name: "Pompa de caldura Aer-Apa 16,80 kW (380V)",
        type: PDC_UNIT_TYPES.MONOBLOCK.value,
        manufacturer: PDC_MANUFACTURERS.DOMUSA.value,
        current: PDC_CURRENTS.TRI.value,
        pump_power: {
            "-15": 9
        },
    },
]

export const pdcInternalUnits = [
    {
        product_code: "1003.02.57",
        name: "Unitate interioara \"Hydrobox N14\"",
    },
    {
        product_code: "1003.03.57",
        name: "Unitate interioara \"Hydrobox N23\"",
    },
    {
        product_code: "1003.04.57",
        name: "Unitate interioara \"Hydrobox P7\"",
    },
    {
        product_code: "1003.05.57",
        name: "Unitate interioara \"Hydrobox P14\" - model nou",
    },
    {
        product_code: "1004.03.57",
        name: "Unitate interioara \"Hydrotank N14 180\"",
    },
    {
        product_code: "1004.04.57",
        name: "Unitate interioara \"Hydrotank N14 230\"",
    },
    {
        product_code: "1004.05.57",
        name: "Unitate interioara \"Hydrotank P7 180\"",
    },
    {
        product_code: "1004.06.57",
        name: "Unitate interioara \"Hydrotank P7 230\"",
    },
    {
        product_code: "1004.07.57",
        name: "Unitate interioara \"Hydrotank P14 180\" - model nou",
    },
    {
        product_code: "1004.08.57",
        name: "Unitate interioara \"Hydrotank P14 230\" - model nou",
    },
]