import {PDC_UNIT_TYPES, PDC_MANUFACTURERS, PDC_CURRENTS} from "@/PDC/pdcData.js"


export const pdcProducts = [
    {
        "id": 1,
        "product_code": "1001.08.57",
        "name": "Unitate exterioara \"Eco Inverter\" 4,0 kw (220V) 1x16A - nou",
        "type": "pdcUnit",
        "specifications": {
            "type": PDC_UNIT_TYPES.SPLIT.value,
            "manufacturer": PDC_MANUFACTURERS.MITSUBISHI.value,
            "current": PDC_CURRENTS.MONO.value,
            "pump_power": 4.3,
        },
        "requirements": [
            {
                "type": "tablou",
                "subtype": "mono",
                "variant": "16A",
                "quantity": 1,
            },
            {
                "type": "puffer",
                "subtype": "serpentina",
                "variant": "100",
                "quantity": 1,
            },
            {
                "type": "teava",
                "subtype": "cupru",
                "variant": "izolata",
                "diameter": "1/4\"",
                "quantity": 5,
            },
            {
                "type": "teava",
                "subtype": "cupru",
                "variant": "izolata",
                "diameter": "1/2\"",
                "quantity": 5,
            },
            {
                "type": "tampon",
                "subtype": "cauciuc",
                "quantity": 4,
            },
            {
                "type": "timbru",
                "subtype": "verde",
                "variant": 95.20,
                "quantity": 1,
            },
        ]
    },
    {
        "id": 2,
        "product_code": "1001.09.57",
        "name": "Unitate exterioara \"Eco Inverter\" 6,0 kw (220V) 1x16A - nou",
        "type": "pdcUnit",
        "specifications": {
            "type": PDC_UNIT_TYPES.SPLIT.value,
            "manufacturer": PDC_MANUFACTURERS.MITSUBISHI.value,
            "current": PDC_CURRENTS.MONO.value,
            "pump_power": 5.7,
        },
        "requirements": [
            {
                "type": "tablou",
                "subtype": "mono",
                "variant": "16A",
                "quantity": 1,
            },
            {
                "type": "puffer",
                "subtype": "serpentina",
                "variant": "100",
                "quantity": 1,
            },
            {
                "type": "teava",
                "subtype": "cupru",
                "variant": "izolata",
                "diameter": "1/4\"",
                "quantity": 5,
            },
            {
                "type": "teava",
                "subtype": "cupru",
                "variant": "izolata",
                "diameter": "1/2\"",
                "quantity": 5,
            },
            {
                "type": "tampon",
                "subtype": "cauciuc",
                "quantity": 4,
            },
            {
                "type": "timbru",
                "subtype": "verde",
                "variant": 95.20,
                "quantity": 1,
            },
        ]
    },
    {
        "id": 3,
        "product_code": "1001.10.57",
        "name": "Unitate exterioara \"Eco Inverter\" 7,5 kw (220V) 1x16A - nou",
        "type": "pdcUnit",
        "specifications": {
            "type": PDC_UNIT_TYPES.SPLIT.value,
            "manufacturer": PDC_MANUFACTURERS.MITSUBISHI.value,
            "current": PDC_CURRENTS.MONO.value,
            "pump_power": 6,
        },
        "requirements": [
            {
                "type": "tablou",
                "subtype": "mono",
                "variant": "16A",
                "quantity": 1,
            },
            {
                "type": "puffer",
                "subtype": "serpentina",
                "variant": "100",
                "quantity": 1,
            },
            {
                "type": "teava",
                "subtype": "cupru",
                "variant": "izolata",
                "diameter": "1/4\"",
                "quantity": 5,
            },
            {
                "type": "teava",
                "subtype": "cupru",
                "variant": "izolata",
                "diameter": "1/2\"",
                "quantity": 5,
            },
            {
                "type": "tampon",
                "subtype": "cauciuc",
                "quantity": 4,
            },
            {
                "type": "timbru",
                "subtype": "verde",
                "variant": 95.20,
                "quantity": 1,
            },
        ]
    },
    {
        "id": 4,
        "product_code": "1002.07.57",
        "name": "Unitate exterioara \"Zubadan\" 8,0 kw (380V) 3x16A (R32 cu racire)",
        "type": "pdcUnit",
        "specifications": {
            "type": PDC_UNIT_TYPES.SPLIT.value,
            "manufacturer": PDC_MANUFACTURERS.MITSUBISHI.value,
            "current": PDC_CURRENTS.TRI.value,
            "pump_power": 8,
        },
        "requirements": [
            {
                "type": "tablou",
                "subtype": "tri",
                "variant": "16A",
                "quantity": 1,
            },
            {
                "type": "puffer",
                "subtype": "serpentina",
                "variant": "100",
                "quantity": 1,
            },
            {
                "type": "teava",
                "subtype": "cupru",
                "variant": "izolata",
                "diameter": "1/4\"",
                "quantity": 5,
            },
            {
                "type": "teava",
                "subtype": "cupru",
                "variant": "izolata",
                "diameter": "1/2\"",
                "quantity": 5,
            },
            {
                "type": "tampon",
                "subtype": "cauciuc",
                "quantity": 4,
            },
            {
                "type": "timbru",
                "subtype": "verde",
                "variant": 95.20,
                "quantity": 1,
            },
        ]
    },
    {
        "id": 5,
        "product_code": "1002.08.57",
        "name": "Unitate exterioara \"Zubadan\" 10,0 kw (380V) 3x16A (R32 cu racire)",
        "type": "pdcUnit",
        "specifications": {
            "type": PDC_UNIT_TYPES.SPLIT.value,
            "manufacturer": PDC_MANUFACTURERS.MITSUBISHI.value,
            "current": PDC_CURRENTS.TRI.value,
            "pump_power": 10,
        },
        "requirements": [
            {
                "type": "tablou",
                "subtype": "tri",
                "variant": "16A",
                "quantity": 1,
            },
            {
                "type": "puffer",
                "subtype": "serpentina",
                "variant": "100",
                "quantity": 1,
            },
            {
                "type": "teava",
                "subtype": "cupru",
                "variant": "izolata",
                "diameter": "1/4\"",
                "quantity": 5,
            },
            {
                "type": "teava",
                "subtype": "cupru",
                "variant": "izolata",
                "diameter": "1/2\"",
                "quantity": 5,
            },
            {
                "type": "tampon",
                "subtype": "cauciuc",
                "quantity": 4,
            },
            {
                "type": "timbru",
                "subtype": "verde",
                "variant": 95.20,
                "quantity": 1,
            },
        ]
    },
    {
        "id": 6,
        "product_code": "1002.09.57",
        "name": "Unitate exterioara \"Zubadan\" 12,0 kw (380V) 3x16A (R32 cu racire)",
        "type": "pdcUnit",
        "specifications": {
            "type": PDC_UNIT_TYPES.SPLIT.value,
            "manufacturer": PDC_MANUFACTURERS.MITSUBISHI.value,
            "current": PDC_CURRENTS.TRI.value,
            "pump_power": 12,
        },
        "requirements": [
            {
                "type": "tablou",
                "subtype": "tri",
                "variant": "16A",
                "quantity": 1,
            },
            {
                "type": "puffer",
                "subtype": "serpentina",
                "variant": "100",
                "quantity": 1,
            },
            {
                "type": "teava",
                "subtype": "cupru",
                "variant": "izolata",
                "diameter": "1/4\"",
                "quantity": 5,
            },
            {
                "type": "teava",
                "subtype": "cupru",
                "variant": "izolata",
                "diameter": "1/2\"",
                "quantity": 5,
            },
            {
                "type": "tampon",
                "subtype": "cauciuc",
                "quantity": 4,
            },
            {
                "type": "timbru",
                "subtype": "verde",
                "variant": 95.20,
                "quantity": 1,
            },
        ]
    },
    {
        "id": 7,
        "product_code": "1002.10.57",
        "name": "Unitate exterioara \"Zubadan\" 14,0 kw (380V) 3x16A (R32 cu racire)",
        "type": "pdcUnit",
        "specifications": {
            "type": PDC_UNIT_TYPES.SPLIT.value,
            "manufacturer": PDC_MANUFACTURERS.MITSUBISHI.value,
            "current": PDC_CURRENTS.TRI.value,
            "pump_power": 14,
        },
        "requirements": [
            {
                "type": "tablou",
                "subtype": "tri",
                "variant": "16A",
                "quantity": 1,
            },
            {
                "type": "puffer",
                "subtype": "serpentina",
                "variant": "100",
                "quantity": 1,
            },
            {
                "type": "teava",
                "subtype": "cupru",
                "variant": "izolata",
                "diameter": "1/4\"",
                "quantity": 5,
            },
            {
                "type": "teava",
                "subtype": "cupru",
                "variant": "izolata",
                "diameter": "1/2\"",
                "quantity": 5,
            },
            {
                "type": "tampon",
                "subtype": "cauciuc",
                "quantity": 4,
            },
            {
                "type": "timbru",
                "subtype": "verde",
                "variant": 95.20,
                "quantity": 1,
            },
        ]
    },
    {
        "id": 8,
        "product_code": "1002.06.57",
        "name": "Unitate exterioara \"Zubadan\" 23,0 kw (380V) 3x25A",
        "type": "pdcUnit",
        "specifications": {
            "type": PDC_UNIT_TYPES.SPLIT.value,
            "manufacturer": PDC_MANUFACTURERS.MITSUBISHI.value,
            "current": PDC_CURRENTS.TRI.value,
            "pump_power": 22.9,
        },
        "requirements": [
            {
                "type": "tablou",
                "subtype": "tri",
                "variant": "25A",
                "quantity": 1,
            },
            {
                "type": "puffer",
                "subtype": "serpentina",
                "variant": "100",
                "quantity": 1,
            },
            {
                "type": "teava",
                "subtype": "cupru",
                "variant": "izolata",
                "diameter": "3/4\"",
                "quantity": 5,
            },
            {
                "type": "teava",
                "subtype": "cupru",
                "variant": "izolata",
                "diameter": "1/2\"",
                "quantity": 5,
            },
            {
                "type": "tampon",
                "subtype": "cauciuc",
                "quantity": 4,
            },
            {
                "type": "timbru",
                "subtype": "verde",
                "variant": 95.20,
                "quantity": 1,
            },
        ]
    },
    {
        "id": 9,
        "product_code": "1010.09.94",
        "name": "Pompa de caldura \"Sistema-Home D\" H/C 4 Kw 1x16A",
        "type": "pdcUnit",
        "specifications": {
            "type": PDC_UNIT_TYPES.MONOBLOCK.value,
            "manufacturer": PDC_MANUFACTURERS.SISTEMA.value,
            "current": PDC_CURRENTS.MONO.value,
            "pump_power": 3.95,
        },
        "requirements": [
            {
                "type": "senzor",
                "subtype": "ACM",
                "variant": "sistema",
                "quantity": 1,
            },
            {
                "type": "tablou",
                "subtype": "mono",
                "variant": "16A",
                "quantity": 1,
            },
            {
                "type": "boiler",
                "subtype": "serpentina",
                "variant": "300",
                "quantity": 1,
            },
            {
                "type": "puffer",
                "subtype": "serpentina",
                "variant": "100",
                "quantity": 1,
            },
            {
                "type": "vas",
                "subtype": "expansiune",
                "variant": "24",
                "quantity": 2,
            },
            {
                "type": "filtru",
                "subtype": "magnetita",
                "variant": "3/4",
                "quantity": 1,
            },
            {
                "type": "vana",
                "subtype": "deviatie",
                "variant": "1",
                "quantity": 1,
            },
            {
                "type": "robinet",
                "subtype": "bila",
                "variant": "1/4",
                "quantity": 2,
            },
            {
                "type": "tampon",
                "subtype": "cauciuc",
                "quantity": 4,
            },
            {
                "type": "timbru",
                "subtype": "verde",
                "variant": 95.20,
                "quantity": 1,
            },
        ]
    },
    {
        "id": 10,
        "product_code": "1010.11.94",
        "name": "Pompa de caldura \"Sistema-Home D\" H/C 6 Kw",
        "type": "pdcUnit",
        "specifications": {
            "type": PDC_UNIT_TYPES.MONOBLOCK.value,
            "manufacturer": PDC_MANUFACTURERS.SISTEMA.value,
            "current": PDC_CURRENTS.MONO.value,
            "pump_power": 5.85,
        },
        "requirements": [
            {
                "type": "senzor",
                "subtype": "ACM",
                "variant": "sistema",
                "quantity": 1,
            },
            {
                "type": "tablou",
                "subtype": "mono",
                "variant": "16A",
                "quantity": 1,
            },
            {
                "type": "boiler",
                "subtype": "serpentina",
                "variant": "300",
                "quantity": 1,
            },
            {
                "type": "puffer",
                "subtype": "serpentina",
                "variant": "100",
                "quantity": 1,
            },
            {
                "type": "vas",
                "subtype": "expansiune",
                "variant": "24",
                "quantity": 2,
            },
            {
                "type": "filtru",
                "subtype": "magnetita",
                "variant": "3/4",
                "quantity": 1,
            },
            {
                "type": "vana",
                "subtype": "deviatie",
                "variant": "1",
                "quantity": 1,
            },
            {
                "type": "robinet",
                "subtype": "bila",
                "variant": "1/4",
                "quantity": 2,
            },
            {
                "type": "tampon",
                "subtype": "cauciuc",
                "quantity": 4,
            },
            {
                "type": "timbru",
                "subtype": "verde",
                "variant": 95.20,
                "quantity": 1,
            },
        ]
    },
    {
        "id": 11,
        "product_code": "1010.13.94",
        "name": "Pompa de caldura \"Sistema-Home D\" H/C 8 Kw",
        "type": "pdcUnit",
        "specifications": {
            "type": PDC_UNIT_TYPES.MONOBLOCK.value,
            "manufacturer": PDC_MANUFACTURERS.SISTEMA.value,
            "current": PDC_CURRENTS.MONO.value,
            "pump_power": 6.48,
        },
        "requirements": [
            {
                "type": "senzor",
                "subtype": "ACM",
                "variant": "sistema",
                "quantity": 1,
            },
            {
                "type": "tablou",
                "subtype": "mono",
                "variant": "25A",
                "quantity": 1,
            },
            {
                "type": "boiler",
                "subtype": "serpentina",
                "variant": "300",
                "quantity": 1,
            },
            {
                "type": "puffer",
                "subtype": "serpentina",
                "variant": "100",
                "quantity": 1,
            },
            {
                "type": "vas",
                "subtype": "expansiune",
                "variant": "24",
                "quantity": 2,
            },
            {
                "type": "filtru",
                "subtype": "magnetita",
                "variant": "3/4",
                "quantity": 1,
            },
            {
                "type": "vana",
                "subtype": "deviatie",
                "variant": "1",
                "quantity": 1,
            },
            {
                "type": "robinet",
                "subtype": "bila",
                "variant": "1/4",
                "quantity": 2,
            },
            {
                "type": "tampon",
                "subtype": "cauciuc",
                "quantity": 4,
            },
            {
                "type": "timbru",
                "subtype": "verde",
                "variant": 95.20,
                "quantity": 1,
            },
        ]
    },
    {
        "id": 12,
        "product_code": "1010.15.94",
        "name": "Pompa de caldura \"Sistema-Home D\" H/C 10 Kw",
        "type": "pdcUnit",
        "specifications": {
            "type": PDC_UNIT_TYPES.MONOBLOCK.value,
            "manufacturer": PDC_MANUFACTURERS.SISTEMA.value,
            "current": PDC_CURRENTS.MONO.value,
            "pump_power": 8.25,
        },
        "requirements": [
            {
                "type": "senzor",
                "subtype": "ACM",
                "variant": "sistema",
                "quantity": 1,
            },
            {
                "type": "tablou",
                "subtype": "mono",
                "variant": "25A",
                "quantity": 1,
            },
            {
                "type": "boiler",
                "subtype": "serpentina",
                "variant": "300",
                "quantity": 1,
            },
            {
                "type": "puffer",
                "subtype": "serpentina",
                "variant": "100",
                "quantity": 1,
            },
            {
                "type": "vas",
                "subtype": "expansiune",
                "variant": "24",
                "quantity": 2,
            },
            {
                "type": "filtru",
                "subtype": "easy",
                "variant": "1",
                "quantity": 1,
            },
            {
                "type": "vana",
                "subtype": "deviatie",
                "variant": "1",
                "quantity": 1,
            },
            {
                "type": "robinet",
                "subtype": "bila",
                "variant": "1/4",
                "quantity": 2,
            },
            {
                "type": "tampon",
                "subtype": "cauciuc",
                "quantity": 4,
            },
            {
                "type": "timbru",
                "subtype": "verde",
                "variant": 95.20,
                "quantity": 1,
            },
        ]
    },
    {
        "id": 13,
        "product_code": "1010.17.94",
        "name": "Pompa de caldura \"Sistema-Home DT\" H/C 10 Kw",
        "type": "pdcUnit",
        "specifications": {
            "type": PDC_UNIT_TYPES.MONOBLOCK.value,
            "manufacturer": PDC_MANUFACTURERS.SISTEMA.value,
            "current": PDC_CURRENTS.TRI.value,
            "pump_power": 8.25,
        },
        "requirements": [
            {
                "type": "senzor",
                "subtype": "ACM",
                "variant": "sistema",
                "quantity": 1,
            },
            {
                "type": "tablou",
                "subtype": "tri",
                "variant": "10A",
                "quantity": 1,
            },
            {
                "type": "boiler",
                "subtype": "serpentina",
                "variant": "300",
                "quantity": 1,
            },
            {
                "type": "puffer",
                "subtype": "serpentina",
                "variant": "100",
                "quantity": 1,
            },
            {
                "type": "vas",
                "subtype": "expansiune",
                "variant": "24",
                "quantity": 2,
            },
            {
                "type": "filtru",
                "subtype": "easy",
                "variant": "1",
                "quantity": 1,
            },
            {
                "type": "vana",
                "subtype": "deviatie",
                "variant": "1",
                "quantity": 1,
            },
            {
                "type": "robinet",
                "subtype": "bila",
                "variant": "1/4",
                "quantity": 2,
            },
            {
                "type": "tampon",
                "subtype": "cauciuc",
                "quantity": 4,
            },
            {
                "type": "timbru",
                "subtype": "verde",
                "variant": 95.20,
                "quantity": 1,
            },
        ]
    },
    {
        "id": 14,
        "product_code": "1010.19.94",
        "name": "Pompa de caldura \"Sistema-Home D\" H/C 12 Kw",
        "type": "pdcUnit",
        "specifications": {
            "type": PDC_UNIT_TYPES.MONOBLOCK.value,
            "manufacturer": PDC_MANUFACTURERS.SISTEMA.value,
            "current": PDC_CURRENTS.MONO.value,
            "pump_power": 8.94,
        },
        "requirements": [
            {
                "type": "senzor",
                "subtype": "ACM",
                "variant": "sistema",
                "quantity": 1,
            },
            {
                "type": "tablou",
                "subtype": "mono",
                "variant": "25A",
                "quantity": 1,
            },
            {
                "type": "boiler",
                "subtype": "serpentina",
                "variant": "300",
                "quantity": 1,
            },
            {
                "type": "puffer",
                "subtype": "serpentina",
                "variant": "100",
                "quantity": 1,
            },
            {
                "type": "vas",
                "subtype": "expansiune",
                "variant": "24",
                "quantity": 2,
            },
            {
                "type": "filtru",
                "subtype": "easy",
                "variant": "1",
                "quantity": 1,
            },
            {
                "type": "vana",
                "subtype": "deviatie",
                "variant": "1",
                "quantity": 1,
            },
            {
                "type": "robinet",
                "subtype": "bila",
                "variant": "1/4",
                "quantity": 2,
            },
            {
                "type": "tampon",
                "subtype": "cauciuc",
                "quantity": 4,
            },
            {
                "type": "timbru",
                "subtype": "verde",
                "variant": 95.20,
                "quantity": 1,
            },
        ]
    },
    {
        "id": 15,
        "product_code": "1010.21.94",
        "name": "Pompa de caldura \"Sistema-Home DT\" H/C 12 Kw",
        "type": "pdcUnit",
        "specifications": {
            "type": PDC_UNIT_TYPES.MONOBLOCK.value,
            "manufacturer": PDC_MANUFACTURERS.SISTEMA.value,
            "current": PDC_CURRENTS.TRI.value,
            "pump_power": 8.94,
        },
        "requirements": [
            {
                "type": "senzor",
                "subtype": "ACM",
                "variant": "sistema",
                "quantity": 1,
            },
            {
                "type": "tablou",
                "subtype": "tri",
                "variant": "10A",
                "quantity": 1,
            },
            {
                "type": "boiler",
                "subtype": "serpentina",
                "variant": "300",
                "quantity": 1,
            },
            {
                "type": "puffer",
                "subtype": "serpentina",
                "variant": "100",
                "quantity": 1,
            },
            {
                "type": "vas",
                "subtype": "expansiune",
                "variant": "24",
                "quantity": 2,
            },
            {
                "type": "filtru",
                "subtype": "easy",
                "variant": "1",
                "quantity": 1,
            },
            {
                "type": "vana",
                "subtype": "deviatie",
                "variant": "1",
                "quantity": 1,
            },
            {
                "type": "robinet",
                "subtype": "bila",
                "variant": "1/4",
                "quantity": 2,
            },
            {
                "type": "tampon",
                "subtype": "cauciuc",
                "quantity": 4,
            },
            {
                "type": "timbru",
                "subtype": "verde",
                "variant": 95.20,
                "quantity": 1,
            },
        ]
    },
    {
        "id": 16,
        "product_code": "1010.23.94",
        "name": "Pompa de caldura \"Sistema-Home D\" H/C 14 Kw",
        "type": "pdcUnit",
        "specifications": {
            "type": PDC_UNIT_TYPES.MONOBLOCK.value,
            "manufacturer": PDC_MANUFACTURERS.SISTEMA.value,
            "current": PDC_CURRENTS.MONO.value,
            "pump_power": 10.8,
        },
        "requirements": [
            {
                "type": "senzor",
                "subtype": "ACM",
                "variant": "sistema",
                "quantity": 1,
            },
            {
                "type": "tablou",
                "subtype": "mono",
                "variant": "32A",
                "quantity": 1,
            },
            {
                "type": "boiler",
                "subtype": "serpentina",
                "variant": "300",
                "quantity": 1,
            },
            {
                "type": "puffer",
                "subtype": "serpentina",
                "variant": "100",
                "quantity": 1,
            },
            {
                "type": "vas",
                "subtype": "expansiune",
                "variant": "24",
                "quantity": 2,
            },
            {
                "type": "filtru",
                "subtype": "easy",
                "variant": "1",
                "quantity": 1,
            },
            {
                "type": "vana",
                "subtype": "deviatie",
                "variant": "1",
                "quantity": 1,
            },
            {
                "type": "robinet",
                "subtype": "bila",
                "variant": "1/4",
                "quantity": 2,
            },
            {
                "type": "tampon",
                "subtype": "cauciuc",
                "quantity": 4,
            },
            {
                "type": "timbru",
                "subtype": "verde",
                "variant": 95.20,
                "quantity": 1,
            },
        ]
    },
    {
        "id": 17,
        "product_code": "1010.25.94",
        "name": "Pompa de caldura \"Sistema-Home DT\" H/C 14 Kw",
        "type": "pdcUnit",
        "specifications": {
            "type": PDC_UNIT_TYPES.MONOBLOCK.value,
            "manufacturer": PDC_MANUFACTURERS.SISTEMA.value,
            "current": PDC_CURRENTS.TRI.value,
            "pump_power": 10.8,
        },
        "requirements": [
            {
                "type": "senzor",
                "subtype": "ACM",
                "variant": "sistema",
                "quantity": 1,
            },
            {
                "type": "tablou",
                "subtype": "tri",
                "variant": "16A",
                "quantity": 1,
            },
            {
                "type": "boiler",
                "subtype": "serpentina",
                "variant": "300",
                "quantity": 1,
            },
            {
                "type": "puffer",
                "subtype": "serpentina",
                "variant": "100",
                "quantity": 1,
            },
            {
                "type": "vas",
                "subtype": "expansiune",
                "variant": "24",
                "quantity": 2,
            },
            {
                "type": "filtru",
                "subtype": "easy",
                "variant": "1",
                "quantity": 1,
            },
            {
                "type": "vana",
                "subtype": "deviatie",
                "variant": "1",
                "quantity": 1,
            },
            {
                "type": "robinet",
                "subtype": "bila",
                "variant": "1/4",
                "quantity": 2,
            },
            {
                "type": "tampon",
                "subtype": "cauciuc",
                "quantity": 4,
            },
            {
                "type": "timbru",
                "subtype": "verde",
                "variant": 95.20,
                "quantity": 1,
            },
        ]
    },
    {
        "id": 18,
        "product_code": "1010.27.94",
        "name": "Pompa de caldura \"Sistema-Home D\" H/C 16 Kw",
        "type": "pdcUnit",
        "specifications": {
            "type": PDC_UNIT_TYPES.MONOBLOCK.value,
            "manufacturer": PDC_MANUFACTURERS.SISTEMA.value,
            "current": PDC_CURRENTS.MONO.value,
            "pump_power": 10.2,
        },
        "requirements": [
            {
                "type": "timbru",
                "subtype": "verde",
                "variant": 95.20,
                "quantity": 1,
            },
        ]
    },
    {
        "id": 19,
        "product_code": "1010.29.94",
        "name": "Pompa de caldura \"Sistema-Home DT\" H/C 16 Kw",
        "type": "pdcUnit",
        "specifications": {
            "type": PDC_UNIT_TYPES.MONOBLOCK.value,
            "manufacturer": PDC_MANUFACTURERS.SISTEMA.value,
            "current": PDC_CURRENTS.TRI.value,
            "pump_power": 10.2,
        },
        "requirements": [
            {
                "type": "timbru",
                "subtype": "verde",
                "variant": 95.20,
                "quantity": 1,
            },
        ]
    },
    {
        "id": 20,
        "product_code": "1010.31.94",
        "name": "Pompa de caldura \"Sistema-Home DT\" H/C 18 Kw",
        "type": "pdcUnit",
        "specifications": {
            "type": PDC_UNIT_TYPES.MONOBLOCK.value,
            "manufacturer": PDC_MANUFACTURERS.SISTEMA.value,
            "current": PDC_CURRENTS.TRI.value,
            "pump_power": 11.8,
        },
        "requirements": [
            {
                "type": "senzor",
                "subtype": "ACM",
                "variant": "sistema",
                "quantity": 1,
            },
            {
                "type": "tablou",
                "subtype": "tri",
                "variant": "16A",
                "quantity": 1,
            },
            {
                "type": "boiler",
                "subtype": "serpentina",
                "variant": "300",
                "quantity": 1,
            },
            {
                "type": "puffer",
                "subtype": "serpentina",
                "variant": "100",
                "quantity": 1,
            },
            {
                "type": "vas",
                "subtype": "expansiune",
                "variant": "24",
                "quantity": 2,
            },
            {
                "type": "filtru",
                "subtype": "easy",
                "variant": "1",
                "quantity": 1,
            },
            {
                "type": "vana",
                "subtype": "deviatie",
                "variant": "1",
                "quantity": 1,
            },
            {
                "type": "robinet",
                "subtype": "bila",
                "variant": "1/4",
                "quantity": 2,
            },
            {
                "type": "tampon",
                "subtype": "cauciuc",
                "quantity": 4,
            },
            {
                "type": "timbru",
                "subtype": "verde",
                "variant": 95.20,
                "quantity": 1,
            },
        ]
    },
    {
        "id": 21,
        "product_code": "1010.01.85",
        "name": "Pompa de caldura Aer-Apa 6,21 kW (220V)",
        "type": "pdcUnit",
        "specifications": {
            "type": PDC_UNIT_TYPES.MONOBLOCK.value,
            "manufacturer": PDC_MANUFACTURERS.DOMUSA.value,
            "current": PDC_CURRENTS.MONO.value,
            "pump_power": 3.5,
        },
        "requirements": [
            {
                "type": "senzor",
                "subtype": "ACM",
                "variant": "sistema",
                "quantity": 1,
            },
            {
                "type": "tablou",
                "subtype": "mono",
                "variant": "16A",
                "quantity": 1,
            },
            {
                "type": "boiler",
                "subtype": "serpentina",
                "variant": "300",
                "quantity": 1,
            },
            {
                "type": "vas",
                "subtype": "expansiune",
                "variant": "24",
                "quantity": 1,
            },
            {
                "type": "filtru",
                "subtype": "magnetita",
                "variant": "3/4",
                "quantity": 1,
            },
            {
                "type": "vana",
                "subtype": "deviatie",
                "variant": "1",
                "quantity": 1,
            },
            {
                "type": "robinet",
                "subtype": "bila",
                "variant": "1",
                "quantity": 2,
            },
            {
                "type": "timbru",
                "subtype": "verde",
                "variant": 297.50,
                "quantity": 1,
            },
        ]
    },
    {
        "id": 22,
        "product_code": "1010.06.85",
        "name": "Pompa de caldura Aer-Apa 16,80 kW (380V)",
        "type": "pdcUnit",
        "specifications": {
            "type": PDC_UNIT_TYPES.MONOBLOCK.value,
            "manufacturer": PDC_MANUFACTURERS.DOMUSA.value,
            "current": PDC_CURRENTS.TRI.value,
            "pump_power": 9,
        },
        "requirements": [
            {
                "type": "senzor",
                "subtype": "ACM",
                "variant": "sistema",
                "quantity": 1,
            },
            {
                "type": "tablou",
                "subtype": "tri",
                "variant": "10A",
                "quantity": 1,
            },
            {
                "type": "boiler",
                "subtype": "serpentina",
                "variant": "300",
                "quantity": 1,
            },
            {
                "type": "vas",
                "subtype": "expansiune",
                "variant": "24",
                "quantity": 1,
            },
            {
                "type": "filtru",
                "subtype": "easy",
                "variant": "1",
                "quantity": 1,
            },
            {
                "type": "vana",
                "subtype": "deviatie",
                "variant": "1",
                "quantity": 1,
            },
            {
                "type": "robinet",
                "subtype": "bila",
                "variant": "1",
                "quantity": 2,
            },
            {
                "type": "timbru",
                "subtype": "verde",
                "variant": 297.50,
                "quantity": 1,
            },
        ]
    },
    {
        "id": 23,
        "product_code": "1005.07.00",
        "name": "Tablou de siguranta monofazic 16A",
        "type": "tablou",
        "subtype": "mono",
        "specifications": {
            "type": "tablou",
            "subtype": "mono",
            "variant": "16A",
        },
        "requirements": []
    },
    {
        "id": 24,
        "product_code": "1007.17.85",
        "name": "Puffer cu serpentina 100 l",
        "type": "puffer",
        "subtype": "serpentina",
        "specifications": {
            "type": "puffer",
            "subtype": "serpentina",
            "variant": "100",
        },
        "requirements": [
            {
                "type": "rezistenta",
                "subtype": "electrica",
                "heating_power": 3,
                "variant": "1/4",
                "quantity": 1,
            },
        ]
    },
    {
        "id": 25,
        "product_code": "1008.01.37",
        "name": "Teava CU izolata 3/8\"",
        "type": "teava",
        "subtype": "cupru",
        "specifications": {
            "type": "teava",
            "subtype": "cupru",
            "variant": "izolata",
            "diameter": "3/8\","
        },
        "requirements": []
    },
    {
        "id": 26,
        "product_code": "1008.02.37",
        "name": "Teava CU izolata 5/8\"",
        "type": "teava",
        "subtype": "izolata",
        "specifications": {
            "type": "teava",
            "subtype": "cupru",
            "variant": "izolata",
            "diameter": "5/8\""
        },
        "requirements": []
    },
    {
        "id": 27,
        "product_code": "1008.03.37",
        "name": "Teava CU izolata 1/4\"",
        "type": "teava",
        "subtype": "izolata",
        "specifications": {
            "type": "teava",
            "subtype": "cupru",
            "variant": "izolata",
            "diameter": "1/4\"",
        },
        "requirements": []
    },
    {
        "id": 28,
        "product_code": "1008.04.37",
        "name": "Teava CU izolata 1/2\"",
        "type": "teava",
        "subtype": "izolata",
        "specifications": {
            "type": "teava",
            "subtype": "cupru",
            "variant": "izolata",
            "diameter": "1/2\""
        },
        "requirements": []
    },
    {
        "id": 29,
        "product_code": "1008.05.37",
        "name": "Teava CU izolata 3/4\"",
        "type": "teava",
        "subtype": "izolata",
        "specifications": {
            "type": "teava",
            "subtype": "cupru",
            "variant": "izolata",
            "diameter": "3/4\""
        },
        "requirements": []
    },
    {
        "id": 30,
        "product_code": "1008.06.78",
        "name": "Banda neadeziva PVC 25x100",
        "type": "banda",
        "subtype": "neadeziva",
        "specifications": {
            "type": "banda",
            "subtype": "neadeziva",
            "variant": "PVC",
        },
        "requirements": []
    },
    {
        "id": 31,
        "product_code": "1008.07.65",
        "name": "Cablu MYYM 4x1,5",
        "type": "cablu",
        "subtype": "MYYM",
        "specifications": {
            "type": "cablu",
            "subtype": "MYYM",
        },
        "requirements": []
    },
    {
        "id": 32,
        "product_code": "1009.04.57",
        "name": "Tampon cauciuc antivibratii",
        "type": "tampon",
        "subtype": "cauciuc",
        "specifications": {
            "type": "tampon",
            "subtype": "cauciuc",
        },
        "requirements": []
    },
    {
        "id": 33,
        "product_code": "1005.05.00",
        "name": "Tablou de siguranta trifazic 25A",
        "type": "tablou",
        "subtype": "tri",
        "specifications": {
            "type": "tablou",
            "subtype": "tri",
            "variant": "25A",
        },
        "requirements": []
    },
    {
        "id": 34,
        "product_code": "1003.02.57",
        "name": "Unitate interioara \"Hydrobox N14\"",
        "type": "unitate",
        "subtype": "hydrobox",
        "specifications": {
            "type": "unitate",
            "subtype": "hydrobox",
            "variant": "N14",
        },
        "requirements": [
            {
                "type": "interfata",
                "subtype": "wifi",
                "variant": "PAC61",
                "quantity": 1,
            },
            {
                "type": "boiler",
                "subtype": "serpentina",
                "variant": "300",
                "quantity": 1,
            },
            {
                "type": "vas",
                "subtype": "expansiune",
                "variant": "24",
                "quantity": 1,
            },
            {
                "type": "filtru",
                "subtype": "magnetita",
                "variant": "1",
                "quantity": 1,
            },
            {
                "type": "vana",
                "subtype": "deviatie",
                "variant": "1",
                "quantity": 1,
            },
            {
                "type": "timbru",
                "subtype": "verde",
                "variant": 95.20,
                "quantity": 1,
            },
        ]
    },
    {
        "id": 35,
        "product_code": "1003.03.57",
        "name": "Unitate interioara \"Hydrobox N23\"",
        "type": "unitate",
        "subtype": "hydrobox",
        "specifications": {
            "type": "unitate",
            "subtype": "hydrobox",
            "variant": "N23",
        },
        "requirements": [
            {
                "type": "interfata",
                "subtype": "wifi",
                "variant": "PAC61",
                "quantity": 1,
            },
            {
                "type": "boiler",
                "subtype": "serpentina",
                "variant": "300",
                "quantity": 1,
            },
            {
                "type": "vas",
                "subtype": "expansiune",
                "variant": "24",
                "quantity": 1,
            },
            {
                "type": "vas",
                "subtype": "expansiune",
                "variant": "50",
                "quantity": 1,
            },
            {
                "type": "senzor",
                "subtype": "ACM",
                "variant": "mitsubishi",
                "quantity": 1,
            },
            {
                "type": "filtru",
                "subtype": "magnetita",
                "variant": "1/2",
                "quantity": 1,
            },
            {
                "type": "vana",
                "subtype": "deviatie",
                "variant": "1/4",
                "quantity": 1,
            },
            {
                "type": "timbru",
                "subtype": "verde",
                "variant": 95.20,
                "quantity": 1,
            },
        ]
    },
    {
        "id": 36,
        "product_code": "1003.04.57",
        "name": "Unitate interioara \"Hydrobox P7\"",
        "type": "unitate",
        "subtype": "hydrobox",
        "specifications": {
            "type": "unitate",
            "subtype": "hydrobox",
            "variant": "P7",
        },
        "requirements": [
            {
                "type": "interfata",
                "subtype": "wifi",
                "variant": "PAC61",
                "quantity": 1,
            },
            {
                "type": "vana",
                "subtype": "deviatie",
                "variant": "1",
                "quantity": 1,
            },
            {
                "type": "filtru",
                "subtype": "magnetita",
                "variant": "3/4",
                "quantity": 1,
            },
            {
                "type": "timbru",
                "subtype": "verde",
                "variant": 95.20,
                "quantity": 1,
            },
            {
                "type": "boiler",
                "subtype": "serpentina",
                "variant": "300",
                "quantity": 1,
            },
        ]
    },
    {
        "id": 37,
        "product_code": "1003.05.57",
        "name": "Unitate interioara \"Hydrobox P14\" - model nou",
        "type": "unitate",
        "subtype": "hydrobox",
        "specifications": {
            "type": "unitate",
            "subtype": "hydrobox",
            "variant": "P14",
        },
        "requirements": [
            {
                "type": "interfata",
                "subtype": "wifi",
                "variant": "PAC61",
                "quantity": 1,
            },
            {
                "type": "boiler",
                "subtype": "serpentina",
                "variant": "300",
                "quantity": 1,
            },
            {
                "type": "vas",
                "subtype": "expansiune",
                "variant": "24",
                "quantity": 1,
            },
            {
                "type": "filtru",
                "subtype": "magnetita",
                "variant": "1",
                "quantity": 1,
            },
            {
                "type": "vana",
                "subtype": "deviatie",
                "variant": "1",
                "quantity": 1,
            },
            {
                "type": "timbru",
                "subtype": "verde",
                "variant": 95.20,
                "quantity": 1,
            },
        ]
    },
    {
        "id": 38,
        "product_code": "1004.03.57",
        "name": "Unitate interioara \"Hydrotank N14 180\"",
        "type": "unitate",
        "subtype": "hydrotank",
        "specifications": {
            "type": "unitate",
            "subtype": "hydrotank",
            "variant": "N14 180",
        },
        "requirements": [
            {
                "type": "interfata",
                "subtype": "wifi",
                "variant": "PAC61",
                "quantity": 1,
            },
            {
                "type": "filtru",
                "subtype": "magnetita",
                "variant": "1",
                "quantity": 1,
            },
            {
                "type": "timbru",
                "subtype": "verde",
                "variant": 297.50,
                "quantity": 1,
            },
        ]
    },
    {
        "id": 39,
        "product_code": "1004.04.57",
        "name": "Unitate interioara \"Hydrotank N14 230\"",
        "type": "unitate",
        "subtype": "hydrotank",
        "specifications": {
            "type": "unitate",
            "subtype": "hydrotank",
            "variant": "N14 230",
        },
        "requirements": [
            {
                "type": "interfata",
                "subtype": "wifi",
                "variant": "PAC61",
                "quantity": 1,
            },
            {
                "type": "filtru",
                "subtype": "magnetita",
                "variant": "1",
                "quantity": 1,
            },
            {
                "type": "timbru",
                "subtype": "verde",
                "variant": 297.50,
                "quantity": 1,
            },
        ]
    },
    {
        "id": 40,
        "product_code": "1004.05.57",
        "name": "Unitate interioara \"Hydrotank P7 180\"",
        "type": "unitate",
        "subtype": "hydrotank",
        "specifications": {
            "type": "unitate",
            "subtype": "hydrotank",
            "variant": "P7 180",
        },
        "requirements": [
            {
                "type": "interfata",
                "subtype": "wifi",
                "variant": "PAC61",
                "quantity": 1,
            },
            {
                "type": "filtru",
                "subtype": "magnetita",
                "variant": "3/4",
                "quantity": 1,
            },
            {
                "type": "timbru",
                "subtype": "verde",
                "variant": 297.50,
                "quantity": 1,
            },
        ]
    },
    {
        "id": 40,
        "product_code": "1004.06.57",
        "name": "Unitate interioara \"Hydrotank P7 230\"",
        "type": "unitate",
        "subtype": "hydrotank",
        "specifications": {
            "type": "unitate",
            "subtype": "hydrotank",
            "variant": "P7 230",
        },
        "requirements": [
            {
                "type": "interfata",
                "subtype": "wifi",
                "variant": "PAC61",
                "quantity": 1,
            },
            {
                "type": "filtru",
                "subtype": "magnetita",
                "variant": "3/4",
                "quantity": 1,
            },
            {
                "type": "timbru",
                "subtype": "verde",
                "variant": 297.50,
                "quantity": 1,
            },
        ]
    },
    {
        "id": 41,
        "product_code": "1004.07.57",
        "name": "Unitate interioara \"Hydrotank P14 180\" - model nou",
        "type": "unitate",
        "subtype": "hydrotank",
        "specifications": {
            "type": "unitate",
            "subtype": "hydrotank",
            "variant": "P14 180",
        },
        "requirements": [
            {
                "type": "interfata",
                "subtype": "wifi",
                "variant": "PAC61",
                "quantity": 1,
            },
            {
                "type": "filtru",
                "subtype": "magnetita",
                "variant": "1",
                "quantity": 1,
            },
            {
                "type": "timbru",
                "subtype": "verde",
                "variant": 297.50,
                "quantity": 1,
            },
        ]
    },
    {
        "id": 42,
        "product_code": "1004.08.57",
        "name": "Unitate interioara \"Hydrotank P14 230\" - model nou",
        "type": "unitate",
        "subtype": "hydrotank",
        "specifications": {
            "type": "unitate",
            "subtype": "hydrotank",
            "variant": "P14 230",
        },
        "requirements": [
            {
                "type": "interfata",
                "subtype": "wifi",
                "variant": "PAC61",
                "quantity": 1,
            },
            {
                "type": "filtru",
                "subtype": "magnetita",
                "variant": "1",
                "quantity": 1,
            },
            {
                "type": "timbru",
                "subtype": "verde",
                "variant": 297.50,
                "quantity": 1,
            },
        ]
    },
    {
        "id": 43,
        "product_code": "1005.01.64",
        "name": "Interfata Wi-Fi - PAC61 (internet)",
        "type": "interfata",
        "subtype": "wifi",
        "specifications": {
            "type": "interfata",
            "subtype": "wifi",
            "variant": "PAC61",
        },
        "requirements": []
    },
    {
        "id": 44,
        "product_code": "1007.02.68",
        "name": "Boiler 300 lt. (o serpentina)",
        "type": "boiler",
        "subtype": "serpentina",
        "specifications": {
            "type": "boiler",
            "subtype": "serpentina",
            "variant": "300",
        },
        "requirements": [
            {
                "type": "rezistenta",
                "subtype": "electrica",
                "variant": "1/2",
                "heating_power": 3,
                "quantity": 1,
            },
        ]
    },
    {
        "id": 46,
        "product_code": "1007.10.68",
        "name": "Vas expansiune 24 lt.",
        "type": "vas",
        "subtype": "expansiune",
        "specifications": {
            "type": "vas",
            "subtype": "expansiune",
            "variant": "24",
        },
        "requirements": [
            {
                "type": "suport",
                "subtype": "perete",
                "variant": "3/4",
                "quantity": 1,
            },
        ]
    },
    {
        "id": 47,
        "product_code": "1007.12.68",
        "name": "Suport perete vas expansiune 3/4",
        "type": "suport",
        "subtype": "perete",
        "specifications": {
            "type": "suport",
            "subtype": "perete",
            "variant": "3/4",
        },
        "requirements": []
    },
    {
        "id": 48,
        "product_code": "1305.10.26",
        "name": "Filtru magnetita 1\"",
        "type": "filtru",
        "subtype": "magnetita",
        "specifications": {
            "type": "filtru",
            "subtype": "magnetita",
            "variant": "1",
        },
        "requirements": []
    },
    {
        "id": 49,
        "product_code": "607.08.39",
        "name": "Vana deviatie 1\" cu servomotor inclus",
        "type": "vana",
        "subtype": "deviatie",
        "specifications": {
            "type": "vana",
            "subtype": "deviatie",
            "variant": "1",
        },
        "requirements": []
    },
    {
        "id": 50,
        "product_code": "1007.11.68",
        "name": "Vas expansiune 50 lt.",
        "type": "vas",
        "subtype": "expansiune",
        "specifications": {
            "type": "vas",
            "subtype": "expansiune",
            "variant": "50",
        },
        "requirements": [
            {
                "type": "suport",
                "subtype": "perete",
                "variant": "3/4",
                "quantity": 1,
            },
        ]
    },
    {
        "id": 51,
        "product_code": "1007.33.57",
        "name": "Senzor ACM",
        "type": "senzor",
        "subtype": "ACM",
        "specifications": {
            "type": "senzor",
            "subtype": "ACM",
            "variant": "mitsubishi",
        },
        "requirements": []
    },
    {
        "id": 52,
        "product_code": "1305.11.17",
        "name": "Filtru magnetita 1 1/2\"",
        "type": "filtru",
        "subtype": "magnetita",
        "specifications": {
            "type": "filtru",
            "subtype": "magnetita",
            "variant": "1/2",
        },
        "requirements": []
    },
    {
        "id": 53,
        "product_code": "607.09.80",
        "name": "Vana deviatie 1 1/4\" cu servomotor inclus",
        "type": "vana",
        "subtype": "deviatie",
        "specifications": {
            "type": "vana",
            "subtype": "deviatie",
            "variant": "1/4",
        },
        "requirements": []
    },
    {
        "id": 54,
        "product_code": "1305.05.12",
        "name": "Filtru magnetita 3/4\"",
        "type": "filtru",
        "subtype": "magnetita",
        "specifications": {
            "type": "filtru",
            "subtype": "magnetita",
            "variant": "3/4",
        },
        "requirements": []
    },
    {
        "id": 55,
        "product_code": "1005.04.00",
        "name": "Tablou de siguranta trifazic 16A",
        "type": "tablou",
        "subtype": "tri",
        "specifications": {
            "type": "tablou",
            "subtype": "tri",
            "variant": "16A",
        },
        "requirements": []
    },
    {
        "id": 56,
        "product_code": "1010.33.94",
        "name": "Senzor temperatura ACM pdc \"Sistema-Home\"",
        "type": "senzor",
        "subtype": "ACM",
        "specifications": {
            "type": "senzor",
            "subtype": "ACM",
            "variant": "sistema",
        },
        "requirements": []
    },
    {
        "id": 57,
        "product_code": "1303.08.93",
        "name": "Robinet cu bila si fata plana 1\" IE",
        "type": "robinet",
        "subtype": "bila",
        "specifications": {
            "type": "robinet",
            "subtype": "bila",
            "variant": "1",
        },
        "requirements": [
            {
                "type": "racord",
                "subtype": "preizolate",
                "variant": "1",
                "quantity": 1
            },
        ]
    },
    {
        "id": 58,
        "product_code": "1303.09.93",
        "name": "Robinet cu bila si fata plana 1 1/4\" IE",
        "type": "robinet",
        "subtype": "bila",
        "specifications": {
            "type": "robinet",
            "subtype": "bila",
            "variant": "1/4",
        },
        "requirements": [
            {
                "type": "racord",
                "subtype": "preizolate",
                "variant": "1",
                "quantity": 1,
            },
        ]
    },
    {
        "id": 59,
        "product_code": "1007.20.93",
        "name": "Racord flexibil preizolate 1\" II (600mm)",
        "type": "racord",
        "subtype": "preizolate",
        "specifications": {
            "type": "racord",
            "subtype": "preizolate",
            "variant": "1",
        },
        "requirements": []
    },
    {
        "id": 60,
        "product_code": "400202010",
        "name": "Easy Mag Maxi 1\" filtru antimagnetită cu plasă magnetică",
        "type": "filtru",
        "subtype": "easy",
        "specifications": {
            "type": "filtru",
            "subtype": "easy",
            "variant": "1",
        },
        "requirements": []
    },
    {
        "id": 61,
        "product_code": "1005.03.00",
        "name": "Tablou de siguranta trifazic 10A",
        "type": "tablou",
        "subtype": "tri",
        "specifications": {
            "type": "tablou",
            "subtype": "tri",
            "variant": "10A",
        },
        "requirements": []
    },
    {
        "id": 62,
        "product_code": "1005.08.00",
        "name": "Tablou de siguranta monofazic 25A",
        "type": "tablou",
        "subtype": "mono",
        "specifications": {
            "type": "tablou",
            "subtype": "mono",
            "variant": "25A",
        },
        "requirements": []
    },
    {
        "id": 63,
        "product_code": "1005.09.00",
        "name": "Tablou de siguranta monofazic 32A",
        "type": "tablou",
        "subtype": "mono",
        "specifications": {
            "type": "tablou",
            "subtype": "mono",
            "variant": "32A",
        },
        "requirements": []
    },
    {
        "id": 64,
        "product_code": "1010.02.85",
        "name": "Pompa de caldura Monobloc Aer-Apa 8,9 kw (220V) - Domusa 1x16A",
        "type": "pdcUnit",
        "specifications": {
            "type": PDC_UNIT_TYPES.MONOBLOCK.value,
            "manufacturer": PDC_MANUFACTURERS.DOMUSA.value,
            "current": PDC_CURRENTS.MONO.value,
            "pump_power": 9,
        },
        "requirements": []
    },
    {
        "id": 65,
        "product_code": "1007.07.67",
        "name": "Rezistenta electrica 3kw 1 1/2",
        "type": "rezistenta",
        "subtype": "electrica",
        "specifications": {
            "type": "rezistenta",
            "subtype": "electrica",
            "heating_power": 3,
            "variant": "1/2",
        },
        "requirements": []
    },
    {
        "id": 66,
        "product_code": "1007.35.67",
        "name": "Rezistenta electrica 2kw 1 1/2\"",
        "type": "rezistenta",
        "subtype": "electrica",
        "specifications": {
            "type": "rezistenta",
            "subtype": "electrica",
            "heating_power": 2,
            "variant": 1,
        },
        "requirements": []
    },
    {
        "id": 67,
        "product_code": "1007.08.67",
        "name": "Rezistenta electrica 4,5kw 1 1/2\"",
        "type": "rezistenta",
        "subtype": "electrica",
        "specifications": {
            "type": "rezistenta",
            "subtype": "electrica",
            "heating_power": 4.5,
            "variant": 1,
        },
        "requirements": []
    },
    {
        "id": 68,
        "product_code": "1005.10.00m",
        "name": "Tablou automatizare rezistenta",
        "type": "tablou",
        "subtype": "automatizare",
        "specifications": {
            "type": "tablou",
            "subtype": "automatizare",
            "variant": "monofazic",
        },
        "requirements": []
    },
    {
        "id": 68,
        "product_code": "1005.10.00t",
        "name": "Tablou automatizare rezistenta",
        "type": "tablou",
        "subtype": "automatizare",
        "specifications": {
            "type": "tablou",
            "subtype": "automatizare",
            "variant": "trifazic",
        },
        "requirements": []
    },
    {
        "id": 69,
        "product_code": "TV1",
        "name": "Timbru verde",
        "type": "timbru",
        "subtype": "verde",
        "specifications": {
            "type": "timbru",
            "subtype": "verde",
            "variant": 297.50,
        },
        "requirements": []
    },
    {
        "id": 70,
        "product_code": "TV2",
        "name": "Timbru verde",
        "type": "timbru",
        "subtype": "verde",
        "specifications": {
            "type": "timbru",
            "subtype": "verde",
            "variant": 95.20,
        },
        "requirements": []
    },
    {
        "id": 71,
        "product_code": "1007.37.67",
        "name": "Rezistenta electrica 3kw 1 1/4\"",
        "type": "rezistenta",
        "subtype": "electrica",
        "specifications": {
            "type": "rezistenta",
            "subtype": "electrica",
            "heating_power": 3,
            "variant": "1/4",
        },
        "requirements": []
    },
]