<template>
    <a href="#" id="section1" ref="section1"></a>
    <client-form/>
</template>

<script>
import ClientForm from "@/components/clients/ClientForm.vue";
import navMixin from "@/mixins/navMixin";

export default {
    name: "ClientCreate",
    mixins: [navMixin],
    components: {
        ClientForm
    },
    data() {
        return {
            clientId: null,
            sidebarLinks: [
                {
                    name: 'Creare client',
                    link: '#section1',
                    id: 'section1',
                }
            ]
        }
    },
    methods: {
        useClient(client) {
            // trigger saveLocation() method from ClientLocation component
            this.$refs.clientLocation.saveLocation(client);
        }
    },
    mounted() {
        this.$store.state.sidebarLinks = this.sidebarLinks;
    },
}
</script>

<style scoped>
</style>