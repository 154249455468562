<template>
    <BreadCrumbs>
        <template v-slot:header>
            <template v-for="button in buttons" :key="button.text">
                <v-btn
                    v-if="button.visible === undefined || button.visible()"
                    variant="plain"
                    @click.prevent="button.onClick"
                    :disabled="button.disabled()"
                    :color="button.color"
                >
                    <v-icon v-if="button.icon" :class="button.icon"></v-icon>
                    <span v-else>{{ button.text }}</span>
                </v-btn>
            </template>

            <v-btn color="primary">
                PDF
                <v-icon right>mdi-menu-down</v-icon>
                <v-menu offset-y activator="parent">
                    <v-list>
                        <v-list-item
                            v-for="pdfOption in pdfOptions.simple"
                            :key="pdfOption.text"
                            @click="pdfOption.onClick"
                            :disabled="pdfOption.disabled()"
                            :color="pdfOption.color"
                        >
                            <v-list-item-title>{{ pdfOption.text }}</v-list-item-title>
                        </v-list-item>
                    </v-list
                    >
                </v-menu>
            </v-btn>
        </template>
    </BreadCrumbs>
    <a href="#" id="section1" ref="section1"></a>
    <OfferInformation
        v-if="client && location"
        :client="client"
        :location="location"
        :offer="offer"
        :offerId="offerId"
        @update="updateOfferData"
        @offer-buttons-in-view="isOfferButtonsInView = $event"
    >
    </OfferInformation>
    <a href="#" id="section2" ref="section2"></a>
    <ProductTable
        v-if="loadProductTable"
        :offer="offer"
        :offered-products="offeredProducts ?? []"
        :offer-type="{type: 'AUTOMATION', productCategories: ['AUTOMATIZARE']}"
        :headers="productTableHeaders"
        @sort-pdf-by-number="setPDFCustomSorting"
        @update-products="updateOfferProducts"
        @update-offer="updateOfferData"
        @selected-price-list="updateOfferPriceList"
    >
    </ProductTable>
    <OfferPaymentTerms
        :offer="offer"
        @update_payment_terms="updatePaymentTerms"
    ></OfferPaymentTerms>
</template>

<script>
import navMixin from "@/mixins/navMixin";
import OfferInformation from "@/components/offer/OfferInformation.vue";
import offerMixin from "@/mixins/offerMixin";
import ProductTable from "@/components/offer/ProductTable.vue";
import BreadCrumbs from "@/components/common/BreadCrumbs.vue";
import OfferPaymentTerms from "@/components/offer/OfferPaymentTerms.vue";

export default {
    name: "OfferAutomation",
    mixins: [navMixin, offerMixin],
    components: {
        OfferPaymentTerms,
        BreadCrumbs,
        ProductTable,
        OfferInformation,
    },
    data() {
        return {
            sidebarLinks: [
                {
                    name: 'Informații',
                    link: '#section1',
                    id: 'section1'
                },
                {
                    name: 'Produse',
                    link: '#section2',
                    id: 'section2'
                },
            ],
            productTableHeaders: [
                {
                    title: "Nr.",
                    key: "number",
                    align: "center",
                    show: false,
                    width: "100px",
                },
                {
                    title: "Cod articol",
                    key: "product_code",
                    align: "center",
                    show: true,
                    width: "140px",
                },
                {
                    title: "Nume articol",
                    key: "product_name",
                    align: "start",
                    show: true,
                },
                {
                    title: "Categorie",
                    key: "assigned_sub_category",
                    align: "center",
                    show: false,
                },
                {
                    title: "Cantitate",
                    key: "quantity",
                    align: "center",
                    show: true,
                    width: "130px",
                },
                {
                    title: "UM",
                    key: "measurement_unit",
                    align: "center",
                    show: true,
                    width: "70px",
                },
                {
                    title: "Preț listă cu TVA",
                    key: "price",
                    align: "end",
                    show: true,
                },
                {
                    title: "Discount",
                    key: "discount",
                    align: "center",
                    show: true,
                    width: "120px",
                },
                {
                    title: "Preț final cu TVA",
                    key: "final_price",
                    align: "end",
                    show: true,
                },
                {
                    title: "Valoare finală cu TVA",
                    key: "final_total_price",
                    align: "end",
                    show: true,
                },
            ],
        }
    },
    created() {
        this.$router.beforeEach((to, from, next) => {
            if (from.name === 'OfferAutomation' && to.name !== 'OfferAutomation' && this.isProductsChanged === true) { // Replace 'FormPage' with your form's route name
                const shouldLeave = this.checkFormBeforeLeave();
                if (!shouldLeave) {
                    next(false); // Prevent route change
                } else {
                    this.isProductsChanged = false;
                    next(); // Proceed with the route change
                }
            } else {
                next(); // Proceed with the route change for routes other than the form page
            }
        });
    },
    mounted() {
        this.$store.state.sidebarLinks = this.sidebarLinks
    },
    methods: {
        checkFormBeforeLeave() {
            return confirm('Sunteți sigur că doriți să părăsiți formularul? Modificările nesalvate vor fi pierdute.');
        },
    },

}
</script>