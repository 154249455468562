<template>
    <div>
        <BreadCrumbs>
            <template v-slot:header>
                <template v-for="button in buttons" :key="button.text">
                    <v-btn v-if="button.visible === undefined || button.visible()" variant="plain"
                        @click.prevent="button.onClick" :disabled="button.disabled()" :color="button.color">
                        <v-icon v-if="button.icon" :class="button.icon"></v-icon>
                        <span v-else>{{ button.text }}</span>
                    </v-btn>
                </template>
                <v-btn color="primary">
                    PDF
                    <v-icon right>mdi-menu-down</v-icon>
                    <v-menu offset-y activator="parent">
                        <v-list>
                            <v-list-item v-for="pdfOption in pdfOptions.summary" :key="pdfOption.text"
                                @click="pdfOption.onClick" :disabled="pdfOption.disabled()" :color="pdfOption.color">
                                <v-list-item-title>{{ pdfOption.text }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-btn>
            </template>
        </BreadCrumbs>
        <v-card class="mt-3">
            <v-toolbar>
                <v-toolbar-title>Selectare locații pentru generarea centralizatorului</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <v-chip-group v-model="selectedLocations" column multiple>
                    <v-chip v-for="location in locations" :key="location.id" :value="location.id" variant="outlined"
                        active-class="bg-success" selected-class="bg-success" filter>
                        {{ location.description }}
                        <v-tooltip location="top" activator="parent" open-delay="250">
                            Nume: {{ location.description }} <br />
                            Versiune: {{ location.version }} <br />
                            Nr. camere: {{ location.rooms.length }} <br />
                            Suprafață: {{ locationArea(location) }} <br />
                        </v-tooltip>
                    </v-chip>
                </v-chip-group>
            </v-card-text>
        </v-card>
        <div class="d-flex align-items-center justify-content-center mt-3">
            <v-progress-circular v-if="loading" indeterminate :size="60" :width="5"></v-progress-circular>
        </div>
        <v-card class="mt-3" v-if="!loading && this.summaryOffers">
            <v-hover v-slot="{ isHovering, props }">
                <v-toolbar @click="toggleOffersSectionCollapse" class="cursor-pointer" v-bind="props">
                    <v-toolbar-title>Centralizator oferte</v-toolbar-title>
                    <v-expand-transition>
                        <div v-if="isHovering" class="d-flex justify-content-center align-items-center"
                            style="background: rgb(var(--v-theme-on-surface-variant)); position: absolute; top: 65%; left: 50%;">
                            <v-icon v-if="isOffersCollapsed">mdi-chevron-double-down</v-icon>
                            <v-icon v-else>mdi-chevron-double-up</v-icon>
                        </div>
                    </v-expand-transition>
                </v-toolbar>
            </v-hover>
            <v-expand-transition>
                <v-card-text v-if="!isOffersCollapsed">
                    <v-data-table v-model="selectedOffers" show-select :items="sortedSummaryOffers" :headers="headers"
                        :loading="loading" @update:modelValue="generateSummaryByType">
                        <template v-slot:[`item.id`]="{ item }">
                            {{ item.selectable.id ? item.selectable.id : '--' }}
                        </template>
                        <template v-slot:[`item.location`]="{ item }">
                            {{ item.selectable.location.description }}
                        </template>
                        <template v-slot:[`item.modified`]="{ item }">
                            {{ formatDate(item.selectable.modified) }}
                        </template>
                        <template v-slot:[`item.type`]="{ item }">
                            {{ getTypeDescription(item.selectable.type) }}
                        </template>
                        <template v-slot:[`item.version`]="{ item }">
                            {{ item.selectable.location.version ? item.selectable.location.version : '--' }}
                        </template>
                        <template v-slot:[`item.list_price_value`]="{ item }">
                            <span v-if="item.selectable.list_price_value !== '0.00'">
                                {{ formatNumber(this, item.selectable.list_price_value, true) }}
                            </span>
                            <span v-else>--</span>
                        </template>
                        <template v-slot:[`item.discount`]="{ item }">
                            <span v-if="item.selectable.is_discount_mixed">
                                <span v-if="item.selectable.discount !== '0.00' && item.selectable.discount !== null">
                                    {{ parseInt(item.selectable.discount) }}%
                                </span>
                                <span style="color: red;"> Mixt</span>
                            </span>
                            <span v-else-if="item.selectable.discount !== '0.00' && item.selectable.discount !== null">
                                {{ parseInt(item.selectable.discount) }}%
                            </span>
                            <span v-else>--</span>
                        </template>
                        <template v-slot:[`item.value_with_discount`]="{ item }">
                            <span v-if="item.selectable.value_with_discount !== '0.00'">
                                {{ formatNumber(this, item.selectable.value_with_discount, true) }}
                            </span>
                            <span v-else>--</span>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-expand-transition>
        </v-card>
        <v-card class="mt-3" v-if="!loading && this.summaryOffers">
            <v-toolbar class="d-flex flex-row align-items-center justify-content-evenly">
                <v-toolbar-title>Centralizator valori</v-toolbar-title>
                <div>
                    <v-text-field label="Total cu TVA" density="compact" hide-details variant="solo" bg-color="primary"
                        readonly dirty>
                        {{ formatNumber(this, totalSummaryAndServices, true) }}
                    </v-text-field>
                </div>
            </v-toolbar>
            <v-card-text>
                <v-row class="d-flex align-items-center custom-row">
                    <v-col cols="1" class="header-cell">Afișare</v-col>
                    <v-col cols="3" class="header-cell">Nume</v-col>
                    <v-col cols="3" class="header-cell">Oferte</v-col>
                    <v-col cols="2" class="header-cell">Note</v-col>
                    <v-col cols="2" class="header-cell d-flex justify-content-center">Valoare totală</v-col>
                    <v-col cols="1" class="header-cell d-flex justify-content-center">Acțiuni</v-col>
                </v-row>
                <v-divider></v-divider>
                <div v-for="(summary, index) in summaryByType" :key="summary.name">
                    <v-row class="data-row custom-row">
                        <v-col cols="1" class="d-flex align-items-center justify-content-center">
                            <v-checkbox v-model="summary.show" density="compact" hide-details dense />
                        </v-col>
                        <v-col cols="3" class="d-flex flex-column align-items-start justify-content-center">
                            <template v-if="summary.isEditing">
                                <v-text-field v-model="summary.name" label="Nume" variant="outlined" dense
                                    hide-details></v-text-field>
                            </template>
                            <template v-else>
                                {{ summary.name }}
                            </template>
                        </v-col>
                        <v-col cols="3" class="d-flex flex-column align-items-start justify-content-center">
                            <!-- Offers Section -->
                            <div v-for="(row, rowIndex) in chunkArray(summary.offers, 3)" :key="rowIndex"
                                class="d-flex flex-row align-items-center justify-content-start">
                                <v-chip v-for="offer in row" :key="offer.id"
                                    :color="offer.addedWay === 'normal' ? 'default mb-1' : 'warning mb-1'" closable
                                    dense @click:close="removeOffer(summary, offer)">
                                    <v-tooltip v-if="offer.addedWay === 'manual'" location="top" activator="parent"
                                        open-delay="250">
                                        Ofertă adaugată manual
                                    </v-tooltip>
                                    {{ offer.id }}
                                </v-chip>
                            </div>
                        </v-col>
                        <v-col cols="2" class="d-flex flex-column align-items-start justify-content-center">
                            <v-textarea v-model="summary.notes" label="Note" outlined auto-grow dense density="compact"
                                hide-details rows="1" :disabled="!summary.isEditing"></v-textarea>
                        </v-col>
                        <v-col cols="2" class="d-flex flex-column align-items-end justify-content-center pr-10">
                            <template v-if="summary.isEditing">
                                <v-text-field v-model="summary.total" label="Total" variant="outlined" dense
                                    hide-details></v-text-field>
                            </template>
                            <template v-else>
                                <b>{{ formatNumber(this, summary.total, true) }}</b>
                            </template>
                        </v-col>
                        <v-hover v-slot="{ props }">
                            <v-col cols="1" v-bind="props"
                                class="d-flex flex-column align-items-center justify-content-center">
                                <v-expand-transition>
                                    <v-btn icon class="ma-1" @click="summary.isEditing ? toggleEditing(summary) : null">
                                        <v-icon color="grey">
                                            {{ summary.isEditing ? 'mdi-check' : 'mdi-dots-vertical' }}
                                        </v-icon>
                                        <template v-if="!summary.isEditing">
                                            <v-menu activator="parent">
                                                <v-list v-if="summary.isManualAdded">
                                                    <v-list-item v-for="(action, index) in manualItemActions"
                                                        :key="index" @click="() => action.method(summary)">
                                                        <v-list-item-title>
                                                            {{ action.label }}
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                </v-list>
                                                <v-list v-else>
                                                    <v-list-item @click="showAddOffersToSummaryModal(summary)">
                                                        <v-list-item-title>
                                                            Oferte
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                    <!-- Only for the last item -->
                                                    <v-list-item v-if="index === summaryByType.length - 1"
                                                        @click="startAddSummary(summary)">
                                                        <v-list-item-title>
                                                            Adaugă rând
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                </v-list>
                                            </v-menu>
                                        </template>
                                    </v-btn>
                                </v-expand-transition>
                            </v-col>
                        </v-hover>
                    </v-row>
                    <v-divider></v-divider>
                </div>
                <v-dialog v-model="addOffersToSummaryModal">
                    <v-card>
                        <v-card-title>
                            <span class="headline">Adăugare oferte</span>
                        </v-card-title>
                        <v-card-text>
                            <v-data-table :items="filteredSummaryOffers" :headers="headers" item-value="id" show-select
                                v-model="selectedOffersForSummary">
                                <template v-slot:[`item.id`]="{ item }">
                                    {{ item.selectable.id ? item.selectable.id : '--' }}
                                </template>
                                <template v-slot:[`item.location`]="{ item }">
                                    {{ item.selectable.location.description }}
                                </template>
                                <template v-slot:[`item.modified`]="{ item }">
                                    {{ formatDate(item.selectable.modified) }}
                                </template>
                                <template v-slot:[`item.type`]="{ item }">
                                    {{ getTypeDescription(item.selectable.type) }}
                                </template>
                            </v-data-table>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="danger" @click="closeAddOffersToSummaryModal">Renunță</v-btn>
                            <v-btn color="success" @click="addOffersToSummary">Salvează</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-row v-if="addingNewSummary" class="custom-row">
                    <v-col>
                        <v-text-field v-model="newSummary.name" label="Nume" variant="outlined" dense
                            required></v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field v-model="newSummary.total" label="Valoare" variant="outlined" dense
                            type="number"></v-text-field>
                    </v-col>
                    <v-col class="d-flex flex-row align-items-center justify-content-center">
                        <v-btn color="danger" dense @click="cancelAddSummary">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-btn color="success" dense @click="addSummary">
                            <v-icon>mdi-check</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <!-- Servicii Section -->
                <v-card-title class="mt-3">Servicii</v-card-title>
                <v-divider></v-divider>
                <div v-for="(service, index) in services" :key="service.id">
                    <v-row class="data-row custom-row">
                        <v-col cols="1" class="d-flex align-items-center justify-content-center">
                            <v-checkbox v-model="service.show" hide-details density="compact" dense />
                        </v-col>
                        <v-col cols="2" class="d-flex flex-column align-items-start justify-content-center">
                            <template v-if="service.isEditing">
                                <v-text-field class="w-100" v-model="service.name" label="Nume" variant="outlined" dense
                                    hide-details></v-text-field>
                            </template>
                            <template v-else>
                                {{ service.name }}
                            </template>
                        </v-col>
                        <v-col cols="4"></v-col>
                        <v-col cols="2" class="d-flex flex-column align-items-start justify-content-center">
                            <v-textarea v-model="service.notes" label="Note" density="compact" hide-details outlined
                                auto-grow dense :disabled="!service.isEditing" rows="1"></v-textarea>
                        </v-col>
                        <v-col cols="2" class="d-flex flex-column align-items-end justify-content-center pr-10">
                            <template v-if="service.isEditing">
                                <v-text-field :value="service.totalInput || ''" label="Total" variant="outlined" dense
                                    dirty density="compact" class="w-100" hide-details
                                    @input="handleTotalInput($event, service)" @keydown.tab="toggleEditing(service)"
                                    @keydown.enter="toggleEditing(service)"></v-text-field>
                            </template>
                            <template v-else>
                                <b>{{ formatNumber(this, service.total, true) }}</b>
                            </template>
                        </v-col>
                        <v-hover v-slot="{ props }">
                            <v-col cols="1" v-bind="props"
                                class="d-flex flex-column align-items-center justify-content-center">
                                <v-expand-transition>
                                    <v-btn icon class="ma-1" @click="service.isEditing ? toggleEditing(service) : null">
                                        <v-icon color="grey">
                                            {{ service.isEditing ? 'mdi-check' : 'mdi-dots-vertical' }}
                                        </v-icon>
                                        <v-menu v-if="!service.isEditing" activator="parent">
                                            <v-list>
                                                <v-list-item v-for="action in serviceItemActions" :key="action.label"
                                                    @click="() => action.method(service, index)">
                                                    <v-list-item-title>
                                                        {{ action.label }}
                                                    </v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </v-btn>
                                </v-expand-transition>
                            </v-col>
                        </v-hover>
                    </v-row>
                    <v-divider></v-divider>
                </div>
                <v-row v-if="addingNewService" class="custom-row">
                    <v-col cols="2">
                        <v-text-field v-model="newService.name" label="Nume" variant="outlined" required
                            dense></v-text-field>
                    </v-col>
                    <v-col cols="2"></v-col>
                    <v-col cols="2" class="d-flex flex-column align-items-start justify-content-center">
                        <v-textarea v-model="newService.notes" label="Note" variant="outlined" hide-details auto-grow
                            dense rows="1" density="compact"></v-textarea>
                    </v-col>
                    <v-col cols="1"></v-col>
                    <v-col cols="2" class="d-flex flex-column align-items-start justify-content-center">
                        <v-text-field :value="newService.totalInput || ''" label="Total" variant="outlined" required
                            class="w-100" dense @input="handleTotalInput($event, newService)"></v-text-field>
                    </v-col>
                    <v-col cols="3" class="d-flex align-items-center justify-content-center">
                        <v-btn color="danger" dense @click="cancelAddService">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-btn color="success" dense @click="addService">
                            <v-icon>mdi-check</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row v-if="services.length === 0 && !addingNewService" class="custom-row">
                    <v-col>
                        <v-btn variant="elevated" color="success" dense @click="startAddService">
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </div>
</template>
<script>
import { v4 as uuidv4 } from 'uuid';
import { VDataTable } from "vuetify/labs/components";
import apiClient from "@/utils/apiClient";
import {
    createPDFSummary,
    formatNumber,
    getClientLocations, roundToTwo,
    showAlertModal
} from "@/utils/utils";
import { mapGetters } from "vuex";
import BreadCrumbs from "@/components/common/BreadCrumbs.vue";
import { debounce } from "lodash";


export default {
    name: "OffersSummary",
    components: {
        VDataTable,
        BreadCrumbs,
    },
    data() {
        return {
            headers: [
                { title: "ID", key: "id" },
                { title: "Locație", key: "location" },
                { title: "Data", key: "modified" },
                { title: "Tip", key: "type" },
                { title: "Versiune", key: "version" },
                { title: "Preț listă", key: "list_price_value" },
                { title: "Discount", key: "discount" },
                { title: "Total cu discount", key: "value_with_discount" },
            ],
            summaryOffers: [],
            loading: false,
            types: [
                { name: "Încălzire și răcire prin pardoseală", text: "IPA", value: 1 },
                { name: "Instalații sanitare", text: "SANITARE", value: 2 },
                { name: "Ventilație cu recuperare de căldură", text: "VMC", value: 3 },
                { name: "Simplă", text: "SIMPLĂ", value: 4 },
                { name: "Cameră tehnică și coloane", text: "C. T. și COLOANE", value: 5 },
                { name: "Încălzire și răcire prin tavan", text: "TAVAN", value: 6 },
                { name: "Încălzire și răcire prin pereți", text: "PEREȚI", value: 7 },
                { name: "Automatizări clădiri inteligente", text: "AUTOMATIZARE", value: 8 },
                { name: "Încălzire și răcire cu ventiloconvectoare", text: "VENTILOCONVECTOARE", value: 9 },
                { name: "Canal termic", text: "CANAL TERMIC", value: 10 },
                { name: "Pompe de căldură", text: "PDC", value: 11 },
                { name: "Aspirare", text: "ASPIRARE", value: 12 },
            ],
            locations: [],
            selectedLocations: [],
            selectedOffers: [],
            summaryByType: [],
            isOffersCollapsed: false,
            addingNewSummary: false,
            newSummary: {
                name: "",
                total: 0,
                isManualAdded: true,
            },
            addOffersToSummaryModal: false,
            selectedOffersForSummary: [],
            currentSummary: null,
            manualOffers: [],
            manualOffer: {
                id: "",
                type: null,
                value_with_discount: 0,
                uniqueId: null,
            },
            specialType4Summaries: [],
            services: [
                { id: 1, name: 'Manoperă', notes: '', total: '', show: false },
                { id: 2, name: 'Consultanță', notes: '', total: '', show: false },
                { id: 3, name: 'Proiectare', notes: '', total: '', show: false },
            ],
            addingNewService: false,
            newService: { name: '', total: '', notes: '' },
            newServiceIndex: null,
            currentSummaryId: null,
            buttons: [
                {
                    text: 'Salvează centralizator',
                    onClick: () => {
                        this.saveSummary(false)
                    },
                    disabled: () => false,
                    color: 'success',
                    visible: () => true,
                },
                {
                    text: 'Creează centralizator nou',
                    onClick: () => {
                        this.saveSummary(true)
                    },
                    disabled: () => !this.currentSummaryId,
                    color: 'primary',
                    visible: () => true,
                }
            ],
            pdfOptions: {
                summary: [
                    {
                        text: 'Centralizator',
                        onClick: () => createPDFSummary(this.currentSummaryId),
                        disabled: () => !this.currentSummaryId,
                        color: 'primary',
                    },
                ],
            },
            fetchedLocations: {},
            customOrder: [
                "Încălzire și răcire prin pardoseală", "Încălzire și răcire prin tavan", "Încălzire și răcire prin pereți", "Încălzire și răcire cu ventiloconvectoare", "Pompe de căldură",
                "FOTOVOLTAICE", "Ventilație cu recuperare de căldură", "ASPIRARE", "Automatizări clădiri inteligente",
                "Instalații sanitare", "Cameră tehnică și coloane"
            ],
            manualItemActions: [
                {
                    label: 'Adaugă rând',
                    method: this.addSummary,
                },
                {
                    label: 'Oferte',
                    method: this.showAddOffersToSummaryModal,
                },
                {
                    label: 'Șterge',
                    method: (summary) => {
                        if (summary.isManualAdded) this.deleteItem(this.summaryByType, summary);
                    },
                },
                {
                    label: 'Editează',
                    method: (summary) => {
                        if (summary.isManualAdded) this.toggleEditing(summary);
                    },
                },
            ],

            serviceItemActions: [
                {
                    label: 'Adaugă rând',
                    method: (service, index) => this.startAddService(index),
                },
                {
                    label: 'Șterge',
                    method: (service) => {
                        this.deleteItem(this.services, service);
                    },
                },
                {
                    label: 'Editează',
                    method: (service) => {
                        this.toggleEditing(service);
                    },
                },
            ],
        };
    },
    computed: {
        ...mapGetters(['userPermissions']),
        /**
         * Computed property that returns the sorted list of summary offers.
         * The offers are sorted first by the order of location IDs in selectedLocations,
         * then by type order as defined in this.types, and finally by modified date in descending order.
         *
         * @returns {Array} - The sorted array of summary offers.
         */
        sortedSummaryOffers() {
            // Create a mapping of type values to their order
            const typeOrder = this.types.reduce((acc, type, index) => {
                acc[type.value] = index;
                return acc;
            }, {});

            // Create a mapping of selected location IDs to their order
            const locationOrder = this.selectedLocations.reduce((acc, location, index) => {
                acc[location.id] = index;
                return acc;
            }, {});

            // Sort the offers
            return this.summaryOffers.slice().sort((a, b) => {
                // First, compare by the order of location IDs in selectedLocations
                const locationAOrder = locationOrder[a.location.id] !== undefined ? locationOrder[a.location.id] : Infinity;
                const locationBOrder = locationOrder[b.location.id] !== undefined ? locationOrder[b.location.id] : Infinity;
                const locationComparison = locationAOrder - locationBOrder;
                if (locationComparison !== 0) {
                    return locationComparison;
                }

                // If locations are the same, compare by type order
                const typeComparison = typeOrder[a.type] - typeOrder[b.type];
                if (typeComparison !== 0) {
                    return typeComparison;
                }

                // If types are the same, compare by modified date in descending order
                const dateA = new Date(a.modified);
                const dateB = new Date(b.modified);
                const dateComparison = dateB - dateA;
                return dateComparison;
            });
        },
        /**
         * Computed property that returns the filtered list of summary offers.
         * If the user does not have permission to add any offer type in the summary,
         * only offers of the current summary type are returned. Otherwise, all summary offers are returned.
         *
         * @returns {Array} - The filtered array of summary offers.
         */
        filteredSummaryOffers() {
            if (!this.userPermissions.canAddAnyOfferTypeInSummary) {
                return this.summaryOffers.filter(offer => offer.type === this.currentSummary.type)
            }
            return this.summaryOffers;
        },
        /**
         * Computed property that calculates the total value of the summary and services.
         * The total is the sum of the total values of summaryByType and services where show is true.
         *
         * @returns {number} - The total value of the summary and services.
         */
        totalSummaryAndServices() {
            // Calculate the total for summaryByType where show is true
            const summaryTotal = this.summaryByType
                .filter(summary => summary.show)
                .reduce((acc, summary) => acc + (parseFloat(summary.total) || 0), 0);

            // Calculate the total for services where show is true
            const servicesTotal = this.services
                .filter(service => service.show)
                .reduce((acc, service) => acc + (parseFloat(service.total) || 0), 0);

            // Return the sum of both totals
            return summaryTotal + servicesTotal;
        }
    },
    async mounted() {
        this.loading = true;
        const currentSummaryId = sessionStorage.getItem('currentSummaryId');
        this.currentSummaryId = currentSummaryId;
        this.locations = await this.getClientLocations(this.$route.params.id);
        if (currentSummaryId) {
            await this.fetchSummary(currentSummaryId);
        } else {
            if (this.locations.length > 0) {
                if (this.$route.params.locationId) {
                    this.selectedLocations = [parseInt(this.$route.params.locationId)];
                } else {
                    this.selectedLocations = [this.locations[0].id];
                }

                this.generateSummary();
            }
        }
    },
    beforeUnmount() {
        sessionStorage.removeItem('currentSummaryId');
    },
    methods: {
        /**
         * Handles the input for the total field, allowing both comma and dot as decimal separators.
         * It updates the service's totalInput for display and total for calculations.
         *
         * @param {Event} event - The input event triggered by the user.
         * @param {Object} service - The service object being updated.
         * @param {string|number} service.totalInput - The input value for display.
         * @param {number} service.total - The total value for calculations.
         */
        handleTotalInput(event, service) {
            const value = event.target.value.replace(',', '.');
            // Allow input to include digits and at most one dot
            if (/^\d*\.?\d*$/.test(value)) {
                service.totalInput = value;
                service.total = parseFloat(value) || 0; // Convert to float for internal use, fallback to 0
            }
        },
        /**
         * Fetches the summary data for a given summary ID, including the related location offers.
         * @param {string} summaryId - The ID of the summary to fetch.
         * @async
         */
        async fetchSummary(summaryId) {
            this.loading = true;
            try {
                const response = await apiClient.get(`/api/summaries/${summaryId}/`);
                const summaryData = response.data;

                // Fetch the summary offers
                const fetchPromises = summaryData.locations.map(location =>
                    this.fetchLocationOffers(location.id)
                );

                const results = await Promise.all(fetchPromises);

                // Check if any fetch failed
                if (results.some(result => result === null)) {
                    throw new Error('Failed to fetch some location offers');
                }

                this.summaryOffers = results.flat();

                // Populate the component data with the fetched summary details
                this.selectedLocations = summaryData.locations.map(location => location.id);

                // Clear the selectedOffers array
                this.selectedOffers = [];

                this.summaryByType = summaryData.items.filter(item => item.item_type === 'offer').map(item => {
                    const offersWithDetails = item.offers.map(offerId => {
                        const offer = this.summaryOffers.find(offer => offer.id === offerId) || {};
                        // Add offerId to selectedOffers array
                        if (!this.selectedOffers.includes(offerId)) {
                            this.selectedOffers.push(offerId);
                        }
                        return {
                            id: offerId,
                            addedWay: 'normal',
                            value_with_discount: offer.value_with_discount || 0
                        };
                    });

                    return {
                        id: item.id,
                        name: item.name,
                        notes: item.notes,
                        total: item.total,
                        show: item.show,
                        offers: offersWithDetails
                    };
                });

                this.services = summaryData.items.filter(item => item.item_type === 'service').map(item => ({
                    id: item.id,
                    name: item.name,
                    notes: item.notes,
                    total: item.total,
                    show: item.show
                }));

                this.loading = false;
            } catch (error) {
                console.error('Error fetching summary:', error);
                this.loading = false;
            }
        },
        /**
         * Adds a new empty manual offer to the current summary.
         */
        addEmptyManualOffer() {
            this.currentSummary.manualOffers.push({
                id: "",
                type: null,
                value_with_discount: 0,
                uniqueId: Date.now(), // Generate a unique ID for each new manual offer
                addedWay: 'manual'
            });
        },
        formatNumber,
        getClientLocations,
        /**
         * Fetches the offers for a given location ID.
         * @param {number} locationId - The ID of the location to fetch offers for.
         * @returns {Array|null} - The array of offers or null if the fetch fails.
         * @async
         */
        async fetchLocationOffers(locationId) {
            try {
                const response = await apiClient.get(`/api/get_offer_data_by_location_id/${locationId}/`);
                return response.data.offers;
            } catch (error) {
                console.error("Error fetching location offers:", error);
                return null;  // Return null on failure
            }
        },
        /**
         * Generates the summary by fetching the location offers and processing them.
         */
        generateSummary() {
            this.loading = true;
            this.summaryOffers = [];
            this.selectedOffers = [];

            const fetchPromises = this.selectedLocations.map(locationId =>
                this.fetchLocationOffers(locationId)
            );

            Promise.all(fetchPromises)
                .then(results => {
                    // Check if any fetch failed
                    if (results.some(result => result === null)) {
                        throw new Error('Failed to fetch some location offers');
                    }

                    this.summaryOffers = results.flat();
                    this.selectLatestOffers();
                    this.generateSummaryByType();
                    this.loading = false;
                })
                .catch(error => {
                    console.error("Error fetching offers:", error);
                    this.loading = false;
                });
        },
        /**
         * Selects the latest offers for each location and type, ensuring that specific offers with a valid custom PDF heading are selected.
         */
        selectLatestOffers() {
            const offersByLocationAndType = {};
            let parsedOffers = [];

            this.summaryOffers.forEach(offer => {
                parsedOffers.push(offer);
                const location = offer.location;
                const type = offer.type;

                if (!this.selectedLocations.includes(location.parent)) {
                    const locationId = location.id;
                    if (!offersByLocationAndType[locationId]) {
                        offersByLocationAndType[locationId] = {};
                    }

                    // Check if offer is of type 4 and has a valid custom_pdf_heading
                    if (type === 4 && offer.custom_pdf_heading) {
                        // Ensure this offer is selected regardless of the existing selection
                        this.selectedOffers.push(offer.id);
                    }

                    if (
                        !offersByLocationAndType[locationId][type] ||
                        new Date(offer.modified) > new Date(offersByLocationAndType[locationId][type].modified)
                    ) {
                        offersByLocationAndType[locationId][type] = offer;
                    }
                }
            });

            Object.values(offersByLocationAndType).forEach(locationOffers => {
                Object.values(locationOffers).forEach(offer => {
                    // Ensure type 4 offers with valid custom_pdf_heading are not re-added
                    if (!(offer.type === 4 && offer.custom_pdf_heading)) {
                        this.selectedOffers.push(offer.id);
                    }
                });
            });

            // Ensure the latest type 4 offer without a valid custom_pdf_heading is selected
            const latestType4WithoutHeading = Object.values(offersByLocationAndType)
                .flatMap(locationOffers => Object.values(locationOffers))
                .filter(offer => offer.type === 4 && !offer.custom_pdf_heading)
                .sort((a, b) => new Date(b.modified) - new Date(a.modified))[0];

            if (latestType4WithoutHeading) {
                this.selectedOffers.push(latestType4WithoutHeading.id);
            }
        },
        /**
         * Generates a summary by type, including special handling for type 4 summaries.
         * Splits offers based on custom_pdf_heading and organizes them into appropriate summaries.
         * Appends special type 4 summaries and sorts all summaries based on a custom order.
         */
        generateSummaryByType() {
            const currentSummaryByType = this.summaryByType;
            this.specialType4Summaries = []; // Reset special type 4 summaries

            this.summaryByType = this.types.map(type => {
                let offersOfType = this.summaryOffers.filter(
                    offer => offer.type === type.value && this.selectedOffers.includes(offer.id)
                ).map(offer => ({
                    ...offer,
                    addedWay: 'normal'
                }));

                // For type 4, split offers based on custom_pdf_heading
                if (type.value === 4) {
                    const validHeadingOffers = offersOfType.filter(offer => offer.custom_pdf_heading);
                    const invalidHeadingOffers = offersOfType.filter(offer => !offer.custom_pdf_heading);

                    // Create separate summaries for each offer with a valid custom_pdf_heading
                    validHeadingOffers.forEach(offer => {
                        const validHeadingSummary = {
                            name: offer.custom_pdf_heading + ' / Simplă',
                            total: this.calculateTotalValue([offer]),
                            type: type.value,
                            offers: [{
                                id: offer.id,
                                addedWay: offer.addedWay,
                                value_with_discount: offer.value_with_discount
                            }],
                            show: true,
                            notes: '',
                            manualOffers: [],
                            isManualAdded: false,
                        };
                        this.specialType4Summaries.push(validHeadingSummary);
                    });
                    // Proceed with invalid heading offers as the usual type 4 offers
                    offersOfType = invalidHeadingOffers;
                }

                const existingSummary = currentSummaryByType.find(summary => summary.name === type.name);
                const existingManualOffers = existingSummary ? existingSummary.offers.filter(offer => offer.addedWay === 'manual' && offer.id) : [];

                const combinedOffers = [...offersOfType, ...existingManualOffers];

                const totalValue = this.calculateTotalValue(combinedOffers);
                const offers = combinedOffers.map(offer => ({
                    id: offer.id,
                    addedWay: offer.addedWay,
                    value_with_discount: offer.value_with_discount
                }));

                let typeSummary = {
                    name: type.name,
                    total: totalValue,
                    type: type.value,
                    offers,
                    show: !!totalValue, // Add show property
                    notes: existingSummary ? existingSummary.notes : '', // Add note property
                    isManualAdded: false,
                };

                if (!existingSummary || !existingSummary.manualOffers || existingSummary.manualOffers.length === 0) {
                    typeSummary.manualOffers = [{
                        id: "",
                        type: null,
                        value_with_discount: 0,
                        uniqueId: Date.now(),
                        addedWay: 'manual'
                    }];
                } else {
                    typeSummary.manualOffers = existingSummary.manualOffers;
                }

                return typeSummary;
            }).filter(summary => summary !== undefined); // Remove any undefined entries

            // Append special type 4 summaries to the final summaryByType
            this.summaryByType.push(...this.specialType4Summaries);


            // Sort summaryByType based on the custom order, placing type 4 summaries with valid headings at the end
            this.summaryByType.sort((a, b) => {
                const indexA = this.customOrder.indexOf(a.name);
                const indexB = this.customOrder.indexOf(b.name);

                if (a.type === 4 && b.type !== 4) {
                    // a is type 4 and b is not, put a after b
                    return 1;
                } else if (b.type === 4 && a.type !== 4) {
                    // b is type 4 and a is not, put b after a
                    return -1;
                } else if (a.type === 4 && b.type === 4) {
                    // Both are type 4, keep them in their order
                    return 0;
                } else if (indexA === -1 && indexB === -1) {
                    // Both elements are not in the custom order, sort them alphabetically by name
                    return a.name.localeCompare(b.name);
                } else if (indexA === -1) {
                    // a is not in the custom order, put it after b
                    return 1;
                } else if (indexB === -1) {
                    // b is not in the custom order, put it after a
                    return -1;
                } else {
                    // Both elements are in the custom order, sort them by the custom order
                    return indexA - indexB;
                }
            });
        },
        /**
         * Calculates the total area of a location by summing the area of all its rooms.
         * @param {Object} location - The location object containing rooms.
         * @returns {string} - The total area in square meters, formatted as a string, or "Nu" if no area.
         */
        locationArea(location) {
            const totalArea = location.rooms.reduce((sum, room) => sum + room.area.sq_m, 0);
            return totalArea ? `${this.formatNumber(this, totalArea, false)} m²` : "Nu";
        },
        /**
         * Starts the process of adding a new summary by setting the appropriate state.
         */
        startAddSummary() {
            this.addingNewSummary = true;
        },
        /**
         * Cancels the process of adding a new summary and resets the new summary form.
         */
        cancelAddSummary() {
            this.addingNewSummary = false;
            this.resetNewSummary();
        },
        /**
         * Adds a new summary to the list if the required fields are filled.
         * Alerts the user if any fields are missing.
         */
        addSummary() {
            if (this.newSummary.name) {
                const offersArray = this.newSummary.offers ? this.newSummary.offers : [];

                this.summaryByType.push({
                    id: uuidv4(),
                    name: this.newSummary.name,
                    offers: offersArray,
                    total: parseFloat(this.newSummary.total),
                    isManualAdded: true,
                    show: !!this.newSummary.total,
                    items: [],
                    isEditing: false,
                });

                this.cancelAddSummary();
            } else {
                alert("Vă rugăm completați toate câmpurile");
            }
        },
        /**
         * Adds selected offers to the current summary, ensuring no duplicate entries.
         * Filters out any empty manual offers before adding.
         */
        addOffersToSummary() {
            // Filter out any empty manual offers
            const filledManualOffers = this.currentSummary.manualOffers.filter(
                offer => offer.id && offer.type !== null && offer.value_with_discount
            );
            // Map selected offer IDs to the corresponding offers with id and list_price_value
            const selectedNormalOffers = this.selectedOffersForSummary.map(id => {
                const offer = this.summaryOffers.find(offer => offer.id === id);
                return offer ? {
                    id: offer.id,
                    value_with_discount: offer.list_price_value,
                    addedWay: 'normal'
                } : null;
            }).filter(offer => offer !== null);

            const allOffers = [...selectedNormalOffers, ...filledManualOffers];
            const uniqueOffers = allOffers.filter((offer, index, self) =>
                index === self.findIndex(o => o.id === offer.id && o.addedWay === offer.addedWay)
            );

            this.currentSummary.offers = uniqueOffers;
            this.currentSummary.total = this.calculateTotalValue(uniqueOffers);
            this.currentSummary.show = true;

            this.addOffersToSummaryModal = false;
            this.selectedOffersForSummary = [];
        },
        /**
         * Deletes a manual offer from the current summary.
         * Removes the offer from various lists and adds an empty manual offer if the list becomes empty.
         *
         * @param {Object} offer - The offer object to delete, containing at least `uniqueId` and `id`.
         */
        deleteManualOffer(offer) {
            const index = this.currentSummary.manualOffers.findIndex(o => o.uniqueId === offer.uniqueId);
            const indexInSummaryOffers = this.currentSummary.offers.findIndex(o => o.id === offer.id);
            const indexInSelectedOffersForSummary = this.selectedOffersForSummary.findIndex(offerId => offerId === parseInt(offer.id));

            if (indexInSelectedOffersForSummary !== -1) {
                this.selectedOffersForSummary.splice(indexInSelectedOffersForSummary, 1);
            }
            if (indexInSummaryOffers !== -1) {
                this.currentSummary.offers.splice(indexInSummaryOffers, 1);
            }
            if (index !== -1) {
                this.currentSummary.manualOffers.splice(index, 1);
                if (this.currentSummary.manualOffers.length === 0) {
                    this.addEmptyManualOffer();
                }
            }
        },
        /**
         * Resets the new summary form to its initial state.
         */
        resetNewSummary() {
            this.newSummary = {
                name: "",
                offers: [],
                total: 0,
            };
        },
        /**
         * Removes an offer from a summary.
         * Updates the total value of the summary and removes the offer from relevant lists.
         *
         * @param {Object} summary - The summary object from which the offer will be removed.
         * @param {Object} offer - The offer object to remove, containing at least `id` and `addedWay`.
         */
        removeOffer(summary, offer) {
            const index = summary.offers.findIndex(o => o.id === offer.id && o.addedWay === offer.addedWay);
            if (index !== -1) {
                summary.offers.splice(index, 1);
                summary.total = this.calculateTotalValue(summary.offers);

                // Remove from manualOffers if it's a manual offer
                if (offer.addedWay === 'manual') {
                    const manualIndex = this.currentSummary.manualOffers.findIndex(o => o.id === offer.id && o.uniqueId === offer.uniqueId);
                    if (manualIndex !== -1) {
                        this.currentSummary.manualOffers.splice(manualIndex, 1);
                    }
                }

                // Remove from selectedOffers if it exists
                const selectedIndex = this.selectedOffers.indexOf(offer.id);
                if (selectedIndex !== -1) {
                    this.selectedOffers.splice(selectedIndex, 1);
                }
            }
        },
        /**
         * Calculates the total value of a list of offers.
         *
         * @param {Array} offers - The array of offers to calculate the total value for.
         * @returns {number} - The total value of the offers.
         */
        calculateTotalValue(offers) {
            return offers.reduce((sum, offer) => {
                if (offer.addedWay === 'manual') {
                    return sum + parseFloat(offer.value_with_discount);
                }
                const matchedOffer = this.summaryOffers.find(o => o.id === offer.id);
                return sum + (matchedOffer ? parseFloat(matchedOffer.value_with_discount) : 0);
            }, 0);
        },
        /**
         * Displays the modal for adding offers to a summary.
         * Initializes the modal state and prepares the current summary for modification.
         *
         * @param {Object} summary - The summary object to which offers will be added.
         */
        showAddOffersToSummaryModal(summary) {
            this.currentSummary = summary;
            if (this.currentSummary && !this.currentSummary.manualOffers) {
                this.currentSummary.manualOffers = [];
                this.addEmptyManualOffer();
            }
            this.selectedOffersForSummary = summary.offers.map(offer => parseInt(offer.id));
            this.addOffersToSummaryModal = true;
        },
        /**
         * Closes the modal for adding offers to a summary.
         */
        closeAddOffersToSummaryModal() {
            this.addOffersToSummaryModal = false;
        },
        /**
         * Toggles the collapsed state of the offers section.
         */
        toggleOffersSectionCollapse() {
            this.isOffersCollapsed = !this.isOffersCollapsed;
        },
        /**
         * Returns the description text for a given type value.
         *
         * @param {number} typeValue - The value of the type to find the description for.
         * @returns {string} - The description text of the type or '--' if not found.
         */
        getTypeDescription(typeValue) {
            const type = this.types.find(t => t.value === typeValue);
            return type ? type.text : '--';
        },
        /**
         * Splits an array into chunks of a specified size.
         *
         * @param {Array} array - The array to be split into chunks.
         * @param {number} chunkSize - The size of each chunk.
         * @returns {Array} - An array of chunked arrays.
         */
        chunkArray(array, chunkSize) {
            const results = [];
            for (let i = 0; i < array.length; i += chunkSize) {
                results.push(array.slice(i, i + chunkSize));
            }
            return results;
        },
        /**
         * Formats a date into a readable string in Romanian locale.
         *
         * @param {string|Date} date - The date to format.
         * @returns {string} - The formatted date string.
         */
        formatDate(date) {
            return new Date(date).toLocaleString("ro-RO", {
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                second: "numeric",
            });
        },
        /**
         * Starts the process of adding a new service by setting the appropriate state.
         */
        startAddService(index = null) {
            this.newServiceIndex = index;
            this.addingNewService = true;
        },
        /**
         * Cancels the process of adding a new service and resets the new service form.
         */
        cancelAddService() {
            this.addingNewService = false;
            this.newService = { name: '', total: 0, notes: '' };
        },
        /**
         * Adds a new service to the list of services.
         * If the new service has a name, it is added to the services array, and the add service form is reset.
         */
        addService() {
            if (this.newService.name) {
                if (this.newService.total) {
                    this.newService.show = true;
                }
                if (this.newServiceIndex !== null) {

                    this.services.splice(this.newServiceIndex + 1, 0, { ...this.newService, id: this.services.length + 1 });

                } else {
                    this.services.push({ ...this.newService, id: this.services.length + 1 });
                }
                this.cancelAddService();
            } else {
                alert("Vă rugăm completați toate câmpurile");
            }
        },
        /**
         * Toggles the editing state of a service/summary.
         * When editing is enabled, the service/summary is set to be shown.
         *
         * @param {Object} ITEM - The service/summary object to toggle editing for.
         */
        toggleEditing(item) {
            item.isEditing = !item.isEditing;
            if (!item.isEditing && item.total) {
                item.show = true;
            } else if (!item.isEditing && !item.total) {
                item.show = false;
            }
        },
        /**
         * Deletes an item from the summary or a service array.
         * @param {Array} array - The array from which the item should be deleted.
         * @param {Object} item - The item object to delete from the array.
         */
        deleteItem(array, item) {
            const index = array.findIndex(i => i.id === item.id);
            if (index !== -1) {
                array.splice(index, 1);
            }
        },
        /**
         * Saves the summary, either creating a new one or updating an existing one based on the `isNewSummary` flag.
         *
         * @param {boolean} isNewSummary - Flag indicating whether to create a new summary (true) or update an existing one (false).
         * @async
         */
        async saveSummary(isNewSummary) {
            const summaryData = {
                client: this.$route.params.id,
                locations: this.selectedLocations,
                items: [
                    ...this.summaryByType.map(summary => ({
                        item_type: 'offer',
                        name: summary.name,
                        notes: summary.notes,
                        total: roundToTwo(summary.total ? summary.total : 0),
                        offers: summary.offers.map(offer => offer.id),
                        show: summary.show,
                        isManualAdded: summary.isManualAdded
                    })),
                    ...this.services.map(service => ({
                        item_type: 'service',
                        name: service.name,
                        notes: service.notes,
                        total: roundToTwo(service.total ? service.total : 0),
                        show: service.show
                    }))
                ]
            };

            try {
                if (this.currentSummaryId && !isNewSummary) {
                    // Update existing summary
                    await apiClient.put(`/api/main-summaries/${this.currentSummaryId}/`, summaryData);
                    showAlertModal(this.$store, 'Centralizatorul a fost salvat cu succes.', 'success', 3000);
                } else {
                    // Create new summarys
                    const response = await apiClient.post('/api/main-summaries/', summaryData);
                    this.currentSummaryId = response.data.id;
                    sessionStorage.setItem('currentSummaryId', this.currentSummaryId);
                    showAlertModal(this.$store, 'Centralizatorul a fost creat cu succes.', 'success', 3000);
                }
            } catch (error) {
                showAlertModal(this.$store, 'A apărut o eroare la salvarea centralizatorului.', 'danger', 12000);
                console.error('Error saving summary:', error);
            }
        },
    },
    watch: {
        selectedLocations: {
            handler: debounce(function () {
                this.generateSummary();
            }, 300),  // Adjust the debounce delay as needed
            deep: true
        },
    },
};
</script>

<style scoped>
.v-col {
    padding: 4px;
}

.custom-row {
    height: 40%;
    /* Adjust this value as needed */
}

.v-data-table th,
.v-data-table td {
    padding: 4px;
    /* Adjust this value as needed */
}
</style>