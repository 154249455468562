<template>
    <v-container style="height: 100vh;" class="d-flex align-items-center flex-column main-content">
        <v-row justify="center" class="flex-column text-center">
            <div class="d-flex flex-column justify-content-evenly h-100">
                <div>
                    <h1 class="text-white">Deautentificat!</h1>
                <p class="text-white">
                    Mulțumim pentru timpul petrecut astăzi în CRM-ul<br/>Sistema.
                </p>
                </div>
                <v-btn color="primary" class="mt-5" @click="redirectToLoginPage">
                    Reautentificare
                </v-btn>
            </div>
        </v-row>
    </v-container>
</template>

<script>
import {deleteAuthToken} from "@/utils/apiClient";

export default {
    methods: {
        redirectToLoginPage() {
            this.$router.push('/');
        }
    },
    created() {
        deleteAuthToken();
    }
}
</script>

<style scoped>
.main-content {
    width: 100%;
    height: 100vh;
    background: linear-gradient(177deg, #7963e4 50%, #172b4d calc(50% + 1px));
}
</style>
