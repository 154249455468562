export const UNIT_ACCESSORY_SETS = {
    basic: {
        name: 'Basic',
        value: 1,
    },
    standard: {
        name: 'Standard',
        value: 2,
    },
};

export const vacuumUnits = [
    {
        id: 1,
        product_code: '901.02.62',
        name: 'Aspirator Display 2',
        type: 'display',
        restrictions: { total_area: 200, last_connector_max_length: 35, needed_connectors: 8 },
        amortizor: 'nu',
        accessorySets: [],
    },
    {
        id: 2,
        product_code: '901.03.62',
        name: 'Aspirator Display 3',
        type: 'display',
        restrictions: { total_area: 350, last_connector_max_length: 45, needed_connectors: 14 },
        amortizor: '914.10.62',
        accessorySets: [UNIT_ACCESSORY_SETS.basic, UNIT_ACCESSORY_SETS.standard],
    },
    {
        id: 3,
        product_code: '901.04.62',
        name: 'Aspirator Display 4',
        type: 'display',
        restrictions: { total_area: 700, last_connector_max_length: 60, needed_connectors: 22 },
        amortizor: '914.10.62',
        accessorySets: [UNIT_ACCESSORY_SETS.basic, UNIT_ACCESSORY_SETS.standard],
    },
    {
        id: 4,
        product_code: '902.01.62',
        name: 'Aspirator Basic 1',
        type: 'basic',
        restrictions: { selected_installation_type: 2, total_area: 100, last_connector_max_length: 30, needed_connectors: 5 },
        amortizor: 'nu',
        accessorySets: [],
    },
    {
        id: 5,
        product_code: '902.02.62',
        name: 'Aspirator Basic 2',
        type: 'basic',
        restrictions: { total_area: 200, last_connector_max_length: 35, needed_connectors: 8 },
        amortizor: 'nu',
        accessorySets: [],
    },
    {
        id: 6,
        product_code: '902.03.62',
        name: 'Aspirator Basic 4',
        type: 'basic',
        restrictions: { total_area: 350, last_connector_max_length: 45, needed_connectors: 14 },
        amortizor: '914.10.62',
        accessorySets: [UNIT_ACCESSORY_SETS.basic, UNIT_ACCESSORY_SETS.standard],
    },
    {
        id: 7,
        product_code: '904.01.62',
        name: 'Aspirator "AP" pentru montaj ingropat',
        type: 'ap',
        restrictions: { total_area: 250, last_connector_max_length: 35, needed_connectors: 8 },
        amortizor: 'nu',
        accessorySets: [],
    },
    {
        id: 8,
        product_code: '904.05.62',
        name: 'Aspirator "AP" pentru montaj aparent',
        type: 'ap',
        restrictions: { total_area: 250, last_connector_max_length: 35, needed_connectors: 8 },
        amortizor: 'nu',
        accessorySets: [],
    },
];
