<template>
    <v-row class="mt-2">
        <v-col cols="12" sm="12" md="12" lg="6" class="w-100">
            <v-card elevation="3">
                <v-toolbar>
                    <v-toolbar-title>Etaje</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <v-list>
                        <v-list-item v-for="(floor, index) in floor_areas" :key="floor.floor">
                            <v-row class="mb-2 pt-2">
                                <v-col cols="1" lg="1" md="1" sm="1" class="d-flex flex-column justify-content-center">
                                    <v-checkbox
                                        v-model="selected_floors"
                                        :value="floor.floor"
                                        hide-details
                                        density="compact"
                                        :checked="true"
                                        @change="handleChange"
                                        @update:modelValue="() => {if (selected_installation_type) {handleSelectedFloors()}}"
                                    ></v-checkbox>
                                </v-col>
                                <v-col cols="11" lg="5" md="5" sm="11">
                                    <v-combobox
                                        v-model="floor.name"
                                        :items="availableFloors"
                                        item-title="name"
                                        item-value="value"
                                        label="Nume etaj"
                                        hide-details
                                        variant="outlined"
                                        dense
                                        density="compact"
                                        @blur="updateFloorName(index, { name: floor.name, value: availableFloors.find(f => f.name === floor.name)?.value || null })"
                                        @keydown.enter="updateFloorName(index, { name: floor.name, value: availableFloors.find(f => f.name === floor.name)?.value || null })"
                                    ></v-combobox>
                                </v-col>
                                <v-col cols="10" lg="4" md="4" sm="10">
                                    <v-text-field
                                        v-model="floor.area"
                                        label="Suprafață totală (m²)"
                                        hide-details
                                        dense
                                        density="compact"
                                        variant="outlined"
                                        @blur="() => { if (selected_installation_type) {updateSelectedFloors(floor); updateNeededConnectors();} }"
                                        @keydown.enter="() => { if (selected_installation_type) {updateSelectedFloors(floor); updateNeededConnectors();} }"
                                        @keydown.tab="() => { if (selected_installation_type) {updateSelectedFloors(floor); updateNeededConnectors();} }"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="2" lg="2" md="2" sm="2">
                                    <v-btn icon size="small">
                                        <v-icon>mdi-dots-vertical</v-icon>
                                        <v-menu activator="parent">
                                            <v-list>
                                                <v-list-item @click="addFloor(index)">
                                                    <v-list-item-title>Adaugă etaj</v-list-item-title>
                                                </v-list-item>
                                                <v-list-item v-if="floor_areas.length > 1" @click="deleteFloor(index)">
                                                    <v-list-item-title>Șterge etaj</v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </v-btn>
                                </v-col>
                                <v-divider
                                    thickness="1"
                                    class="border-opacity-100"
                                    v-if="index < floor_areas.length - 1 && $vuetify.display.mdAndDown"
                                ></v-divider>
                            </v-row>
                        </v-list-item>
                    </v-list>
                    <v-divider></v-divider>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                v-model="needed_connectors"
                                type="number"
                                variant="outlined"
                                density="compact"
                                label="Nr. de prize necesare"
                                class="no-arrows"
                                :rules="neededConnectorsMaxRules"
                                :error="isNeededConnectorsError"
                                :error-messages="isNeededConnectorsErrorMessage"
                                persistent-hint
                                :class="isNeededConnectorsManuallyEdited ? 'text-warning' : ''"
                                :color="isNeededConnectorsManuallyEdited ? 'warning' : ''"
                                :hint="isNeededConnectorsManuallyEdited ? 'Numărul de prize a fost modificat manual!' : ''"
                                @input="isNeededConnectorsManuallyEdited = true"
                                @blur="updateNeededConnectors(needed_connectors)"
                                @keydown.tab="updateNeededConnectors(needed_connectors)"
                                @keydown.enter="updateNeededConnectors(needed_connectors)"
                            >
                                <template v-slot:append-inner>
                                    <v-btn
                                        v-if="isNeededConnectorsManuallyEdited"
                                        color="danger"
                                        variant="plain"
                                        @click="resetAndRecalculateNeededConnectors"
                                    >
                                        <v-icon>mdi-refresh</v-icon>
                                        <v-tooltip activator="parent" location="top" class="text-center">
                                            Atenție! Acest buton resetează nr. de prize
                                            <br/>
                                            necesare la valoarea inițială calculată.
                                        </v-tooltip>
                                    </v-btn>
                                </template>
                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="12" lg="6" md="12" sm="12">
            <v-card elevation="3" v-if="floor_areas && floor_areas.length > 0">
                <v-toolbar>
                    <v-toolbar-title>
                        Setări
                    </v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="12" md="6" lg="6">
                            <v-text-field
                                v-model="last_connector_max_length"
                                variant="outlined"
                                density="compact"
                                :hide-details="last_connector_max_length <= 60"
                                label="Lungime maximă pană la ultima priză"
                                :rules="lastConnectorMaxLengthRules"
                                @input="handleChange"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" lg="6">
                            <v-select
                                label="Tip instalație"
                                v-model="selected_installation_type"
                                :items="installationTypes"
                                variant="outlined"
                                hide-details
                                density="compact"
                                @update:modelValue="() => {updateNeededConnectors(); handleChange()}"
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col v-if="selected_installation_type === 2" cols="12" sm="12" md="6" lg="6">
                            <v-select
                                label="Tip furtun"
                                v-model="selected_hose_type"
                                :items="hoseTypes"
                                variant="outlined"
                                hide-details
                                density="compact"
                                @update:modelValue="handleChange"
                            ></v-select>
                        </v-col>
                        <v-col v-if="selected_installation_type" cols="12" sm="12" md="6" lg="6">
                            <v-checkbox
                                v-model="has_ecoflex"
                                hide-details
                                density="compact"
                                label="Set Ecoflex bucătărie"
                                class="mb-3"
                                @change="handleChange"
                            ></v-checkbox>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="12" md="12" lg="12">
                            <v-select
                                v-model="selected_accessory_set"
                                :items="connectorAccessorySets"
                                item-title="title"
                                item-value="value"
                                label="Selectează setul de accesorii"
                                variant="outlined"
                                density="compact"
                                hide-details
                                @update:modelValue="handleChange()"
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="12" md="12" lg="12">
                            <v-select
                                v-model="selected_vacuum_unit"
                                :items="filteredVacuumUnits"
                                item-title="name"
                                item-value="product_code"
                                label="Selectează unitatea de aspirare"
                                variant="outlined"
                                density="compact"
                                return-object
                                ref="unitModelSelect"
                            >
                                <template v-slot:item="{ item }">
                                    <v-list-item @click="selectUnit(item)">
                                        <v-list-item-title
                                            :style="{ fontWeight: item.value.isFirstMatch ? 'bold' : 'normal' }">
                                            {{ item.title }}
                                        </v-list-item-title>
                                    </v-list-item>
                                </template>
                            </v-select>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="12" v-if="floor_areas && floor_areas.length > 0">
            <div v-if="$vuetify.display.mdAndDown">
                <v-card v-for="(connector, index) in selected_connectors" :key="index" elevation="3" class="mt-3">
                    <v-toolbar>
                        <v-toolbar-title>Priză</v-toolbar-title>
                        <v-toolbar-items>
                            <v-btn v-if="selected_connectors.length > 1" @click="removeConnector(index)" variant="plain"
                                   icon>
                                <v-icon color="danger">mdi-delete</v-icon>
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="12" md="12" lg="3">
                                <v-select
                                    v-model="connector.type"
                                    :items="!connector.is_manually_added || connector.type === 3 ? connector.availableTypes : connectorTypes"
                                    item-title="title"
                                    item-value="value"
                                    density="compact"
                                    hide-details
                                    label="Selectează tipul conectorului"
                                    variant="outlined"
                                    :disabled="connector.type === 3"
                                    @update:modelValue="updateConnectorProperties(index)"
                                ></v-select>
                            </v-col>
                            <v-col cols="12" sm="12" md="12" lg="3">
                                <div>
                                    <v-select
                                        v-model="connector.color"
                                        :items="connector.availableColors"
                                        item-title="title"
                                        item-value="value"
                                        :disabled="
                                            !connector.type ||
                                            connector.availableColors.length === 1 ||
                                            connector.availableColors.length === 0
                                        "
                                        label="Selectează culoarea prizei"
                                        variant="outlined"
                                        @update:modelValue="handleConnectorColorChange(connector)"
                                    ></v-select>
                                    <v-tooltip
                                        v-if="connector.availableColors && connector.availableColors.length === 0"
                                        location="top"
                                        activator="parent"
                                    >
                                        Nu există variante de culori pentru priza selectată
                                    </v-tooltip>
                                    <v-tooltip
                                        v-else-if="connector.availableColors && connector.availableColors.length === 1"
                                        location="top"
                                        activator="parent"
                                    >
                                        {{ `Singura culoare disponibilă este ${connector.availableColors[0].title}` }}
                                    </v-tooltip>
                                </div>
                            </v-col>
                            <v-col cols="12" sm="12" md="12" lg="3">
                                <div>
                                    <v-select
                                        v-model="connector.electrical_dose"
                                        :items="connector.availableElectricalDose"
                                        item-title="title"
                                        item-value="value"
                                        :disabled="connector.availableElectricalDose?.length <= 1 || !connector.type"
                                        label="Selectează doza prizei"
                                        variant="outlined"
                                        @update:modelValue="handleChange"
                                    ></v-select>
                                    <v-tooltip
                                        v-if="connector.availableElectricalDose && connector.availableElectricalDose.length === 0"
                                        location="top"
                                        activator="parent"
                                    >
                                        Nu există doze pentru priza selectată
                                    </v-tooltip>
                                    <v-tooltip
                                        v-else-if="connector.availableElectricalDose && connector.availableElectricalDose.length === 1"
                                        location="top"
                                        activator="parent"
                                    >
                                        {{
                                            `Singura doză disponibilă este ${connector.availableElectricalDose[0].title}`
                                        }}
                                    </v-tooltip>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="d-flex flex-row justify-content-end">
                        <v-btn @click="addConnector(index)" color="success">Adaugă priză</v-btn>
                    </v-card-actions>
                </v-card>
            </div>
            <div v-if="$vuetify.display.lgAndUp && floor_areas && floor_areas.length > 0">
                <v-card elevation="3">
                    <v-hover open-delay="150" close-delay="150" v-slot="{ isHovering, props }">
                        <v-toolbar @click="isConnectorListCollapsed = !isConnectorListCollapsed" v-bind="props"
                                   class="cursor-pointer">
                            <v-toolbar-title>Prize</v-toolbar-title>
                            <v-expand-transition>
                                <div
                                    v-if="isHovering"
                                    class="d-flex justify-content-center align-items-center"
                                    style="background: rgb(var(--v-theme-on-surface-variant)); position: absolute; top: 65%; left: 50%"
                                >
                                    <v-icon v-if="isConnectorListCollapsed">mdi-chevron-double-down</v-icon>
                                    <v-icon v-else>mdi-chevron-double-up</v-icon>
                                </div>
                            </v-expand-transition>
                        </v-toolbar>
                    </v-hover>
                    <v-expand-transition>
                        <v-card-text v-if="!isConnectorListCollapsed">
                            <v-row v-for="(connector, index) in selected_connectors" :key="index">
                                <v-col cols="12" sm="12" md="12" lg="3">
                                    <v-select
                                        v-model="connector.type"
                                        :items="!connector.is_manually_added || connector.type === 3 ? connector.availableTypes : connectorTypes"
                                        item-title="title"
                                        item-value="value"
                                        density="compact"
                                        hide-details
                                        label="Selectează tipul prizei"
                                        variant="outlined"
                                        :disabled="connector.type === 3"
                                        @update:modelValue="updateConnectorProperties(index)"
                                    ></v-select>
                                </v-col>
                                <v-col cols="12" sm="12" md="12" lg="3">
                                    <div>
                                        <v-select
                                            v-model="connector.color"
                                            :items="connector.availableColors"
                                            item-title="title"
                                            item-value="value"
                                            :disabled="
                                                !connector.type ||
                                                connector.availableColors.length === 1 ||
                                                connector.availableColors.length === 0
                                            "
                                            label="Selectează culoarea prizei"
                                            variant="outlined"
                                            density="compact"
                                            hide-details
                                            @update:modelValue="handleChange"
                                        ></v-select>
                                        <v-tooltip
                                            v-if="connector.availableColors && connector.availableColors.length === 0"
                                            location="top"
                                            activator="parent"
                                        >
                                            Nu există variante de culori pentru priza selectată
                                        </v-tooltip>
                                        <v-tooltip
                                            v-else-if="connector.availableColors && connector.availableColors.length === 1"
                                            location="top"
                                            activator="parent"
                                        >
                                            {{
                                                `Singura culoare disponibilă este ${connector.availableColors[0].title}`
                                            }}
                                        </v-tooltip>
                                    </div>
                                </v-col>
                                <v-col cols="12" sm="12" md="12" lg="5">
                                    <div>
                                        <v-select
                                            v-model="connector.electrical_dose"
                                            :items="connector.availableElectricalDose"
                                            item-title="title"
                                            item-value="value"
                                            :disabled="connector.availableElectricalDose?.length <= 1 || !connector.type"
                                            label="Selectează doza prizei"
                                            variant="outlined"
                                            density="compact"
                                            hide-details
                                            @update:modelValue="handleChange"
                                        ></v-select>
                                        <v-tooltip
                                            v-if="connector.availableElectricalDose && connector.availableElectricalDose.length === 0"
                                            location="top"
                                            activator="parent"
                                        >
                                            Nu există doze pentru priza selectată
                                        </v-tooltip>
                                        <v-tooltip
                                            v-else-if="connector.availableElectricalDose && connector.availableElectricalDose.length === 1"
                                            location="top"
                                            activator="parent"
                                        >
                                            {{
                                                `Singura doză disponibilă este ${connector.availableElectricalDose[0].title}`
                                            }}
                                        </v-tooltip>
                                    </div>
                                </v-col>
                                <v-col cols="12" sm="12" md="12" lg="1"
                                       class="d-flex flex-row justify-content-end align-items-center">
                                    <v-btn v-if="selected_connectors.length > 1" @click="removeConnector(index)"
                                           variant="plain" icon>
                                        <v-icon color="danger">mdi-delete</v-icon>
                                        <v-tooltip activator="parent" location="top">Șterge priză</v-tooltip>
                                    </v-btn>
                                    <v-btn @click="addConnector(index)" variant="plain" icon>
                                        <v-icon color="success">mdi-plus</v-icon>
                                        <v-tooltip activator="parent" location="top">Adaugă priză</v-tooltip>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-expand-transition>
                </v-card>
            </div>
        </v-col>
    </v-row>
</template>

<script>
import {UNIT_ACCESSORY_SETS, vacuumUnits} from '@/Vacuum/VacuumData';
import {showAlertModal} from "@/utils/utils";
import apiClient from "@/utils/apiClient";

export default {
    name: 'VacuumSetup',
    props: {
        location: {
            type: Object,
            required: true,
        },
        offerId: {
            type: String,
        }
    },
    emits: ['update-vacuum-setup'],
    data() {
        return {
            newFloor: '',
            newFloorArea: '',
            selected_floors: [],
            floor_areas: [],
            floors: [],
            last_connector_max_length: 30,
            lastConnectorMaxLengthRules: [
                value => {
                    if (value <= 60) return true;
                    return 'Valoarea maximă acceptată este 60';
                },
            ],
            selected_installation_type: null,
            installationTypes: [
                {
                    title: 'Easy',
                    value: 1,
                },
                {
                    title: 'Standard',
                    value: 2,
                },
            ],
            selected_hose_type: 2,
            hoseTypes: [
                {
                    title: 'Furtun cu întrerupător',
                    value: 1,
                },
                {
                    title: 'Furtun fără întrerupător',
                    value: 2,
                },
            ],
            has_ecoflex: false,
            needed_connectors: null,
            neededConnectorsMaxRules: [
                value => {
                    if (value <= 22) return true;
                    return 'Valoarea maximă acceptată este 22';
                },
            ],
            isNeededConnectorsManuallyEdited: false,
            selected_connectors: [],
            connectorTypes: [
                {title: 'Bucătărie', value: 3},
                {title: 'Gewiss', value: 1},
                {title: 'Biticino', value: 2},
                {title: 'Easy rigips', value: 4},
                {title: 'Easy zidărie', value: 5},
                {title: 'ECO', value: 6},
                {title: 'Aparentă', value: 7},
                {title: 'Pardoseală', value: 8},
                {title: 'exterior', value: 9},
            ],
            standardConnectors: [
                {title: 'Gewiss', value: 1},
                {title: 'Biticino', value: 2},
            ],
            easyConnectors: [
                {title: 'Easy rigips', value: 4},
                {title: 'Easy zidărie', value: 5},
            ],
            easyTypes: [4, 5], // Types restricted to Easy installation
            standardTypes: [1, 2, 6], // Types restricted to Standard installation
            connectorColors: {
                1: [
                    {title: 'Alb', value: 1},
                    {title: 'Negru', value: 2},
                    {title: 'Silver', value: 3},
                ], // Gewiss
                2: [
                    {title: 'Alb', value: 1},
                    {title: 'Negru', value: 2},
                    {title: 'Silver', value: 3},
                ], // Biticino
                3: [
                    {title: 'Alb', value: 1},
                    {title: 'Negru', value: 2},
                    {title: 'Silver', value: 3},
                ], // Bucatarie
                4: [{title: 'Alb', value: 1}], // Easy rigips
                5: [{title: 'Alb', value: 1}], // Easy zidarie
                6: [{title: 'Alb', value: 1}], // ECO
                7: [{title: 'Alb', value: 1}], // Aparenta
                8: [{title: 'Silver', value: 3}], // Pardoseala
                9: [{title: 'Alb', value: 1}], // exterior
            },
            connectorOrnaments: {
                1: true,
                2: true,
                3: true,
                4: false,
                5: false,
                6: false,
                7: false,
                8: false,
                9: false,
            },
            connectorSupportOrnaments: {
                1: true,
                2: true,
                3: false,
                4: false,
                5: false,
                6: false,
                7: false,
                8: false,
                9: false,
            },
            connectorElectricalDose: {
                1: [
                    {title: 'Doză dreptunghiulară', value: 1},
                    {title: 'Doză dreptunghiulară cu racordare spate', value: 2},
                ],
                2: [
                    {title: 'Doză dreptunghiulară', value: 1},
                    {title: 'Doză dreptunghiulară cu racordare spate', value: 2},
                ],
                3: [{title: 'Doză pentru bucătărie', value: 3}],
                4: [
                    {title: 'Doză Easy perete gipscarton', value: 4},
                ],
                5: [
                    {title: 'Doză Easy perete zidărie', value: 5},
                ],
                6: [
                    {title: 'Doză dreptunghiulară', value: 1},
                    {title: 'Doză dreptunghiulară cu racordare spate', value: 2},
                ],
                7: [],
                8: [
                    {title: 'Doză pardoseală', value: 6},
                    {title: 'Doză pardoseală spate', value: 7},
                ],
                9: [
                    {title: 'Doză dreptunghiulară', value: 1},
                    {title: 'Doză dreptunghiulară cu racordare spate', value: 2},
                ],
            },
            connectorAccessorySets: [
                {title: 'Standard', value: 1},
                {title: 'Basic', value: 2},
            ],
            vacuumUnits,
            UNIT_ACCESSORY_SETS,
            selectedUnits: [],
            defaultVacuumUnit: {
                id: null,
                product_code: '',
                name: '',
                last_connector_max_length: null,
                maxConnectors: null,
                recommendedArea: null,
                restrictions: {},
                amortizor: '',
                accessorySets: [],
            },
            selected_vacuum_unit: this.defaultVacuumUnit,
            isConnectorListCollapsed: false,
            total_area: 0,
            vacuumSetup: {},
            selected_accessory_set: 1,
            isNeededConnectorsError: false,
            isNeededConnectorsErrorMessage: '',
        };
    },
    computed: {
        /**
         * @computed
         * @returns {Array} The list of available floors that are not yet selected, with each floor value converted to a string.
         */
        availableFloors() {
            return this.floors
                .filter(floor => !this.selected_floors.includes(`${floor.value}`))
                .map(floor => ({
                    ...floor,
                    value: floor.value.toString(), // Ensure value is a string for comparison
                }));
        },
        /**
         * @computed
         * @returns {Array} The list of vacuum units filtered based on dynamic restrictions.
         * If the selected vacuum unit's product code is not found in the results, resets the selected vacuum unit to the default.
         */
        filteredVacuumUnits() {
            let results = this.vacuumUnits.filter(unit => {
                // Dynamic restriction matching
                return Object.keys(unit.restrictions).every(key => {
                    if (key === 'selectedInstallationType') {
                        return unit.restrictions[key] === this[key];
                    }
                    return this[key] <= unit.restrictions[key];
                });
            });

            // Determine the first matching unit for each type (excluding 'ap')
            const firstMatchingUnits = {};
            results.forEach(unit => {
                if (unit.type !== 'ap' && !firstMatchingUnits[unit.type]) {
                    firstMatchingUnits[unit.type] = unit.product_code;
                }
            });

            if (this.vacuumSetup && this.vacuumSetup.selected_vacuum_unit) {
                const selectedProductCode = this.vacuumSetup.selected_vacuum_unit.product_code;
                const isSelectedUnitInResults = results.some(unit => unit.product_code === selectedProductCode);

                if (!isSelectedUnitInResults) {
                    this.resetSelectedVacuumUnit();
                }
            }

            return results.map(unit => ({
                ...unit,
                isFirstMatch: firstMatchingUnits[unit.type] === unit.product_code
            }));
        },
    },
    async mounted() {
        if (this.offerId) {
            this.floors = [...this.$store.state.floorsDefinitions];

            // Check if offerId is present and fetch the offer data including the vacuum setup
            await this.fetchVacuumSetup(this.offerId);
        } else {
            this.initializeFloorAreas();
            this.initializeSelectedFloors();
            this.updateVacuumSetup(); // Initialize vacuum setup on mount
        }
    },
    methods: {
        /**
         * Fetches the vacuum setup by offer ID.
         * @param {string} offerId - The ID of the offer to fetch the vacuum setup for.
         * @returns {Promise<void>}
         */
        async fetchVacuumSetup(offerId) {
            try {
                const response = await apiClient.get(`/api/vacuum-setup/${offerId}/`);
                if (response.status === 200) {
                    const vacuumSetup = await response.data;
                    this.vacuumSetup = this.transformVacuumSetupFromBackend(vacuumSetup);
                    this.populateComponent();
                    this.handleChange();
                }
            } catch (error) {
                console.error('Error fetching vacuum setup:', error);
            }
        },
        /**
         * Transforms the backend vacuum setup data to the format used in the frontend.
         * @param {Object} vacuumSetup - The vacuum setup data from the backend.
         * @returns {Object} The transformed vacuum setup data for the frontend.
         */
        transformVacuumSetupFromBackend(vacuumSetup) {
            this.needed_connectors = vacuumSetup.needed_connectors || vacuumSetup.selected_connectors.length;
            this.last_connector_max_length = vacuumSetup.last_connector_max_length;
            this.has_ecoflex = vacuumSetup.has_ecoflex;
            this.selected_hose_type = vacuumSetup.selected_hose_type;

            const getAvailableTypes = (connector) => {
                if (connector.type === 3 && !connector.is_manually_added) {
                    return [{title: 'Bucătărie', value: 3}];
                } else if (connector.is_manually_added) {
                    return this.connectorTypes;
                } else if (vacuumSetup.selected_installation_type === 2) {
                    return this.standardConnectors;
                } else {
                    return this.easyConnectors;
                }
            };

            return {
                total_area: vacuumSetup.total_area,
                selected_connectors: vacuumSetup.selected_connectors.map(connector => ({
                    ...connector,
                    availableColors: this.connectorColors[connector.type] || [],
                    availableElectricalDose: this.connectorElectricalDose[connector.type] || [],
                    availableTypes: getAvailableTypes(connector),
                })),
                selected_vacuum_unit: vacuumSetup.selected_vacuum_unit,
                selected_accessory_set: vacuumSetup.selected_accessory_set,
                selected_installation_type: vacuumSetup.selected_installation_type,
                floor_areas: vacuumSetup.floor_areas,
            };
        },
        /**
         * Populates the component with the vacuum setup data.
         */
        populateComponent() {
            this.total_area = this.vacuumSetup.total_area;
            this.selected_connectors = this.vacuumSetup.selected_connectors;
            this.selected_vacuum_unit = this.vacuumSetup.selected_vacuum_unit;
            this.selected_accessory_set = this.vacuumSetup.selected_accessory_set;
            this.selected_installation_type = this.vacuumSetup.selected_installation_type;
            this.floor_areas = this.vacuumSetup.floor_areas || [];
            this.selected_floors = this.floor_areas.map(floor => floor.floor);
        },
        /**
         * Selects a vacuum unit and updates the selected vacuum unit.
         * This method also closes the dropdown menu and triggers the handleChange method.
         * @param {Object} unit - The unit object containing the vacuum unit details.
         */
        selectUnit(unit) {
            this.selected_vacuum_unit = unit.raw;
            this.$refs.unitModelSelect.menu = false;
            this.handleChange();
        },
        /**
         * Resets the selected vacuum unit to the default vacuum unit.
         */
        resetSelectedVacuumUnit() {
            this.selected_vacuum_unit = this.defaultVacuumUnit;
            this.vacuumSetup.selected_vacuum_unit = this.defaultVacuumUnit;
        },
        /**
         * Adds a new connector to the selected_connectors array at the specified index.
         * The new connector object is initialized with default values.
         * @param {number} index - The index at which to add the new connector.
         */
        addConnector(index) {
            this.selected_connectors.push();
            let newConnector = {
                type: null,
                color: null,
                availableColors: [],
                availableElectricalDose: [],
                availableTypes: [...this.connectorTypes],
                is_manually_added: true,
            };
            this.selected_connectors.splice(index + 1, 0, newConnector);
        },
        /**
         * Removes a connector from the selected_connectors array at the specified index.
         * @param {number} index - The index of the connector to remove.
         */
        removeConnector(index) {
            this.selected_connectors.splice(index, 1);
            this.handleChange();
        },
        /**
         * Deletes a floor from the floorAreas array at the specified index.
         * Also removes the floor from the selectedFloors array if it exists,
         * and updates the needed connectors if they have not been manually edited.
         * @param {number} index - The index of the floor to delete.
         */
        deleteFloor(index) {
            // Remove the floor from the floorAreas array
            const removedFloor = this.floor_areas.splice(index, 1)[0];
            // Remove the floor from the selectedFloors array if it exists
            const selectedIndex = this.selected_floors.indexOf(removedFloor.floor);
            if (selectedIndex !== -1) {
                this.selected_floors.splice(selectedIndex, 1);
            }
            // Update connectors if needed
            if (!this.isNeededConnectorsManuallyEdited) {
                this.updateNeededConnectors();
            }
        },
        /**
         * Updates the selectedFloors array by adding a floor if it doesn't already exist in the array.
         * If the floor is already found or if it contains a single falsy value (null or undefined) in the selected floors,
         * it should be replaced, otherwise, it should be pushed.
         * @param {Object} floor - The floor object containing floor details.
         */
        updateSelectedFloors(floor) {
            // Remove any falsy values (null or undefined) from selectedFloors
            this.selected_floors = this.selected_floors.filter(f => f);

            // Find the index of the existing floor in selectedFloors
            const floorIndex = this.selected_floors.findIndex(f => f === floor.floor);

            if (floorIndex !== -1) {
                // Replace the existing floor in selectedFloors
                this.selected_floors.splice(floorIndex, 1, floor.floor);
            } else {
                // Push the new floor to selectedFloors
                this.selected_floors.push(floor.floor);
            }
            this.handleChange();
        },
        /**
         * Initializes the floor areas based on the location rooms.
         * This method calculates the total area for each floor and updates the floorAreas object.
         * It also initializes the floors array from the store state.
         */
        initializeFloorAreas() {
            const areas = [];
            this.floors = [...this.$store.state.floorsDefinitions];

            if (this.location.rooms && this.location.rooms.length > 0) {
                this.location.rooms.forEach(room => {
                    let existingFloorIndex = areas.findIndex(item => item.floor === room.floor.toString());

                    let roundedArea = parseFloat(room.area.sq_m.toFixed(2)); // Round area to 2 decimals

                    if (existingFloorIndex !== -1) {
                        // Accumulate the area for the existing floor
                        areas[existingFloorIndex].area += roundedArea;
                        areas[existingFloorIndex].area = parseFloat(areas[existingFloorIndex].area.toFixed(2)); // Ensure the accumulated area is rounded
                    } else {
                        // Create a new floor entry and initialize its area
                        let newFloor = {
                            floor: room.floor.toString(),
                            name: this.floors.find(floor => floor.value === room.floor)?.name,
                            area: roundedArea,
                        };
                        areas.push(newFloor);
                    }
                });
                this.floor_areas = areas;
            } else {
                // If no rooms are present, initialize floorAreas with a single newFloor entry
                this.floor_areas.push({
                    floor: '2',
                    name: 'Parter',
                    area: null,
                });
            }
        },
        /**
         * Initializes the selected floors based on the existing floor areas.
         * This method ensures that the floors are checked on page load.
         */
        initializeSelectedFloors() {
            this.selected_floors = this.floor_areas.map(floorArea => floorArea?.floor?.toString());
        },
        /**
         * Adds a new floor to the floor areas and selected floors.
         * This method handles the addition of new floors and their respective areas.
         * It updates the floorAreas and floors arrays, and resets the newFloor and newFloorArea inputs.
         * If the floor is not already in the selectedFloors, it is added.
         * @param {number} index - The index at which to add the new floor.
         */
        addFloor(index) {
            let manuallyAddedFloor = {
                floor: null,
                name: null,
                area: null,
            };

            this.floor_areas.splice(index + 1, 0, manuallyAddedFloor);
        },
        /**
         * Updates the number of needed connectors based on the total area of selected floors or a manually inputted value.
         * If manualConnectors is provided, it sets the needed connectors directly, otherwise it recalculates based on total area.
         * This method also initializes the selected_connectors array with the appropriate number of connector objects.
         * It validates the number of connectors to ensure it does not exceed the maximum allowed value of 22.
         * If the number of connectors exceeds the limit, an error message is displayed and the operation is halted.
         * The number of connectors is divided by 50 for standard installation type and by 100 for easy installation type.
         *
         * @param {number} [manualConnectors=0] - The manually inputted number of connectors, if provided.
         */
        updateNeededConnectors(manualConnectors = 0) {
            this.isNeededConnectorsError = false;
            this.isNeededConnectorsErrorMessage = '';

            if (!manualConnectors) {
                const totalArea = this.selected_floors.reduce((sum, floor) => {
                    // Find the floor area in floorAreas
                    const floorArea = this.floor_areas.find(item => item.floor === floor);
                    // If the floorArea is found, add its area to the sum
                    if (floorArea) {
                        sum += parseInt(floorArea.area) || 0;
                    }
                    return sum;
                }, 0);

                if (totalArea) {
                    this.total_area = totalArea;
                }


                let divider = 50;
                if (this.selected_installation_type === 1) {
                    divider = 100;
                }

                let result = Math.ceil(totalArea / divider);

                if (result > 22) {
                    showAlertModal(
                        this.$store,
                        `Numărul de prize rezultate pe baza etajelor selectate (<b>${result}</b>) depășește valoarea maximă admisă de <b>22</b>! <br/> Vă rugăm să ajustați selecția pentru a respecta limita.`,
                        'danger',
                        12000
                    );
                    this.isNeededConnectorsError = true;
                    this.isNeededConnectorsErrorMessage = 'Valoarea maximă acceptată este 22';
                    return;
                }

                this.needed_connectors = Math.ceil(totalArea / divider);
            }

            if (manualConnectors && manualConnectors > 22) {
                this.needed_connectors = 22;
            }

            const newConnectors = Array.from({length: this.needed_connectors}, () => ({
                type: this.selected_installation_type === 2 ? 1 : 4,
                color: 1,
                availableTypes: this.selected_installation_type === 2 ? this.standardConnectors : this.easyConnectors,
                availableColors: this.selected_installation_type === 2 ? this.connectorColors[1] : this.connectorColors[4],
                availableElectricalDose: this.selected_installation_type === 2 ? this.connectorElectricalDose[1] : this.connectorElectricalDose[4],
                electrical_dose: this.selected_installation_type === 2
                    ? this.connectorElectricalDose[1][0]?.value
                    : this.connectorElectricalDose[4][0]?.value,
                is_manually_added: false,
            })); // Initialize connectors array with unique objects

            // Ensure the Bucatarie connector is always the first one
            const bucatarieConnector = {
                type: 3,
                color: 1,
                availableColors: [
                    {title: 'Alb', value: 1},
                    {title: 'Negru', value: 2},
                    {title: 'Silver', value: 3}
                ],
                availableElectricalDose: [
                    {title: 'Doză pentru bucătărie', value: 3}
                ],
                availableTypes: [{title: 'Bucătărie', value: 3}],
                electrical_dose: 3,
            };

            this.selected_connectors = [bucatarieConnector, ...newConnectors];

            this.selected_connectors.forEach(connector => {
                this.handleConnectorColorChange(connector);
            });
        },
        /**
         * Updates the properties (color, electrical dose, accessory set) of a connector based on the selected type.
         * @param {number} index - The index of the connector in the selected_connectors array.
         * This method updates the availableColors, availableElectricalDose, and availableAccessorySets arrays for the selected connector type.
         * It also sets the default values for color, electrical dose, and accessory set.
         */
        updateConnectorProperties(index) {
            const connector = this.selected_connectors[index];
            connector.availableColors = this.connectorColors[connector.type] || [];
            connector.color =
                connector.availableColors.length > 1
                    ? connector.availableColors.find(color => color.value === 1).value
                    : connector?.availableColors[0]?.value;
            this.handleConnectorColorChange(connector);

            connector.availableElectricalDose = this.connectorElectricalDose[connector.type] || [];
            connector.electrical_dose = connector.availableElectricalDose[0]?.value || null;

            this.updateVacuumSetup();
        },
        /**
         * Resets and recalculates the needed connectors.
         * This method sets the isNeededConnectorsManuallyEdited flag to false and calls updateNeededConnectors to recalculate the connectors.
         */
        resetAndRecalculateNeededConnectors() {
            this.isNeededConnectorsManuallyEdited = false;
            this.updateNeededConnectors();
            this.updateVacuumSetup();
        },
        /**
         * Updates the floor name based on the input value.
         * @param {number|string} index - The index of the floor in the floorAreas array.
         * @param {Object} newValue - The new value for the floor, containing its details.
         */
        updateFloorName(index, newValue) {
            // Check if newValue is valid
            if (!newValue || (!newValue.value && !newValue.name)) {
                return; // Exit early if newValue is invalid
            }

            let selectedFloor;

            // Extract value and name correctly from nested structures
            const value = newValue.value || (newValue.name && newValue.name.value);
            const name = newValue.name?.name || newValue.name;

            // Check if newValue matches an existing floor in availableFloors
            const existingFloor = this.availableFloors.find(floor => floor.value === value || floor.name === name);

            if (existingFloor) {
                selectedFloor = existingFloor;
            } else {
                // Generate a unique value for manually added floors only if value is not defined
                const uniqueValue = value || `manual-${Date.now()}`;

                // Handle manually added floor
                selectedFloor = {
                    value: uniqueValue,
                    name: name || uniqueValue, // Fallback to unique value if name is not provided
                };
            }

            if (selectedFloor) {
                // Ensure floorAreas[index] exists
                if (!this.floor_areas[index]) {
                    this.floor_areas[index] = {};
                }

                // Get the current floor value before updating
                const currentFloorValue = this.floor_areas[index].floor ? this.floor_areas[index].floor.toString() : null;

                // Update the floorAreas array
                this.floor_areas[index].floor = selectedFloor.value.toString();
                this.floor_areas[index].name = selectedFloor.name;

                // Check if the floor already exists in the selectedFloors array
                const floorIndex = this.selected_floors.findIndex(floor => floor === currentFloorValue);

                if (floorIndex !== -1) {
                    // Update the existing floor in the selectedFloors array
                    this.selected_floors[floorIndex] = selectedFloor.value.toString();
                } else {
                    // Add the new floor to the selectedFloors array
                    this.selected_floors.push(selectedFloor.value.toString());
                }
            }
            this.handleChange();
        },
        /**
         * Updates the vacuum setup and emits it to the parent component.
         * This method constructs the vacuumSetup object based on the current state and emits an event with the object.
         */
        updateVacuumSetup() {
            const total_area = this.selected_floors.reduce((sum, floor) => {
                // Find the floor area in floorAreas
                const floorArea = this.floor_areas.find(item => item.floor === floor);
                // If the floorArea is found, add its area to the sum
                if (floorArea) {
                    sum += parseInt(floorArea.area) || 0;
                }
                return sum;
            }, 0);

            const has_bucatarie_connector = this.selected_connectors.some(connector => connector.type === 3);

            this.vacuumSetup = {
                total_area: total_area,
                has_bucatarie_connector: has_bucatarie_connector,
                has_ecoflex: this.has_ecoflex,
                selected_connectors: this.selected_connectors,
                selected_vacuum_unit: this.selected_vacuum_unit,
                selected_accessory_set: this.selected_accessory_set,
                selected_installation_type: this.selected_installation_type,
                floor_areas: this.floor_areas,
                needed_connectors: this.needed_connectors,
                last_connector_max_length: this.last_connector_max_length,
                selected_hose_type: this.selected_hose_type,
            };

            this.$emit('update-vacuum-setup', this.vacuumSetup);
        },
        /**
         * Handles changes to the vacuum setup and updates it accordingly.
         * This method is called when there are changes that require updating the vacuum setup.
         */
        handleChange() {
            this.updateVacuumSetup();
        },
        /**
         * Handles changes to the connector color and updates the connector properties.
         * @param {Object} connector - The connector object whose color is being changed.
         */
        handleConnectorColorChange(connector) {
            const connectorType = connector.type;
            connector.ornament = this.connectorOrnaments[connectorType];
            connector.ornamentSupport = this.connectorSupportOrnaments[connectorType];

            this.handleChange();
        },
        handleSelectedFloors() {
            if (!this.isNeededConnectorsManuallyEdited) {
                this.updateNeededConnectors();
            }
            this.updateVacuumSetup();
        },
    },
};
</script>

<style scoped></style>
