import {
    checkBandaPerforataConditions,
    checkCableConditions, checkColierMetalicConditions, checkCotPVCConditions,
    checkFaseteConditions,
    checkTeavaPVCConditions
} from "@/Vacuum/VacuumLogic";

export const products = [
    {
        "id": 1,
        "product_code": "905.04.62",
        "name": "Doza dreptunghiulara",
        "type": "doza",
        "subtype": "dreptunghiulara",
        "specifications": {
            "type": "doza",
            "subtype": "dreptunghiulara",
            "connector": "normal",
        },
        "requirements": [
            {
                "type": "teava",
                "subtype": "PVC",
                "diameter": "50mm",
                "thickness": "2,1mm",
                "length": 2,
                "quantity": 9,
            },
            {
                "type": "cot",
                "subtype": "PVC",
                "angle": "45",
                "diameter": "50mm",
                "connection": "IE",
                "quantity": 1.5,
            },
            {
                "type": "cot",
                "subtype": "PVC",
                "angle": "45",
                "diameter": "50mm",
                "connection": "II",
                "quantity": 1.5,
            },
            {
                "type": "cot",
                "subtype": "PVC",
                "angle": "90",
                "diameter": "50mm",
                "connection": "II",
                "quantity": 1.5,
            },
        ],
        "conditions": [
            (context) => {
                const H3 = context.vacuumSetup.total_area;
                const H7 = context.resultedProducts.find(p => p.type === "doza" && p.subtype === "dreptunghiulara" && p.specifications.connector === "normal")?.quantity || 0;
                const H8 = context.resultedProducts.find(p => p.type === "doza" && p.subtype === "dreptunghiulara" && p.specifications.connector === "spate")?.quantity || 0;
                const D2 = context.vacuumSetup.has_bucatarie_connector ? 1 : 0;
                const H9 = context.resultedProducts.find(p => p.type === "doza" && p.subtype === "bucatarie")?.quantity || 0;

                checkCableConditions(context, H7, H8, H3, D2);
                checkFaseteConditions(context, H3, H7, H8, H9);
            }
        ],
    },
    {
        "id": 2,
        "product_code": "905.05.62",
        "name": "Doza dreptunghiulara cu racordare din spate",
        "type": "doza",
        "subtype": "dreptunghiulara",
        "specifications": {
            "type": "doza",
            "subtype": "dreptunghiulara",
            "connector": "spate"
        },
        "requirements": [
            {
                "type": "teava",
                "subtype": "PVC",
                "diameter": "50mm",
                "thickness": "2,1mm",
                "length": 2,
                "quantity": 9,
            },
            {
                "type": "cot",
                "subtype": "PVC",
                "angle": "45",
                "diameter": "50mm",
                "connection": "IE",
                "quantity": 1.5,
            },
            {
                "type": "cot",
                "subtype": "PVC",
                "angle": "45",
                "diameter": "50mm",
                "connection": "II",
                "quantity": 1.5,
            },
            {
                "type": "cot",
                "subtype": "PVC",
                "angle": "90",
                "diameter": "50mm",
                "connection": "II",
                "quantity": 1.5,
            },
        ],
        "conditions": [
            (context) => {
                const H3 = context.vacuumSetup.total_area;
                const H7 = context.resultedProducts.find(p => p.type === "doza" && p.subtype === "dreptunghiulara" && p.specifications.connector === "normal")?.quantity || 0;
                const H8 = context.resultedProducts.find(p => p.type === "doza" && p.subtype === "dreptunghiulara" && p.specifications.connector === "spate")?.quantity || 0;
                const D2 = context.vacuumSetup.has_bucatarie_connector ? 1 : 0;
                const H9 = context.resultedProducts.find(p => p.type === "doza" && p.subtype === "bucatarie")?.quantity || 0;

                checkCableConditions(context, H7, H8, H3, D2);
                checkFaseteConditions(context, H3, H7, H8, H9);
            }
        ],
    },
    {
        "id": 3,
        "product_code": "905.06.62",
        "name": "Doza pentru bucatarie",
        "type": "doza",
        "subtype": "bucatarie",
        "specifications": {
            "type": "doza",
            "subtype": "bucatarie"
        },
        "requirements": [
            {
                "type": "racord",
                "subtype": "priza",
                "variant": "bucatarie",
                "quantity": 1,
            },
            {
                "type": "prelungitor",
                "subtype": "priza",
                "variant": "bucatarie",
                "quantity": 1,
            },
            {
                "type": "teava",
                "subtype": "flexibila",
                "usage": "bucatarie",
                "quantity": 1,
            },
        ],
        "conditions": [
            (context) => {
                const H3 = context.vacuumSetup.total_area;
                const H7 = context.resultedProducts.find(p => p.type === "doza" && p.subtype === "dreptunghiulara" && p.specifications.connector === "normal")?.quantity || 0;
                const H8 = context.resultedProducts.find(p => p.type === "doza" && p.subtype === "dreptunghiulara" && p.specifications.connector === "spate")?.quantity || 0;
                const H9 = context.resultedProducts.find(p => p.type === "doza" && p.subtype === "bucatarie")?.quantity || 0;

                checkFaseteConditions(context, H3, H7, H8, H9);
            },
        ],
    },
    {
        "id": 4,
        "product_code": "905.07.62",
        "name": "Prelungitor doza",
        "type": "prelungitor",
        "subtype": "doza",
        "specifications": {
            "type": "prelungitor",
            "subtype": "doza"
        }
    },
    {
        "id": 5,
        "product_code": "905.10.62",
        "name": "Doza pardoseala",
        "type": "doza",
        "subtype": "pardoseala",
        "specifications": {
            "type": "doza",
            "subtype": "pardoseala",
            "connector": "normal"
        }
    },
    {
        "id": 6,
        "product_code": "905.11.62",
        "name": "Doza pardoseala cu racordare din spate",
        "type": "doza",
        "subtype": "pardoseala",
        "specifications": {
            "type": "doza",
            "subtype": "pardoseala",
            "connector": "spate"
        }
    },
    {
        "id": 7,
        "product_code": "908.01.62",
        "name": "Teava PVC d.50mm, grosime 2,1mm (bare de 2m)",
        "type": "teava",
        "subtype": "PVC",
        "specifications": {
            "type": "teava",
            "subtype": "PVC",
            "diameter": "50mm",
            "thickness": "2,1mm",
            "length": 2
        },
        "conditions": [
            (context) => {
                if (context) {
                    checkTeavaPVCConditions(context);
                }
            }
        ]
    },
    {
        "id": 8,
        "product_code": "908.11.62",
        "name": "Teava PVC d.40mm, grosime 2,1mm (bare de 2m)",
        "type": "teava",
        "subtype": "PVC",
        "specifications": {
            "type": "teava",
            "subtype": "PVC",
            "diameter": "40mm",
            "thickness": "2,1mm",
            "length": 2
        }
    },
    {
        "id": 9,
        "product_code": "908.02.62",
        "name": "Cot PVC la 45 d.50mm IE",
        "type": "cot",
        "subtype": "PVC",
        "specifications": {
            "type": "cot",
            "subtype": "PVC",
            "angle": "45",
            "diameter": "50mm",
            "connection": "IE"
        },
        "conditions": [
            (context) => {
                checkCotPVCConditions(context, "45", "50mm", "IE", 14);
            }
        ],
    },
    {
        "id": 10,
        "product_code": "908.12.62",
        "name": "Cot PVC la 45 d.40mm IE",
        "type": "cot",
        "subtype": "PVC",
        "specifications": {
            "type": "cot",
            "subtype": "PVC",
            "angle": "45",
            "diameter": "40mm",
            "connection": "IE"
        }
    },
    {
        "id": 11,
        "product_code": "908.03.62",
        "name": "Cot PVC la 45 d.50mm II",
        "type": "cot",
        "subtype": "PVC",
        "specifications": {
            "type": "cot",
            "subtype": "PVC",
            "angle": "45",
            "diameter": "50mm",
            "connection": "II"
        },
        "conditions": [
            (context) => {
                checkCotPVCConditions(context, "45", "50mm", "II", null);
            }
        ],
    },
    {
        "id": 12,
        "product_code": "908.13.62",
        "name": "Cot PVC la 45 d.40mm II",
        "type": "cot",
        "subtype": "PVC",
        "specifications": {
            "type": "cot",
            "subtype": "PVC",
            "angle": "45",
            "diameter": "40mm",
            "connection": "II"
        }
    },
    {
        "id": 13,
        "product_code": "908.04.62",
        "name": "Cot PVC la 90 d.50mm II",
        "type": "cot",
        "subtype": "PVC",
        "specifications": {
            "type": "cot",
            "subtype": "PVC",
            "angle": "90",
            "diameter": "50mm",
            "connection": "II"
        },
        "conditions": [
            (context) => {
                checkCotPVCConditions(context, "90", "50mm", "II", null);
            }
        ],
    },
    {
        "id": 14,
        "product_code": "908.05.62",
        "name": "Derivatie PVC la 45 d.50mm II",
        "type": "derivatie",
        "subtype": "PVC",
        "specifications": {
            "type": "derivatie",
            "subtype": "PVC",
            "angle": "45",
            "diameter": "50mm",
            "connection": "II"
        },
        "requirements": [],
    },
    {
        "id": 15,
        "product_code": "908.14.62",
        "name": "Derivatie PVC la 45 d.40mm II",
        "type": "derivatie",
        "subtype": "PVC",
        "specifications": {
            "type": "derivatie",
            "subtype": "PVC",
            "angle": "45",
            "diameter": "40mm",
            "connection": "II"
        }
    },
    {
        "id": 16,
        "product_code": "908.06.62",
        "name": "Derivatie PVC la 90 d.50mm II",
        "type": "derivatie",
        "subtype": "PVC",
        "specifications": {
            "type": "derivatie",
            "subtype": "PVC",
            "angle": "90",
            "diameter": "50mm",
            "connection": "II"
        }
    },
    {
        "id": 17,
        "product_code": "908.07.62",
        "name": "Mufa prelungire PVC d.50mm",
        "type": "mufa",
        "subtype": "PVC",
        "specifications": {
            "type": "mufa",
            "subtype": "PVC",
            "diameter": "50mm"
        },
    },
    {
        "id": 18,
        "product_code": "908.15.62",
        "name": "Mufa prelungire PVC d.40mm",
        "type": "mufa",
        "subtype": "PVC",
        "specifications": {
            "type": "mufa",
            "subtype": "PVC",
            "diameter": "40mm"
        }
    },
    {
        "id": 19,
        "product_code": "908.08.62",
        "name": "Reductie PVC d.82-50mm",
        "type": "reductie",
        "subtype": "PVC",
        "specifications": {
            "type": "reductie",
            "subtype": "PVC",
            "diameter": "82-50mm"
        }
    },
    {
        "id": 20,
        "product_code": "908.16.62",
        "name": "Reductie PVC d.50-40mm",
        "type": "reductie",
        "subtype": "PVC",
        "specifications": {
            "type": "reductie",
            "subtype": "PVC",
            "diameter": "50-40mm"
        }
    },
    {
        "id": 21,
        "product_code": "908.09.62",
        "name": "Gratar evacuare d.82mm",
        "type": "gratar",
        "subtype": "PVC",
        "specifications": {
            "type": "gratar",
            "subtype": "PVC",
            "diameter": "82mm"
        },
    },
    {
        "id": 22,
        "product_code": "908.10.62",
        "name": "Dop PVC d.50mm",
        "type": "dop",
        "subtype": "PVC",
        "specifications": {
            "type": "dop",
            "subtype": "PVC",
            "diameter": "50mm"
        }
    },
    {
        "id": 23,
        "product_code": "909.01.62",
        "name": "Suport fixare aspirator",
        "type": "suport",
        "subtype": "aspirator",
        "specifications": {
            "type": "suport",
            "subtype": "aspirator",
        }
    },
    {
        "id": 24,
        "product_code": "909.02.62",
        "name": "Dop proba",
        "type": "dop",
        "subtype": "proba",
        "specifications": {
            "type": "dop",
            "subtype": "proba"
        }
    },
    {
        "id": 25,
        "product_code": "909.03.62",
        "name": "Cablu electric 4x1 (25 m) (pentru control la distanta aspirator Basic)",
        "type": "cablu",
        "subtype": "electric",
        "specifications": {
            "type": "cablu",
            "subtype": "electric",
            "dimensions": "4x1",
            "length": 25,
        },
        "requirements": [
            {
                "type": "diblu",
                "subtype": "bataie",
                "quantity": 1,
            },
        ],
    },
    {
        "id": 26,
        "product_code": "909.04.62",
        "name": "Cablu electric 2x1 (25 m)",
        "type": "cablu",
        "subtype": "electric",
        "specifications": {
            "type": "cablu",
            "subtype": "electric",
            "dimensions": "2x1",
            "length": 25
        },
        "requirements": [
            {
                "type": "adeziv",
                "subtype": "adeziv",
                "weight": "125g",
                "quantity": 1,
            },
            {
                "type": "diblu",
                "subtype": "bataie",
                "quantity": 1,
            },
        ],
        "conditions": [
            (context) => {
                checkBandaPerforataConditions(context);
            }
        ],
    },
    {
        "id": 27,
        "product_code": "909.05.62",
        "name": "Cablu electric 2x1 (50 m)",
        "type": "cablu",
        "subtype": "electric",
        "specifications": {
            "type": "cablu",
            "subtype": "electric",
            "dimensions": "2x1",
            "length": 50
        },
        "requirements": [
            {
                "type": "adeziv",
                "subtype": "adeziv",
                "weight": "250g",
                "quantity": 1,
            },
            {
                "type": "diblu",
                "subtype": "bataie",
                "quantity": 1,
            },
        ],
        "conditions": [
            (context) => {
                checkBandaPerforataConditions(context);
            }
        ],
    },
    {
        "id": 28,
        "product_code": "909.06.62",
        "name": "Cablu electric 2x1 (100 m)",
        "type": "cablu",
        "subtype": "electric",
        "specifications": {
            "type": "cablu",
            "subtype": "electric",
            "dimensions": "2x1",
            "length": 100
        },
        "requirements": [
            {
                "type": "adeziv",
                "subtype": "adeziv",
                "weight": "500g",
                "quantity": 1,
            },
        ],
        "conditions": [
            (context) => {
                checkBandaPerforataConditions(context);
            }
        ],
    },
    {
        "id": 29,
        "product_code": "909.08.65",
        "name": "Adeziv 125 gr.",
        "type": "adeziv",
        "subtype": "adeziv",
        "specifications": {
            "type": "adeziv",
            "subtype": "adeziv",
            "weight": "125g"
        }
    },
    {
        "id": 30,
        "product_code": "909.09.65",
        "name": "Adeziv 250 gr.",
        "type": "adeziv",
        "subtype": "adeziv",
        "specifications": {
            "type": "adeziv",
            "subtype": "adeziv",
            "weight": "250g"
        }
    },
    {
        "id": 31,
        "product_code": "909.10.65",
        "name": "Adeziv 500 gr.",
        "type": "adeziv",
        "subtype": "adeziv",
        "specifications": {
            "type": "adeziv",
            "subtype": "adeziv",
            "weight": "500g└"
        }
    },
    {
        "id": 32,
        "product_code": "909.11.65",
        "name": "Fasete 30 cm",
        "type": "fasete",
        "subtype": "fasete",
        "specifications": {
            "type": "fasete",
            "subtype": "fasete",
            "length": 30
        }
    },
    {
        "id": 33,
        "product_code": "1401.02.65",
        "name": "Banda perforata 12mm",
        "type": "banda",
        "subtype": "perforata",
        "specifications": {
            "type": "banda",
            "subtype": "perforata",
            "width": "12mm"
        }
    },
    {
        "id": 34,
        "product_code": "1401.03.65",
        "name": "Diblu prin bataie 6x40",
        "type": "diblu",
        "subtype": "bataie",
        "specifications": {
            "type": "diblu",
            "subtype": "bataie",
        }
    },
    {
        "id": 35,
        "product_code": "1401.12.81",
        "name": "Prezon M8x60mm",
        "type": "prezon",
        "subtype": "flexibil",
        "specifications": {
            "type": "prezon",
            "subtype": "flexibil",
            "dimensions": "M8x60mm"
        }
    },
    {
        "id": 36,
        "product_code": "1401.13.81",
        "name": "Prezon M8x140mm",
        "type": "prezon",
        "subtype": "flexibil",
        "specifications": {
            "type": "prezon",
            "subtype": "flexibil",
            "dimensions": "M8x140mm"
        }
    },
    {
        "id": 37,
        "product_code": "1401.14.81",
        "name": "Diblu 12x70",
        "type": "diblu",
        "subtype": "standard",
        "specifications": {
            "type": "diblu",
            "subtype": "standard",
            "dimensions": "12x70"
        }
    },
    {
        "id": 38,
        "product_code": "1401.17.81",
        "name": "Brida M8 6/4\" (48-53mm)",
        "type": "brida",
        "subtype": "M8",
        "specifications": {
            "type": "brida",
            "subtype": "M8",
            "dimensions": "48-53mm"
        }
    },
    {
        "id": 39,
        "product_code": "909.13.62",
        "name": "Doza curent tip 503",
        "type": "doza",
        "subtype": "curent",
        "specifications": {
            "type": "doza",
            "subtype": "curent",
            "variant": "503"
        }
    },
    {
        "id": 40,
        "product_code": "915.01.62",
        "name": "Kit \"Easy\" pentru pereti din rigips",
        "type": "kit",
        "subtype": "Easy",
        "specifications": {
            "type": "kit",
            "subtype": "Easy",
            "variant": "rigips"
        },
        "requirements": [
            {
                "type": "diblu",
                "subtype": "bataie",
                "quantity": 1,
            },
            {
                "type": "banda",
                "subtype": "perforata",
                "width": "12mm",
                "quantity": 1,
            },
            {
                "type": "set",
                "subtype": "Easy",
                "variant": "furtun",
                "length": 12,
                "quantity": 1,
            },
            {
                "type": "teava",
                "subtype": "PVC",
                "diameter": "50mm",
                "thickness": "2,1mm",
                "length": 2,
                "quantity": 6,
            },
            {
                "type": "cot",
                "subtype": "PVC",
                "angle": "45",
                "diameter": "50mm",
                "connection": "IE",
                "quantity": 2,
            },
            {
                "type": "cot",
                "subtype": "PVC",
                "angle": "45",
                "diameter": "50mm",
                "connection": "II",
                "quantity": 2,
            },
            {
                "type": "mufa",
                "subtype": "PVC",
                "diameter": "50mm",
                "quantity": 3,
            },
        ],
    },
    {
        "id": 41,
        "product_code": "915.02.62",
        "name": "Kit \"Easy\" pentru pereti din zidarie",
        "type": "kit",
        "subtype": "Easy",
        "specifications": {
            "type": "kit",
            "subtype": "Easy",
            "variant": "zidarie"
        },
        "requirements": [
            {
                "type": "diblu",
                "subtype": "bataie",
                "quantity": 1,
            },
            {
                "type": "banda",
                "subtype": "perforata",
                "width": "12mm",
                "quantity": 1,
            },
            {
                "type": "set",
                "subtype": "Easy",
                "variant": "furtun",
                "length": 12,
                "quantity": 1,
            },
            {
                "type": "teava",
                "subtype": "PVC",
                "diameter": "50mm",
                "thickness": "2,1mm",
                "length": 2,
                "quantity": 6,
            },
            {
                "type": "cot",
                "subtype": "PVC",
                "angle": "45",
                "diameter": "50mm",
                "connection": "IE",
                "quantity": 2,
            },
            {
                "type": "cot",
                "subtype": "PVC",
                "angle": "45",
                "diameter": "50mm",
                "connection": "II",
                "quantity": 2,
            },
            {
                "type": "mufa",
                "subtype": "PVC",
                "diameter": "50mm",
                "quantity": 3,
            },
        ],
    },
    {
        "id": 44,
        "product_code": "915.11.62",
        "name": "Teava \"Easy\" PVC d.2\" grosime 1,6mm (bare de 2m)",
        "type": "teava",
        "subtype": "Easy",
        "specifications": {
            "type": "teava",
            "subtype": "Easy",
            "diameter": "d.2\"",
            "thickness": "1,6mm",
            "length": 2
        }
    },
    {
        "id": 45,
        "product_code": "915.12.62",
        "name": "Mufa \"Easy\" d.2\"",
        "type": "mufa",
        "subtype": "Easy",
        "specifications": {
            "type": "mufa",
            "subtype": "Easy",
            "diameter": "d.2\""
        }
    },
    {
        "id": 46,
        "product_code": "915.13.62",
        "name": "Adaptor niplu \"Easy\" d.2\"",
        "type": "adaptor",
        "subtype": "niplu",
        "specifications": {
            "type": "adaptor",
            "subtype": "niplu",
            "diameter": "d.2\"",
            "variant": "Easy",
        }
    },
    {
        "id": 47,
        "product_code": "915.14.62",
        "name": "Curba \"Easy\" larga la 22,5 d.2\" II",
        "type": "curba",
        "subtype": "Easy",
        "specifications": {
            "type": "curba",
            "subtype": "Easy",
            "angle": "22,5",
            "diameter": "d.2\"",
            "connection": "II"
        }
    },
    {
        "id": 48,
        "product_code": "915.15.62",
        "name": "Curba \"Easy\" larga la 45 d.2\" II",
        "type": "curba",
        "subtype": "Easy",
        "specifications": {
            "type": "curba",
            "subtype": "Easy",
            "angle": "45",
            "diameter": "d.2\"",
            "connection": "II"
        }
    },
    {
        "id": 49,
        "product_code": "915.16.62",
        "name": "Curba \"Easy\" larga la 90 d.2\" II",
        "type": "curba",
        "subtype": "Easy",
        "specifications": {
            "type": "curba",
            "subtype": "Easy",
            "angle": "90",
            "diameter": "d.2\"",
            "connection": "II"
        }
    },
    {
        "id": 50,
        "product_code": "901.02.62",
        "name": "Aspirator Display 2",
        "type": "aspirator",
        "subtype": "display",
        "specifications": {
            "type": "aspirator",
            "subtype": "display",
            "variant": 2,
        },
        "requirements": [
            {
                "type": "suport",
                "subtype": "aspirator",
                "quantity": 1,
            },
            {
                "type": "reductie",
                "subtype": "PVC",
                "diameter": "82-50mm",
                "quantity": 1,
            },
            {
                "type": "gratar",
                "subtype": "PVC",
                "diameter": "82mm",
                "quantity": 1,
            },
            {
                "type": "filtru",
                "subtype": "Display 2",
                "quantity": 0,
            },
            {
                "type": "teava",
                "subtype": "flexibila",
                "usage": "bucatarie",
                "quantity": 1,
            },
            {
                "type": "colier",
                "subtype": "metalic",
                "dimensions": "50-70",
                "quantity": 2,
            },
        ],
        "recommendations": [
            {
                "type": "perie",
                "subtype": "covoare",
            },
            {
                "type": "perie",
                "subtype": "tapiterie",
            },
            {
                "type": "separator",
                "subtype": "lichide",
            },
            {
                "type": "filtru",
                "subtype": "Display 2",
            },
            {
                "type": "set",
                "subtype": "ecoflex",
            },
        ],
        "conditions": [],
    },
    {
        "id": 51,
        "product_code": "901.03.62",
        "name": "Aspirator Display 3",
        "type": "aspirator",
        "subtype": "display",
        "specifications": {
            "type": "aspirator",
            "subtype": "display",
            "variant": 3,
        },
        "requirements": [
            {
                "type": "reductie",
                "subtype": "PVC",
                "diameter": "82-50mm",
                "quantity": 1,
            },
            {
                "type": "gratar",
                "subtype": "PVC",
                "diameter": "82mm",
                "quantity": 1,
            },
            {
                "type": "suport",
                "subtype": "aspirator",
                "quantity": 1,
            },
            {
                "type": "amortizor",
                "usage": "sunet",
                "quantity": 1,
            },
            {
                "type": "filtru",
                "subtype": "Display 3-4",
                "quantity": 0,
            },
            {
                "type": "teava",
                "subtype": "flexibila",
                "usage": "bucatarie",
                "quantity": 1,
            },
            {
                "type": "colier",
                "subtype": "metalic",
                "dimensions": "50-70",
                "quantity": 2,
            },
        ],
        "recommendations": [
            {
                "type": "perie",
                "subtype": "covoare",
            },
            {
                "type": "perie",
                "subtype": "tapiterie",
            },
            {
                "type": "separator",
                "subtype": "lichide"
            },
            {
                "type": "filtru",
                "subtype": "Display 3-4"
            },
            {
                "type": "set",
                "subtype": "ecoflex",
            },
        ],
    },
    {
        "id": 52,
        "product_code": "901.04.62",
        "name": "Aspirator Display 4",
        "type": "aspirator",
        "subtype": "display",
        "specifications": {
            "type": "aspirator",
            "subtype": "display",
            "variant": 4,
        },
        "requirements": [
            {
                "type": "reductie",
                "subtype": "PVC",
                "diameter": "82-50mm",
                "quantity": 1,
            },
            {
                "type": "gratar",
                "subtype": "PVC",
                "diameter": "82mm",
                "quantity": 1,
            },
            {
                "type": "suport",
                "subtype": "aspirator",
                "quantity": 1,
            },
            {
                "type": "amortizor",
                "usage": "sunet",
                "quantity": 1,
            },
            {
                "type": "filtru",
                "subtype": "Display 3-4",
                "quantity": 0,
            },
            {
                "type": "teava",
                "subtype": "flexibila",
                "usage": "bucatarie",
                "quantity": 1,
            },
            {
                "type": "colier",
                "subtype": "metalic",
                "dimensions": "50-70",
                "quantity": 2,
            },
        ],
        "recommendations": [
            {
                "type": "perie",
                "subtype": "covoare",
            },
            {
                "type": "perie",
                "subtype": "tapiterie",
            },
            {
                "type": "separator",
                "subtype": "lichide"
            },
            {
                "type": "filtru",
                "subtype": "Display 3-4",
                "quantity": 0,
            },
            {
                "type": "set",
                "subtype": "ecoflex",
            },
        ],
    },
    {
        "id": 53,
        "product_code": "901.05.62",
        "name": "Conexiune internet",
        "type": "conexiune",
        "subtype": "internet",
        "specifications": {
            "type": "conexiune",
            "usage": "internet"
        }
    },
    {
        "id": 54,
        "product_code": "902.01.62",
        "name": "Aspirator Basic 1",
        "type": "aspirator",
        "subtype": "Basic",
        "specifications": {
            "type": "aspirator",
            "subtype": "Basic",
            "variant": 1,
        },
        "requirements": [
            {
                "type": "reductie",
                "subtype": "PVC",
                "diameter": "82-50mm",
                "quantity": 1,
            },
            {
                "type": "gratar",
                "subtype": "PVC",
                "diameter": "82mm",
                "quantity": 1,
            },
            {
                "type": "suport",
                "subtype": "aspirator",
                "quantity": 1,
            },
            {
                "type": "adaptor",
                "subtype": "exhaustare",
                "variant": "aer",
                "quantity": 1,
            },
            {
                "type": "filtru",
                "subtype": "Basic 1",
                "quantity": 0,
            },
            {
                "type": "teava",
                "subtype": "flexibila",
                "usage": "bucatarie",
                "quantity": 1,
            },
            {
                "type": "colier",
                "subtype": "metalic",
                "dimensions": "50-70",
                "quantity": 2,
            },
        ],
        "recommendations": [
            {
                "type": "perie",
                "subtype": "covoare",
            },
            {
                "type": "perie",
                "subtype": "tapiterie",
            },
            {
                "type": "separator",
                "subtype": "lichide"
            },
            {
                "type": "filtru",
                "subtype": "Basic 1"
            },
            {
                "type": "set",
                "subtype": "ecoflex",
            },
            {
                "type": "set",
                "subtype": "ecoflex",
            },
        ],
    },
    {
        "id": 55,
        "product_code": "902.02.62",
        "name": "Aspirator Basic 2",
        "type": "aspirator",
        "subtype": "Basic",
        "specifications": {
            "type": "aspirator",
            "subtype": "Basic",
            "variant": 2,
        },
        "requirements": [
            {
                "type": "reductie",
                "subtype": "PVC",
                "diameter": "82-50mm",
                "quantity": 1,
            },
            {
                "type": "gratar",
                "subtype": "PVC",
                "diameter": "82mm",
                "quantity": 1,
            },
            {
                "type": "suport",
                "subtype": "aspirator",
                "quantity": 1,
            },
            {
                "type": "adaptor",
                "subtype": "exhaustare",
                "variant": "aer",
                "quantity": 1,
            },
            {
                "type": "filtru",
                "subtype": "Basic 2-4",
                "quantity": 0,
            },
            {
                "type": "teava",
                "subtype": "flexibila",
                "usage": "bucatarie",
                "quantity": 1,
            },
            {
                "type": "colier",
                "subtype": "metalic",
                "dimensions": "50-70",
                "quantity": 2,
            },
        ],
        "recommendations": [
            {
                "type": "perie",
                "subtype": "covoare",
            },
            {
                "type": "perie",
                "subtype": "tapiterie",
            },
            {
                "type": "separator",
                "subtype": "lichide"
            },
            {
                "type": "filtru",
                "subtype": "Basic 2-4"
            },
            {
                "type": "set",
                "subtype": "ecoflex",
            },
        ],
    },
    {
        "id": 56,
        "product_code": "902.03.62",
        "name": "Aspirator Basic 4",
        "type": "aspirator",
        "subtype": "Basic",
        "specifications": {
            "type": "aspirator",
            "subtype": "Basic",
            "variant": 4,
        },
        "requirements": [
            {
                "type": "reductie",
                "subtype": "PVC",
                "diameter": "82-50mm",
                "quantity": 1,
            },
            {
                "type": "gratar",
                "subtype": "PVC",
                "diameter": "82mm",
                "quantity": 1,
            },
            {
                "type": "suport",
                "subtype": "aspirator",
                "quantity": 1,
            },
            {
                "type": "amortizor",
                "usage": "sunet",
                "quantity": 1,
            },
            {
                "type": "filtru",
                "subtype": "Basic 2-4",
                "quantity": 0,
            },
            {
                "type": "teava",
                "subtype": "flexibila",
                "usage": "bucatarie",
                "quantity": 1,
            },
            {
                "type": "colier",
                "subtype": "metalic",
                "dimensions": "50-70",
                "quantity": 2,
            },
        ],
        "recommendations": [
            {
                "type": "perie",
                "subtype": "covoare",
            },
            {
                "type": "perie",
                "subtype": "tapiterie",
            },
            {
                "type": "separator",
                "subtype": "lichide"
            },
            {
                "type": "filtru",
                "subtype": "Basic 2-4"
            },
            {
                "type": "set",
                "subtype": "ecoflex",
            },
        ],
        "conditions": [
            (context) => {
                const productIndex = context.resultedProducts.findIndex(p => {
                    return p.type === "aspirator" &&
                        p.subtype === "Basic" &&
                        p.specifications.variant === 4
                });
                if (productIndex !== -1) {
                    if (context.resultedProducts[productIndex].quantity > 0) {
                        let electricalCableForBasicVacuum = {
                            "id": 25,
                            "product_code": "909.03.62",
                            "name": "Cablu electric 4x1 (25 m) (pentru control la distanta aspirator Basic)",
                            "type": "cablu",
                            "subtype": "electric",
                            "quantity": 15,
                            "specifications": {
                                "type": "cablu",
                                "subtype": "electric",
                                "dimensions": "4x1",
                                "length": 25,
                            }
                        };
                        context.resultedProducts.push(electricalCableForBasicVacuum);
                    }
                }
            },
        ],
    },
    {
        "id": 57,
        "product_code": "902.05.62",
        "name": "Kit control la distanta pentru aspiratoare \"Basic\"",
        "type": "kit",
        "subtype": "control",
        "specifications": {
            "type": "kit",
            "subtype": "control",
            "variant": "Basic"
        }
    },
    {
        "id": 58,
        "product_code": "902.06.62",
        "name": "Adaptor exhaustare aer",
        "type": "adaptor",
        "subtype": "exhaustare",
        "specifications": {
            "type": "adaptor",
            "subtype": "exhaustare",
            "variant": "aer"
        }
    },
    {
        "id": 59,
        "product_code": "904.01.62",
        "name": "Aspirator \"AP\" pentru montaj ingropat",
        "type": "aspirator",
        "subtype": "AP",
        "specifications": {
            "type": "aspirator",
            "subtype": "AP",
            "mounting": "ingropat"
        },
        "requirements": [
            {
                "type": "kit",
                "subtype": "instalare",
                "variant": "AP",
                "quantity": 1,
            },
            {
                "type": "filtru",
                "subtype": "AP",
                "quantity": 0,
            },
            {
                "type": "sac",
                "subtype": "carton",
                "variant": "AP",
                "quantity": 0,
            },
            {
                "type": "teava",
                "subtype": "flexibila",
                "usage": "bucatarie",
                "quantity": 1,
            },
            {
                "type": "colier",
                "subtype": "metalic",
                "dimensions": "50-70",
                "quantity": 2,
            },
        ],
        "recommendations": [
            {
                "type": "perie",
                "subtype": "covoare",
            },
            {
                "type": "perie",
                "subtype": "tapiterie",
            },
            {
                "type": "separator",
                "subtype": "lichide"
            },
            {
                "type": "filtru",
                "subtype": "AP"
            },
            {
                "type": "sac",
                "subtype": "carton",
                "variant": "AP"
            },
            {
                "type": "set",
                "subtype": "ecoflex",
            },
        ],
    },
    {
        "id": 60,
        "product_code": "904.02.62",
        "name": "Cutie pentru montaj ingropat",
        "type": "cutie",
        "subtype": "montaj",
        "specifications": {
            "type": "cutie",
            "subtype": "montaj",
            "mounting": "ingropat"
        }
    },
    {
        "id": 61,
        "product_code": "904.03.62",
        "name": "Kit cadru si usa de vizitare",
        "type": "kit",
        "subtype": "vizitare",
        "specifications": {
            "type": "kit",
            "subtype": "vizitare",
        }
    },
    {
        "id": 62,
        "product_code": "904.05.62",
        "name": "Aspirator \"AP\" pentru montaj aparent",
        "type": "aspirator",
        "subtype": "AP",
        "specifications": {
            "type": "aspirator",
            "subtype": "vizitare",
            "mounting": "aparent"
        },
        "requirements": [
            {
                "type": "kit",
                "subtype": "instalare",
                "variant": "AP",
                "quantity": 1,
            },
            {
                "type": "perie",
                "subtype": "covoare",
                "quantity": 0,
            },
            {
                "type": "filtru",
                "subtype": "AP",
                "quantity": 0,
            },
            {
                "type": "sac",
                "subtype": "carton",
                "variant": "AP",
                "quantity": 0,
            },
            {
                "type": "teava",
                "subtype": "flexibila",
                "usage": "bucatarie",
                "quantity": 1,
            },
            {
                "type": "colier",
                "subtype": "metalic",
                "dimensions": "50-70",
                "quantity": 2,
            },
        ],
        "recommendations": [
            {
                "type": "perie",
                "subtype": "covoare",
                "quantity": 0,
            },
            {
                "type": "perie",
                "subtype": "tapiterie",
            },
            {
                "type": "separator",
                "subtype": "lichide"
            },
            {
                "type": "filtru",
                "subtype": "AP"
            },
            {
                "type": "sac",
                "subtype": "carton",
                "variant": "AP"
            },
            {
                "type": "set",
                "subtype": "ecoflex",
            },
        ],
    },
    {
        "id": 63,
        "product_code": "904.06.62",
        "name": "Kit instalare aspirator AP",
        "type": "kit",
        "subtype": "instalare",
        "specifications": {
            "type": "kit",
            "subtype": "instalare",
            "variant": "AP"
        }
    },
    {
        "id": 64,
        "product_code": "916.01.62",
        "name": "Robot aspirare, cu statie de autocuratare",
        "type": "robot",
        "subtype": "aspirare",
        "specifications": {
            "type": "robot",
            "subtype": "aspirare",
            "variant": "autocuratare",
        }
    },
    {
        "id": 65,
        "product_code": "916.02.62",
        "name": "Robot aspirare",
        "type": "robot",
        "subtype": "aspirare",
        "specifications": {
            "type": "robot",
            "subtype": "aspirare",
            "variant": "normal",
        }
    },
    {
        "id": 66,
        "product_code": "906.03.62",
        "name": "Priza Gewiss alba",
        "type": "priza",
        "subtype": "Gewiss",
        "specifications": {
            "type": "priza",
            "subtype": "Gewiss",
            "color": "alb"
        }
    },
    {
        "id": 67,
        "product_code": "906.04.62",
        "name": "Priza Gewiss neagra",
        "type": "priza",
        "subtype": "Gewiss",
        "specifications": {
            "type": "priza",
            "subtype": "Gewiss",
            "color": "neagra"
        }
    },
    {
        "id": 68,
        "product_code": "906.05.62",
        "name": "Priza Gewiss silver",
        "type": "priza",
        "subtype": "Gewiss",
        "specifications": {
            "type": "priza",
            "subtype": "Gewiss",
            "color": "silver"
        }
    },
    {
        "id": 69,
        "product_code": "906.11.62",
        "name": "Priza Bticino alba",
        "type": "priza",
        "subtype": "Biticino",
        "specifications": {
            "type": "priza",
            "subtype": "Biticino",
            "color": "alb"
        }
    },
    {
        "id": 70,
        "product_code": "906.12.62",
        "name": "Priza Bticino neagra",
        "type": "priza",
        "subtype": "Biticino",
        "specifications": {
            "type": "priza",
            "subtype": "Biticino",
            "color": "neagra"
        }
    },
    {
        "id": 71,
        "product_code": "906.13.62",
        "name": "Priza Bticino silver",
        "type": "priza",
        "subtype": "Biticino",
        "specifications": {
            "type": "priza",
            "subtype": "Biticino",
            "color": "silver"
        }
    },
    {
        "id": 72,
        "product_code": "907.01.62",
        "name": "Suport priza Gewiss alb",
        "type": "suport",
        "subtype": "priza",
        "specifications": {
            "type": "suport",
            "subtype": "priza",
            "variant": "Gewiss",
            "color": "alb"
        }
    },
    {
        "id": 73,
        "product_code": "907.02.62",
        "name": "Suport priza Gewiss negru",
        "type": "suport",
        "subtype": "priza",
        "specifications": {
            "type": "suport",
            "subtype": "priza",
            "variant": "Gewiss",
            "color": "neagra"
        }
    },
    {
        "id": 74,
        "product_code": "907.03.62",
        "name": "Suport priza Gewiss silver",
        "type": "suport",
        "subtype": "priza",
        "specifications": {
            "type": "suport",
            "subtype": "priza",
            "variant": "Gewiss",
            "color": "silver"
        }
    },
    {
        "id": 75,
        "product_code": "907.07.62",
        "name": "Suport priza Bticino alb",
        "type": "suport",
        "subtype": "priza",
        "specifications": {
            "type": "suport",
            "subtype": "priza",
            "variant": "Biticino",
            "color": "alb"
        }
    },
    {
        "id": 76,
        "product_code": "907.08.62",
        "name": "Suport priza Bticino negru",
        "type": "suport",
        "subtype": "priza",
        "specifications": {
            "type": "suport",
            "subtype": "priza",
            "variant": "Bticino",
            "color": "neagra"
        }
    },
    {
        "id": 77,
        "product_code": "907.09.62",
        "name": "Suport priza Bticino silver",
        "type": "suport",
        "subtype": "priza",
        "specifications": {
            "type": "suport",
            "subtype": "priza",
            "variant": "Bticino",
            "color": "silver"
        }
    },
    {
        "id": 78,
        "product_code": "906.06.62",
        "name": "Priza bucatarie alba",
        "type": "priza",
        "subtype": "bucatarie",
        "specifications": {
            "type": "priza",
            "subtype": "bucatarie",
            "color": "alb"
        }
    },
    {
        "id": 79,
        "product_code": "906.07.62",
        "name": "Priza bucatarie neagra",
        "type": "priza",
        "subtype": "bucatarie",
        "specifications": {
            "type": "priza",
            "subtype": "bucatarie",
            "color": "neagra"
        }
    },
    {
        "id": 80,
        "product_code": "906.08.62",
        "name": "Priza bucatarie gri",
        "type": "priza",
        "subtype": "bucatarie",
        "specifications": {
            "type": "priza",
            "subtype": "bucatarie",
            "color": "silver"
        }
    },
    {
        "id": 81,
        "product_code": "905.08.62",
        "name": "Racord priza bucatarie",
        "type": "racord",
        "subtype": "priza",
        "specifications": {
            "type": "racord",
            "subtype": "priza",
            "variant": "bucatarie"
        }
    },
    {
        "id": 82,
        "product_code": "905.09.62",
        "name": "Prelungitor priza",
        "type": "prelungitor",
        "subtype": "priza",
        "specifications": {
            "type": "prelungitor",
            "subtype": "priza",
            "variant": "bucatarie"
        }
    },
    {
        "id": 83,
        "product_code": "909.12.62",
        "name": "Teava flexibila bucatarie",
        "type": "teava",
        "subtype": "flexibila",
        "specifications": {
            "type": "teava",
            "subtype": "flexibila",
            "usage": "bucatarie"
        },
        "conditions": [
            (context) => {
                if (context) {
                    checkColierMetalicConditions(context);
                }
            },
        ],
    },
    {
        "id": 84,
        "product_code": "909.14.66",
        "name": "Colier metalic 50-70",
        "type": "colier",
        "subtype": "metalic",
        "specifications": {
            "type": "colier",
            "subtype": "metalic",
            "dimensions": "50-70"
        },
    },
    {
        "id": 85,
        "product_code": "907.10.65",
        "name": "Ornament alb priza Gewiss - dreptunghiular",
        "type": "ornament",
        "subtype": "Gewiss",
        "specifications": {
            "type": "ornament",
            "subtype": "Gewiss",
            "variant": "dreptunghiular",
            "color": "alb",
        }
    },
    {
        "id": 86,
        "product_code": "907.11.65",
        "name": "Ornament alb priza Gewiss - rotunjit",
        "type": "ornament",
        "subtype": "Gewiss",
        "specifications": {
            "type": "ornament",
            "subtype": "Gewiss",
            "variant": "rotunjit",
            "color": "alb",
        }
    },
    {
        "id": 93,
        "product_code": "907.12.65",
        "name": "Ornament negru priza Gewiss (Geo) - dreptunghiular",
        "type": "ornament",
        "subtype": "Gewiss",
        "specifications": {
            "type": "ornament",
            "subtype": "Gewiss",
            "variant": "dreptunghiular",
            "color": "neagra",
        }
    },
    {
        "id": 94,
        "product_code": "907.13.65",
        "name": "Ornament silver priza Gewiss(Geo)",
        "type": "ornament",
        "subtype": "Gewiss",
        "specifications": {
            "type": "ornament",
            "subtype": "Gewiss",
            "variant": "normal",
            "color": "silver",
        }
    },
    {
        "id": 95,
        "product_code": "907.04.62",
        "name": "Ornament inox priza bucatarie",
        "type": "ornament",
        "subtype": "bucatarie",
        "specifications": {
            "type": "ornament",
            "subtype": "bucatarie",
            "variant": "normal",
            "color": "silver"
        }
    },
    {
        "id": 96,
        "product_code": "907.05.62",
        "name": "Ornament alb priza bucatarie",
        "type": "ornament",
        "subtype": "bucatarie",
        "specifications": {
            "type": "ornament",
            "subtype": "bucatarie",
            "variant": "normal",
            "color": "alb",
        }
    },
    {
        "id": 97,
        "product_code": "907.06.62",
        "name": "Ornament negru priza bucatarie",
        "type": "ornament",
        "subtype": "bucatarie",
        "specifications": {
            "type": "ornament",
            "subtype": "bucatarie",
            "variant": "normal",
            "color": "neagra",
        }
    },
    {
        "id": 98,
        "product_code": "915.05.62",
        "name": "Priza \"Easy\" alba",
        "type": "priza",
        "subtype": "Easy",
        "specifications": {
            "type": "priza",
            "subtype": "Easy",
            "color": "alb"
        }
    },
    {
        "id": 99,
        "product_code": "906.02.62",
        "name": "Priza \"Eco\" alba",
        "type": "priza",
        "subtype": "Eco",
        "specifications": {
            "type": "priza",
            "subtype": "ECO",
            "color": "alb"
        }
    },
    {
        "id": 100,
        "product_code": "906.09.62",
        "name": "Priza aparenta",
        "type": "priza",
        "subtype": "aparenta",
        "specifications": {
            "type": "priza",
            "subtype": "aparenta",
            "color": "alb",
        }
    },
    {
        "id": 101,
        "product_code": "906.10.62",
        "name": "Priza izolata pentru exterior",
        "type": "priza",
        "subtype": "exterior",
        "specifications": {
            "type": "priza",
            "subtype": "exterior",
            "color": "alb"
        }
    },
    {
        "id": 102,
        "product_code": "906.17.62",
        "name": "Priza pardoseala + cheie",
        "type": "priza",
        "subtype": "pardoseala",
        "specifications": {
            "type": "priza",
            "subtype": "pardoseala",
            "color": "silver"
        }
    },
    {
        "id": 103,
        "product_code": "910.01.62",
        "name": "Furtun cu maner (9m)",
        "type": "furtun",
        "subtype": "maner",
        "specifications": {
            "type": "furtun",
            "subtype": "maner",
            "variant": "normal",
            "length": 9
        }
    },
    {
        "id": 104,
        "product_code": "910.02.62",
        "name": "Furtun cu maner si intrerupator (9m)",
        "type": "furtun",
        "subtype": "maner",
        "specifications": {
            "type": "furtun",
            "subtype": "maner",
            "variant": "intrerupator",
            "length": 9
        }
    },
    {
        "id": 105,
        "product_code": "910.04.62",
        "name": "Kit Furtun Wi-Fi pentru aspirator Display",
        "type": "kit",
        "subtype": "furtun",
        "specifications": {
            "type": "kit",
            "subtype": "furtun",
            "feature": "Wi-Fi",
            "variant": "Display"
        }
    },
    {
        "id": 106,
        "product_code": "910.05.62",
        "name": "Kit Furtun Wi-Fi pentru aspirator Basic",
        "type": "kit",
        "subtype": "furtun",
        "specifications": {
            "type": "kit",
            "subtype": "furtun",
            "feature": "Wi-Fi",
            "variant": "Basic"
        }
    },
    {
        "id": 107,
        "product_code": "915.03.62",
        "name": "Set \"Easy\" furtun si accesorii (12m)",
        "type": "set",
        "subtype": "Easy",
        "specifications": {
            "type": "set",
            "subtype": "Easy",
            "variant": "furtun",
            "length": 12,
        }
    },
    {
        "id": 108,
        "product_code": "915.04.62",
        "name": "Set \"Easy\" furtun si accesorii cu activare Wi-Fi (12m)",
        "type": "set",
        "category": "Unitati",
        "specifications": {
            "type": "set",
            "features": ["furtun", "accesorii", "Wi-Fi"],
            "length": "12m",
            "model": "Easy"
        }
    },
    {
        "id": 109,
        "product_code": "911.01.62",
        "name": "Set accesorii \"Standard\"",
        "type": "set",
        "subtype": "standard",
        "specifications": {
            "type": "set",
            "subtype": "standard"
        }
    },
    {
        "id": 110,
        "product_code": "911.02.62",
        "name": "Set accesorii \"Baza\"",
        "type": "set",
        "subtype": "basic",
        "specifications": {
            "type": "set",
            "subtype": "basic",
        }
    },
    {
        "id": 111,
        "product_code": "914.10.62",
        "name": "Amortizor sunet",
        "type": "amortizor",
        "category": "Materiale",
        "specifications": {
            "type": "amortizor",
            "usage": "sunet",
        }
    },
    {
        "id": 112,
        "product_code": "911.03.62",
        "name": "Perie pentru covoare si mochete",
        "type": "perie",
        "subtype": "covoare",
        "specifications": {
            "type": "perie",
            "subtype": "covoare"
        }
    },
    {
        "id": 113,
        "product_code": "911.04.62",
        "name": "Perie pentru tapiterie",
        "type": "perie",
        "subtype": "tapiterie",
        "specifications": {
            "type": "perie",
            "subtype": "tapiterie"
        }
    },
    {
        "id": 114,
        "product_code": "913.01.62",
        "name": "Set \"Ecoflex\" pentru bucatarie",
        "type": "set",
        "subtype": "ecoflex",
        "specifications": {
            "type": "set",
            "subtype": "ecoflex",
        },
        "requirements": [
            {
                "type": "doza",
                "subtype": "bucatarie",
                "quantity": 1,
            }
        ],
    },
    {
        "id": 115,
        "product_code": "913.02.62",
        "name": "Separator lichide",
        "type": "separator",
        "subtype": "lichide",
        "specifications": {
            "type": "separator",
            "subtype": "lichide"
        }
    },
    {
        "id": 116,
        "product_code": "914.01.62",
        "name": "Micromanometru",
        "type": "micromanometru",
        "category": "Accesorii",
        "specifications": {
            "type": "micromanometru"
        }
    },
    {
        "id": 117,
        "product_code": "914.02.62",
        "name": "Filtru Basic 1",
        "type": "filtru",
        "category": "Basic 1",
        "specifications": {
            "type": "filtru",
            "subtype": "Basic 1"
        }
    },
    {
        "id": 118,
        "product_code": "914.03.62",
        "name": "Filtru Basic 2-4",
        "type": "filtru",
        "subtype": "Basic 2-4",
        "specifications": {
            "type": "filtru",
            "subtype": "Basic 2-4"
        }
    },
    {
        "id": 119,
        "product_code": "914.04.62",
        "name": "Filtru Display 2",
        "type": "filtru",
        "subtype": "Display 2",
        "specifications": {
            "type": "filtru",
            "subtype": "Display 2"
        }
    },
    {
        "id": 120,
        "product_code": "914.05.62",
        "name": "Filtru Display 3-4",
        "type": "filtru",
        "subtype": "Display 3-4",
        "specifications": {
            "type": "filtru",
            "subtype": "Display 3-4"
        }
    },
    {
        "id": 121,
        "product_code": "914.06.62",
        "name": "Filtru AP",
        "type": "filtru",
        "subtype": "AP",
        "specifications": {
            "type": "filtru",
            "subtype": "AP"
        }
    },
    {
        "id": 122,
        "product_code": "914.09.62",
        "name": "Sac carton AP (5 buc)",
        "type": "sac",
        "subtype": "carton",
        "specifications": {
            "type": "sac",
            "subtype": "carton",
            "variant": "AP"
        }
    },
    {
        "id": 123,
        "product_code": "910.03.62",
        "name": "Furtun",
        "type": "furtun",
        "category": "Tubulatura",
        "specifications": {
            "type": "furtun"
        }
    },
    {
        "id": 124,
        "product_code": "913.03.62",
        "name": "Set izolatie furtun",
        "type": "set",
        "category": "Accesorii",
        "specifications": {
            "type": "set",
            "usage": "izolatie furtun"
        }
    },
    {
        "id": 125,
        "product_code": "913.04.62",
        "name": "Activator",
        "type": "activator",
        "category": "Accesorii",
        "specifications": {
            "type": "activator"
        }
    },
    {
        "id": 126,
        "product_code": "913.05.62",
        "name": "Prelungitor",
        "type": "prelungitor",
        "category": "Accesorii",
        "specifications": {
            "type": "prelungitor"
        }
    },
    {
        "id": 127,
        "product_code": "915.08.62",
        "name": "Furtun \"Easy\" (12m), cu maner",
        "type": "furtun",
        "category": "Tubulatura",
        "specifications": {
            "type": "furtun",
            "model": "Easy",
            "length": "12m",
            "feature": "cu maner"
        }
    },
    {
        "id": 128,
        "product_code": "915.09.62",
        "name": "Maner \"Easy\"",
        "type": "maner",
        "category": "Accesorii",
        "specifications": {
            "type": "maner",
            "model": "Easy"
        }
    },
    {
        "id": 129,
        "product_code": "915.10.62",
        "name": "Maner \"Easy\" cu activare Wi-Fi (receiver inclus)",
        "type": "maner",
        "category": "Accesorii",
        "specifications": {
            "type": "maner",
            "model": "Easy",
            "feature": "cu activare Wi-Fi",
            "receiver": "inclus"
        }
    }
];