//create product objects that can be imported into other files

export const DN_VARIANTS = {
    DN75: {name: 'DN75', size: 75},
    DN100: {name: 'DN100', size: 100},
    DN125: {name: 'DN125', size: 125},
    DN127: {name: 'DN127', size: 127},
    DN150: {name: 'DN150', size: 150},
    DN160: {name: 'DN160', size: 160},
    DN200: {name: 'DN200', size: 200},
    DN250: {name: 'DN250', size: 250},
    // ... Add others as needed
};

export const PRODUCTS_COLOR_MAP = new Map(
    [
        ['Produse Etapa 1', '#eaf1dd'],
        ['Produse Etapa 2', '#fde9d9'],
        ['Produse Suplimentare', '#dbe5f1'],
        ['Produse Etapa 3', '#ffffff'],
        ['Produse Principale', '#eaf1dd'],
    ]
)


import {basicUnitSensors, premiumUnitSensors} from "@/VMC/vmcData";

import {calculateDistributorPermutations, vmcUnits} from "@/VMC/vmcData";
import store from "@/store";

let metallicDistributorPossibleCircuits = [4, 6, 10, 15];
let modularDistributorPossibleCircuits = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16];

/**
 * Checks if a unit is of a premium type based on its model ID.
 * It considers a unit as premium if its type includes "Premium" or "Enthalpic".
 *
 * @param {string} unitModelId - The model ID of the unit.
 * @returns {boolean} True if the unit is premium, false otherwise.
 */
function checkIsUnitPremium(unitModelId) {
    const unit = fetchUnitModelData(unitModelId);
    return unit ? unit.type.includes("Premium") || unit.type.includes("Enthalpic") : false;
}

/**
 * Fetches unit model data from a predefined list based on the unit model ID.
 * Assumes that vmcUnits is an accessible array containing unit data.
 *
 * @param {string} unitModelId - The model ID of the unit.
 * @returns {Object} An object containing unit data, or an empty object if not found.
 */
function fetchUnitModelData(unitModelId) {
    let unit = {};

    unit = vmcUnits.find(unit => unit.product_code === unitModelId);

    return unit;
}

/**
 * Creates a list of requirements for units based on their specific characteristics and configurations.
 * It includes various items like pipes, grates, kits, connectors, sensors, controllers, and heaters,
 * each tailored to the specific unit and model. It also considers the user's selection of tubing type
 * and diameter to determine the appropriate requirements.
 *
 * @param {Object} location - The location object containing units and their details.
 * @returns {Array} An array of unit requirements, each including type, subtype, diameter, quantity, etc.
 */
export function createUnitsRequirements(location) {
    let unitRequirements = [];

    let unitModelData;

    // 1 bucata/UNITATE
    let antiphonPipe = {
        "type": "Tub",
        "subtype": "antifonic",
        "diameter": DN_VARIANTS.DN125,
    };

    // daca aleg tub EPS de distributie si in fct de diametru . 2buc per unitate
    let terminalGrate = {
        "type": "gratar",
        "subtype": "exterior",
        "diameter": DN_VARIANTS.DN160,
        "quantity": 2
    }

    // in fct de dim si tip(se potriveste doar pe tubulatura flexibila) tubulaturii de distributie. 2 buc per unitate
    let kitWallTerminal = {
        "type": "kit",
        "subtype": "exterior",
        "diameter": DN_VARIANTS.DN125,
        "quantity": 2,
    };

    // daca selectez tubulatura de distributie flexibila adaug o rola
    let aluminumStrip = {
        "type": "banda",
        "subtype": "aluminiu",
    };

    let pvcConnector = {
        "type": "conector",
        "subtype": "PVC",
        "diameter": DN_VARIANTS.DN125,
    };

    let generalReduction = {
        "type": "reductie",
        "subtype": "general",
        "quantity": 4,
    };

    let metallicBrace = {
        "type": "colier",
        "subtype": "metalic",
        "diameter": DN_VARIANTS.DN125,
        "quantity": 8,
    };

    let epsElbow = {
        "type": "cot",
        "subtype": "EPS",
        "diameter": DN_VARIANTS.DN125,
        "variant": "slim",
        "quantity": 8,
    };

    let epdmConnector = {
        "type": "mufa",
        "subtype": "EPDM",
        "diameter": DN_VARIANTS.DN125,
        "variant": "slim",
    };

    let unitSensor = {
        "type": "sensor",
        "subtype": "",
    };

    let tsDisplay = {
        "type": "controller",
        "subtype": "display",
        "version": "TS",
    };

    let electricHeater = {
        "type": "heater",
        "subtype": "electric",
        "version": DN_VARIANTS.DN125,
    };

    let EPSTubing = {
        "type": "tubulatura",
        "subtype": 1,
        "diameter": DN_VARIANTS.DN125,
        "variant": "slim",
        "quantity": 10,
    }

    // 1 cutie de dibluri per unitate
    let beatingDowel = {
        "type": "diblu",
        "subtype": "bataie",
    };

    // Below we will add unit mounts for walls (for the moment) depending on the unit's model
    let verticalWallMount = {
        "type": "mount",
        "subtype": "wall",
        "variant": "vertical"
    };

    let horizontalWallMount = {
        "type": "mount",
        "subtype": "wall",
        "variant": "horizontal",
    };

    let AKRGasket = {
        "type": "gasket",
        "subtype": "AKR",
        "quantity": 4
    }

    let pvcReduction200 = {
        "type": "reductie",
        "subtype": "PVC",
        "variant": 200,
        "quantity": 4,
    }

    const unitsExcludedFromGeneralReduction = ['550v +', '550v ++']

    location.units.forEach((unit) => {
        let isUnitPremium = checkIsUnitPremium(unit.model_id);
        if (isUnitPremium) {
            unitRequirements.push(tsDisplay);
        }

        // Here we generate unit requirement that depend on the actual model's properties
        unitModelData = fetchUnitModelData(unit.model_id);
        if (unitModelData) {

            if (unitModelData.type.includes('Premium')) {
                unitRequirements.push(verticalWallMount)
            } else if (unitModelData.type.includes('Home')) {
                unitRequirements.push(horizontalWallMount);
            }
        }
        if (unit.tubing === 2) {
            switch (unit.tubing_diameter) {
                case 125:
                    antiphonPipe.diameter = DN_VARIANTS.DN125;
                    kitWallTerminal.diameter = DN_VARIANTS.DN125;

                    // Here we need to add 8 metallic braces for DN125 and 8 for DN150
                    metallicBrace.diameter = DN_VARIANTS.DN125;
                    unitRequirements.push(JSON.parse(JSON.stringify(metallicBrace)))
                    metallicBrace.diameter = DN_VARIANTS.DN150;
                    unitRequirements.push(JSON.parse(JSON.stringify(metallicBrace)))

                    if (!isUnitPremium) {
                        unitRequirements.push(electricHeater);
                    }

                    if (unit.model && unit.model.outletTubingDiameter > unit.tubing_diameter && (unit.model.version.includes('200f') || unit.model.version.includes('200f +'))) {
                        // if the unit version is 200f or 200f + we need to add general reductions
                        // 809.52.32 not PVC reductions and 4 PVC connectors
                        unitRequirements.push(generalReduction)

                        pvcConnector.quantity = 4;
                        unitRequirements.push(pvcConnector)

                        // here we need to add 1 EPDM connector for each general reduction
                        unitRequirements = pushBasedOnReference(generalReduction, epdmConnector, unitRequirements, epdmPushLogic);
                    }
                    break;
                case 160:
                    antiphonPipe.diameter = DN_VARIANTS.DN160;
                    kitWallTerminal.diameter = DN_VARIANTS.DN160;
                    // Here we need to add 8 metallic braces for DN150 and 8 for DN160
                    metallicBrace.diameter = DN_VARIANTS.DN160;
                    unitRequirements.push(JSON.parse(JSON.stringify(metallicBrace)))
                    metallicBrace.diameter = DN_VARIANTS.DN150;
                    unitRequirements.push(JSON.parse(JSON.stringify(metallicBrace)))

                    if (!isUnitPremium) {
                        electricHeater.version = DN_VARIANTS.DN160;
                        unitRequirements.push(electricHeater);
                    }

                    if (unit.model && unit.model.outletTubingDiameter > unit.tubing_diameter && (!unitsExcludedFromGeneralReduction.includes(unit.model.version) && !unitsExcludedFromGeneralReduction.includes(unit.model.version))) {
                        if (unit.model.version.includes('200f') || unit.model.version.includes('200f +')) {
                            // if the unit version is 200f or 200f + we need to add general reductions
                            // 809.52.32 not PVC reductions and 4 PVC connectors
                            unitRequirements.push(generalReduction)

                            pvcConnector.quantity = 4;
                            unitRequirements.push(pvcConnector)

                            // here we need to add 1 EPDM connector for each general reduction
                            unitRequirements = pushBasedOnReference(generalReduction, epdmConnector, unitRequirements, epdmPushLogic);
                        } else {
                            // else we just add pvc reductions
                            unitRequirements.push(pvcReduction200);
                        }
                    }
                    break;
            }
            unitRequirements.push(aluminumStrip);
            unitRequirements.push(antiphonPipe);
            unitRequirements.push(kitWallTerminal);
        } else if (unit.tubing === 1) {
            switch (unit.tubing_diameter) {
                case 125:
                    unitRequirements.push(epsElbow);
                    epdmConnector.diameter = DN_VARIANTS.DN125;

                    // If the selected tubing = EPS and the selected diameter = 125 we need
                    // to push a wall terminal kit since there are no grates available for that tubing size
                    unitRequirements.push(kitWallTerminal);
                    // here we need to add 3 EPDM connector for each 2 EPS elbows
                    unitRequirements = pushBasedOnReference(epsElbow, epdmConnector, unitRequirements, epdmPushLogic);
                    if (!isUnitPremium) {
                        electricHeater.version = DN_VARIANTS.DN125;
                        unitRequirements.push(electricHeater);
                    }

                    if (unit.model && unit.model.outletTubingDiameter > unit.tubing_diameter && (!unitsExcludedFromGeneralReduction.includes(unit.model.version) && !unitsExcludedFromGeneralReduction.includes(unit.model.version))) {
                        unitRequirements.push(generalReduction);

                        // here we need to add 1 EPDM connector for each general reduction
                        unitRequirements = pushBasedOnReference(generalReduction, epdmConnector, unitRequirements, epdmPushLogic);
                    }
                    unitRequirements.push(EPSTubing);
                    epdmConnector.diameter = EPSTubing.diameter;
                    // here we need to add 1 EPDM connector for each EPS tubing
                    unitRequirements = pushBasedOnReference(EPSTubing, epdmConnector, unitRequirements, epdmPushLogic);
                    break;
                case 160:
                    epsElbow.diameter = DN_VARIANTS.DN160;
                    epdmConnector.diameter = DN_VARIANTS.DN160;
                    unitRequirements.push(epsElbow);
                    unitRequirements.push(terminalGrate);

                    // here we need to add 3 EPDM connector for each 2 EPS elbows
                    unitRequirements = pushBasedOnReference(epsElbow, epdmConnector, unitRequirements, epdmPushLogic);
                    if (!isUnitPremium) {
                        electricHeater.version = DN_VARIANTS.DN160;
                        unitRequirements.push(electricHeater);
                    }

                    EPSTubing.diameter = DN_VARIANTS.DN160;
                    unitRequirements.push(EPSTubing);
                    epdmConnector.diameter = EPSTubing.diameter;
                    // here we need to add 1 EPDM connector for each EPS tubing
                    unitRequirements = pushBasedOnReference(EPSTubing, epdmConnector, unitRequirements, epdmPushLogic);

                    // here we check if the outlet diameter is smaller than the tubing diameter we need to add AKR gaskets
                    // otherwise we add general reductions
                    if (unit.model && unit.model.outletTubingDiameter < unit.tubing_diameter && (unit.model.version.includes('400A') || unit.model.version.includes('550A'))) {
                        unitRequirements.push(AKRGasket);
                    } else if (unit.model && unit.model.outletTubingDiameter > unit.tubing_diameter && (!unitsExcludedFromGeneralReduction.includes(unit.model.version) && !unitsExcludedFromGeneralReduction.includes(unit.model.version))) {
                        unitRequirements.push(generalReduction);

                        // here we need to add 1 EPDM connector for each general reduction
                        unitRequirements = pushBasedOnReference(generalReduction, epdmConnector, unitRequirements, epdmPushLogic);
                    }
                    break;
                case 200:
                    epsElbow.diameter = DN_VARIANTS.DN200;
                    terminalGrate.diameter = DN_VARIANTS.DN200;
                    epsElbow.variant = "normal";
                    unitRequirements.push(terminalGrate);
                    unitRequirements.push(epsElbow);
                    epdmConnector.diameter = DN_VARIANTS.DN200;
                    epdmConnector.variant = "normal";
                    // here we need to add 3 EPDM connector for each 2 EPS elbows
                    unitRequirements = pushBasedOnReference(epsElbow, epdmConnector, unitRequirements, epdmPushLogic);
                    if (!isUnitPremium) {
                        electricHeater.version = DN_VARIANTS.DN200;
                        unitRequirements.push(electricHeater);
                    }
                    EPSTubing.diameter = DN_VARIANTS.DN200;
                    // This has been changed to avoid slim EPS tubing for a while until stocks are depleted
                    EPSTubing.variant = "normal";
                    unitRequirements.push(EPSTubing);
                    epdmConnector.diameter = EPSTubing.diameter;
                    // here we need to add 1 EPDM connector for each EPS tubing
                    unitRequirements = pushBasedOnReference(EPSTubing, epdmConnector, unitRequirements, epdmPushLogic);
                    break;
            }
        }

        // Here we add the unit's sensors depending
        // on their type and the unit's tubing type and diameter
        // Expansion module is also being added if one or more of a premium unit's sensors are selected
        let expansionModule = {
            "type": "modul",
            "subtype": "expansiune",
        };

        // If water faucet is found in the selectedSensors of the unit, we need to also add a temperature sensor
        let tempSensor = {
            "type": "sensor",
            "subtype": "temperatura",
        };

        let conditionedUnitFilterRecommendation = {
            "isRecommendedProduct": true,
            "subtype": "standard",
            "type": "filter",
            "variant": "G4",
            "versions": [],
            "quantity": 1,
        }

        let conditionedUnitFilterProduct = {
            "subtype": "standard",
            "type": "filter",
            "variant": "G4",
            "versions": [],
            "quantity": 1,
        }

        // if no sensors have been selected the appropriate filter for the mentioned units needs to be added to the
        // recommended products
        const isVersionEligible = (version) => {
            const eligibleVersions = ['180A', '280A', '230E', '280E'];
            return eligibleVersions.includes(version);
        };

        if (unit.model && unit.model.version) {
            const filterNotSelected = !unit.selectedSensors.includes('Filtre rezervă');
            const eligibleForFilter = isVersionEligible(unit.model.version);

            if (filterNotSelected && eligibleForFilter) {
                if (!conditionedUnitFilterRecommendation.versions.includes(unit.model.version)) {
                    conditionedUnitFilterRecommendation.versions.push(unit.model.version);
                }
                unitRequirements.push(conditionedUnitFilterRecommendation);
            }
        }

        unit.selectedSensors.forEach(sensor => {
            let unitSensorItem = JSON.parse(JSON.stringify(unitSensor));
            let isExpansionModuleNeeded = getSensorByName([...basicUnitSensors, ...premiumUnitSensors], sensor)
            unitSensorItem.subtype = sensor;

            // if the unit is standard we need to remove the cooling battery command from the unit's requirements
            let coolingBatteryCommandRemove = {
                "type": "command",
                "subtype": "cooling",
                "quantity": -1,
            }

            switch (unit.tubing_diameter) {
                case 125:
                    if (sensor === 'Baterie apă') {
                        unitSensorItem.diameter = DN_VARIANTS.DN125;

                        // if the unit is standard we need to remove the cooling battery command from the unit's
                        // requirements and not push the temperature sensor
                        if (isUnitPremium) {
                            unitRequirements.push(tempSensor);
                        } else {
                            unitRequirements.push(coolingBatteryCommandRemove);
                        }
                    }
                    break;
                case 160:
                    if (sensor === 'Baterie apă') {
                        unitSensorItem.diameter = DN_VARIANTS.DN160;

                        // if the unit is standard we need to remove the cooling battery command from the unit's
                        // requirements and not push the temperature sensor
                        if (isUnitPremium) {
                            unitRequirements.push(tempSensor);
                        } else {
                            unitRequirements.push(coolingBatteryCommandRemove);
                        }
                    }
                    break;
                case 200:
                    if (sensor === 'Baterie apă') {
                        unitSensorItem.diameter = DN_VARIANTS.DN200;

                        // if the unit is standard we need to remove the cooling battery command from the unit's
                        // requirements and not push the temperature sensor
                        if (isUnitPremium) {
                            unitRequirements.push(tempSensor);
                        } else {
                            unitRequirements.push(coolingBatteryCommandRemove);
                        }
                    }
                    break;
            }

            //Do not push if sensor is Jonix purifier
            if (sensor !== "Purificator Jonix") {
                unitRequirements.push(unitSensorItem);
            }

            // Logic for adding Jonix purifier
            // If Purificator Jonix is found in the selectedSensors array we need to check the unit's totalAirVolume and
            // push the corresponding product (if < 500m3 = smaller version else = bigger version)
            let jonixPurifier = {
                "type": "sensor",
                "subtype": "Purificator Jonix",
                "volume": 500,
                "quantity": 1,
            }

            let jonixPurifierMountingBox = {
                "type": "mounting",
                "subtype": "purifier",
                "quantity": 1,
            }

            if (sensor === 'Purificator Jonix') {

                // Adding a mounting box for the Jonix Purifier is necessary when the first (good air) distributor of
                // a unit is metallic, and it's size is 4 or when the distributor is modular regardless of it's size.
                if (unit.distributors[0] && unit.distributors[0].type === 1 && unit.distributors[0].size === 4 || unit.distributors[0] && unit.distributors[0].type === 2) {
                    unitRequirements.push(jonixPurifierMountingBox);
                }

                if (unit.totalAirVolume < 500) {
                    unitRequirements.push(JSON.parse(JSON.stringify(jonixPurifier)));
                } else if (unit.totalAirVolume > 500) {
                    jonixPurifier.volume = 1000;
                    unitRequirements.push(JSON.stringify(jonixPurifier));
                }
            }

            let unitFilter = {
                "type": "filter",
                "subtype": "standard",
                "versions": [unit.model.version],
                "quantity": 1,
            };

            let boxForF7Filters = {
                "subtype": "filter",
                "type": "box",
                "variant": "F7",
                "quantity": 1
            };

            if (sensor === 'Filtre rezervă') {
                if (isUnitPremium && !unit.model.type.includes('Home')) {
                    unitFilter.subtype = "premium";
                    unitFilter.variant = "M5";

                    unitRequirements.push(unitFilter);
                } else if (isUnitPremium && unit.model.type.includes('Home')) {
                    unitFilter.variant = "M5";
                    unitFilter.subtype = "home";

                    unitRequirements.push(unitFilter);
                } else if (unit.model && unit.model.version.includes('400A') || unit.model.version.includes('590E') || unit.model.version.includes('550A')) {
                    // here we handle the special case of 400A and 590E unit versions that only have the F7 filter
                    // compatible and we need to add it without it's mounting box
                    conditionedUnitFilterProduct.versions = [unit.model.version];
                    conditionedUnitFilterProduct.variant = "F7";

                    let removeBoxForF7Filter = {
                        "type": "box",
                        "subtype": "filter",
                        "variant": "F7",
                        "quantity": -1,
                    }

                    unitRequirements.push(JSON.parse(JSON.stringify(conditionedUnitFilterProduct)));
                    conditionedUnitFilterProduct.variant = "G4";
                    unitRequirements.push(JSON.parse(JSON.stringify(conditionedUnitFilterProduct)));
                    unitRequirements.push(removeBoxForF7Filter);
                }
            } else if (sensor === 'Filtre rezervă G4' || sensor === 'Filtre rezervă F7') {
                // here we check the conditions for the F7 or G4 filter depending on the user's choice and on the unit
                // he has selected

                if (sensor === 'Filtre rezervă G4') {
                    unitFilter.subtype = "standard";
                    unitFilter.variant = "G4";
                    unitRequirements.push(unitFilter);

                    if (conditionedUnitFilterRecommendation.versions.includes(unit.model.version)) {
                        conditionedUnitFilterRecommendation.variant = "F7";
                        conditionedUnitFilterRecommendation.versions.push(unit.model.version);

                        unitRequirements.push(conditionedUnitFilterRecommendation);
                        // unitRequirements.push(boxForF7Filters);
                    }
                }
                if (sensor === 'Filtre rezervă F7') {
                    conditionedUnitFilterProduct.versions = [unit.model.version];
                    conditionedUnitFilterProduct.variant = "F7";

                    unitRequirements.push(conditionedUnitFilterProduct);
                    unitRequirements.push(boxForF7Filters);
                }
            }
            if (sensor === 'Dispenser parfum') {
                // If the perfume dispenser is added as a sensor then we need to add a perfume by default
                let defaultPerfume = {
                    "type": "perfume",
                    "subtype": "boreal",
                    "variant": 120,
                    "quantity": 1,
                }

                unitRequirements.push(defaultPerfume);

            }

            //If a sensor has the expansion property true we push an expansion module in the unit requirements
            if (isExpansionModuleNeeded && !unitRequirements.includes(expansionModule)) {
                unitRequirements.push(expansionModule);
            }

        });

        if (unit.model && unit.model.version) {
            // O cutie de dibluri per unitate (200buc in cutie)
            unitRequirements.push(beatingDowel);
        }
    });

    let unitMounts = addUnitMountingProduct(location);
    if (unitMounts && unitMounts.length > 0) {
        unitRequirements.push(...unitMounts);
    }
    return unitRequirements;
}

// this method adds the needed unit mounts depending on the unit's type and it's selected mounting
function addUnitMountingProduct(location) {
    let resultedUnitMounts = [];

    location.units.forEach(unit => {
        let unitMount = {
            "type": "mount",
            "subtype": "wall",
            "variant": "premium"
        };

        if (unit.model) {
            switch (unit.selected_mounting) {
                case 2: // PERETE
                    if (unit.model.type === 'Premium V' || unit.model.type === 'Enthalpic V' || unit.model.type === 'Premium V ++') {
                        unitMount.variant = "Premium v";
                        resultedUnitMounts.push(unitMount);
                    } else if (unit.model.type === 'Premium H' || unit.model.type === 'Premium H ++' || unit.model.type === 'Enthalpic H') {
                        unitMount.variant = "Premium v";
                        resultedUnitMounts.push(unitMount);
                    } else if (unit.model.type === 'Premium Home' || unit.model.type === 'Premium Home +') {
                        unitMount.variant = "horizontal";
                        resultedUnitMounts.push(unitMount);
                    }
                    break;
                case 1: // TAVAN
                    if (unit.model.type === 'Premium H' || unit.model.type === 'Premium H ++' || unit.model.type === 'Enthalpic H') {
                        unitMount.subtype = 'wall';
                        unitMount.variant = "Premium v";
                        resultedUnitMounts.push(unitMount);
                    } else if (unit.model.type === 'Premium Home' || unit.model.type === 'Premium Home +') {
                        unitMount.subtype = "ceiling";
                        unitMount.variant = "horizontal";
                    }
                    break;
            }
        }

    });

    return resultedUnitMounts;
}


/**
 * This function checks if a reference object matches any of the objects in an array.
 * If a match is found, it adds a specified object to the array, the number of times being determined by the `pushLogic` function.
 * The `pushLogic` function defines specific logic for determining the number of objects to be added, based on attributes of the `referenceObj`.
 * The `quantity` property is ignored during the comparison.
 *
 * @param {Object} referenceObj - The reference object used to check for a match.
 * @param {Object} objToPush - The object to be added to the array.
 * @param {Array} arr - The array into which the object should be added.
 * @param {Function} pushLogic - The function that determines the number of times the object is added to the array, based on the `referenceObj`.
 * @returns {Array} - Returns the modified array.
 */

function pushBasedOnReference(referenceObj, objToPush, arr, pushLogic) {
    if (matchesReference(referenceObj, arr)) {
        // Determine how many times to push based on the logic provided
        let timesToPush = pushLogic(referenceObj);

        for (let i = 0; i < timesToPush; i++) {
            let newObj = JSON.parse(JSON.stringify(objToPush));
            arr.push(newObj);
        }
    }
    return arr;
}

// this method is used to define how many epdm connectors we need basedon the referenceObj's type
function epdmPushLogic(referenceObj) {
    switch (referenceObj.type) {
        case 'tubulatura':
            return 1 * referenceObj.quantity; // 1 connector for each tubing
        case 'reductie':
            return 1 * referenceObj.quantity; // 1 connector for each reduction
        case 'teu':
            return 3 * referenceObj.quantity; // 3 connectors for each tee
        case 'cot':
            return Math.ceil(referenceObj.quantity / 2) * 3; // 3 connectors for every 2 elbows
        default:
            return 0;
    }
}

// This method looks for an item in an array of items based on the item's name and if an item is found it returns
// that item's expansion property value
function getSensorByName(items, sensorName) {
    // Find the element in the array whose name matches the item's name
    const foundItem = items.find(element => element.name === sensorName);

    // If the item is found, return its expansion property; otherwise, return null
    return foundItem ? foundItem.expansion : null;
}

/**
 * Verifică dacă un obiect de referință corespunde cu oricare dintre obiectele dintr-un array.
 * Proprietatea `quantity` este ignorată în timpul comparației.
 *
 * @param {Object} referenceObj - Obiectul de referință folosit pentru a verifica egalitatea.
 * @param {Array} arr - Array-ul în care se verifică egalitatea.
 * @returns {Boolean} - Returnează true dacă găsește o potrivire, altfel false.
 */
function matchesReference(referenceObj, arr) {
    return arr.some(item => {
        for (let key in referenceObj) {
            if (key === 'quantity') continue;
            if (referenceObj[key] !== item[key]) {
                return false;
            }
        }
        return true;
    });
}

export function createPlenumsRequirements(location) {
    let plenumRequirements = [];
    location.rooms.forEach((room) => {
        room.plenums.forEach((plenum) => {
            let modularGrate = {
                "type": "grate",
                "subtype": "modular",
                "quantity": 1,
            };
            let plenumData = {
                type: 'Plenum',
                subtype: plenum.type,
                size: plenum.size,
            };

            // If the plenum is dublu tavan we need to set the size to 1 in order to match the product's
            // specifications as there is only one product for both sizes (1 and 2)
            if (plenum.type === 6 || plenum.type === 7) {
                plenumData.size = 1;
            }

            if (plenum.type === 6 || plenum.type === 7 || plenum.type === 1) {
                if (plenum.grate === "Difuzor rotund") {
                    modularGrate.subtype = "diffuser";
                    modularGrate.variant = "round";
                } else if (plenum.grate === "Difuzor patrăt") {
                    modularGrate.subtype = "diffuser";
                    modularGrate.variant = "square";
                } else if (plenum.grate === "Design") {
                    modularGrate.subtype = "design";
                    modularGrate.version = DN_VARIANTS.DN100;
                }
                plenumRequirements.push(modularGrate);
            }

            plenumRequirements.push(plenumData);
        });
    });

    return plenumRequirements;
}

export function calculateNeededTubesForCircuits(location) {
    let locationTotalCircuitLength = 0;
    let totalCircuits = 0;
    location.rooms.forEach((room) => {
        totalCircuits += room.circuit_no_VMC;
        room.plenums.forEach((plenum) => {
            let plenumTotalCircuitLength = plenum.circuit_length * plenum.size;
            locationTotalCircuitLength += plenumTotalCircuitLength;
        });
    });

    // suma lungime circuite (prioritate colac de  50 m si colac de 25 de
    // metri daca restul impartirii la 50 este mai micsau egal cu  25)
    // Calcul pentru totalul circuitelor si numarul de tubulaturi flexibile de 25 si 50 metri necesare

    let quotient = Math.ceil(locationTotalCircuitLength / 50); // Using Math.floor to get the integer quotient

    return {
        pipe: {
            "long": quotient,
        },
        totalCircuits: totalCircuits
    };
}

export function createCircuitTubesRequirements(location) {
    let circuitsResults = calculateNeededTubesForCircuits(location);
    let circuitTubesRequirements = [];
    let pipeRequirementLong = {
        "type": "tubulatura",
        "diameter": "DN75",
    };

    let circuitRequirement = {
        "type": "dop",
        "subtype": "DN75",
        "quantity": 1,
    };

    let plugSurplus = {
        "type": "dop",
        "subtype": "DN75",
        "quantity": -1,
    };

    let perforatedBandRequirement = {
        "type": "banda",
        "subtype": "perforata",
    };

    let oRingKit = {
        "type": "kit",
        "subtype": "o-ring",
        // Initially set quantity to 1, but this will be updated below
        "quantity": 1,
    }

    // here we add 2 plug surplus for each pipe knowing that there are 3 plugs for each -1 quantity
    for (let i = 0; i < Math.ceil(circuitsResults.pipe.long * 2 / 3); i++) {
        circuitTubesRequirements.push({...plugSurplus}); // Clone the object to avoid mutation
    }

    for (let i = 0; i < circuitsResults.pipe.long; i++) {
        circuitTubesRequirements.push({...pipeRequirementLong, length: 50}); // Clone and update length
        circuitTubesRequirements.push({...perforatedBandRequirement}); // Clone the object to avoid mutation
    }

    // Add 2 plugs per circuit keeping in mind that every plug product has 3 items inside of it,
    // so we need to divide it by 3
    for (let i = 0; i < Math.ceil(circuitsResults.totalCircuits * 2 / 3); i++) {
        circuitTubesRequirements.push({...circuitRequirement}); // Clone the object to avoid mutation
    }

    // Calculate the total number of oRingKits needed for the circuits
    // Each circuit needs 4 o-rings, and each kit contains 10 o-rings
    let totalORingsNeeded = circuitsResults.totalCircuits * 4;
    let oRingKitsNeeded = Math.ceil(totalORingsNeeded / 10); // Round up to ensure we have enough o-rings

    // Update the oRingKit quantity and add to the requirements
    oRingKit = {...oRingKit, quantity: oRingKitsNeeded}; // Update quantity
    circuitTubesRequirements.push(oRingKit);

    return circuitTubesRequirements;
}

// This method is used for calculating the necessary distributors and their sizes depending on the distributor's type
// and the unit's selected tubing diameter
export function calculateNeededDistributors(location) {
    location.units.forEach((unit) => {
        unit.distributors.forEach((distributor) => {
            if (distributor.type === 1) {
                if (unit.tubing_diameter === 125) {
                    metallicDistributorPossibleCircuits = [4, 6, 10];
                } else if (unit.tubing_diameter === 160) {
                    metallicDistributorPossibleCircuits = [10, 15];
                } else {
                    store.commit('pushVmcError', {
                        type: 'error',
                        text: 'Pentru selectia curenta de tubulatura nu exista distribuitoare metalice compatibile, va rugam ajustati manual'
                    })
                    distributor.size = 0;
                    return;
                }
                distributor.size = calculateDistributorPermutations(distributor.circuitNo, metallicDistributorPossibleCircuits).distributors[0];
                if (distributor.size - distributor.circuitNo >= 3) {
                    store.commit('pushVmcError', {
                        type: 'warning',
                        text: 'Pentru selectia curenta de tubulatura, selectia automata a distribuitoarelor poate fi gresita, va rugam verificati!',
                    })
                }
            } else {
                distributor.size = calculateDistributorPermutations(distributor.circuitNo, modularDistributorPossibleCircuits).distributors[0];
            }
        });
    });
}

export function createDistributorsRequirements(location) {
    calculateNeededDistributors(location);

    let epdmConnector = {
        "type": "mufa",
        "subtype": "EPDM",
        "diameter": DN_VARIANTS.DN125,
    };

    let distributorNipple = {
        "type": "niplu",
        "subtype": "modular",
        "diameter": DN_VARIANTS.DN125,
    };

    // It is required to add a tee when there are more than 2 distributors assigned on the same unit
    let epsTee = {
        "type": "teu",
        "subtype": "EPS",
        "diameter": DN_VARIANTS.DN125,
        "variant": "slim",
        "quantity": 1,
    };

    // It is required to add a tee when there are more than 2 distributors assigned on the same unit
    let flexTee = {
        "type": "teu",
        "subtype": "flex",
        "quantity": 1,
    }

    let distributorsRequirements = [];

    location.units.forEach((unit) => {
        unit.distributors.forEach((distributor) => {
            let distributorRequirement = {
                size: distributor.size,
                type: distributor.type,
            }
            if (unit.tubing === 1) {
                switch (unit.tubing_diameter) {
                    case 125:
                        distributorRequirement.diameter = DN_VARIANTS.DN125;
                        // If the distributor is modular, we set the additional property
                        // to be without a nipple to avoid doubling the distributors (there is a modular
                        // distributor of 8 circuits without a nipple, respectively with 3 connections without a nipple).
                        if (distributor.type === 2) {
                            distributorRequirement.additional = "fara niplu";
                            distributorNipple.diameter = DN_VARIANTS.DN125;
                            distributorsRequirements.push(distributorNipple);
                        }

                        // If there are more than 2 distributors assigned to the same unit we need to modify the
                        // tee's quantity to be equal to the difference between the total number of distributors and the
                        // minimal number of distributors (2)
                        if (unit.distributors.length > 2) {
                            epsTee.diameter = DN_VARIANTS.DN125;
                        }
                        break;
                    case 160:
                        distributorRequirement.diameter = DN_VARIANTS.DN160;
                        // If the distributor is modular, we set the additional property
                        // to be without a nipple to avoid doubling the distributors (there is a modular
                        // distributor of 8 circuits without a nipple, respectively with 3 connections without a nipple).
                        if (distributor.type === 2) {
                            distributorRequirement.additional = "fara niplu";
                            distributorNipple.diameter = DN_VARIANTS.DN160;
                            distributorsRequirements.push(distributorNipple);
                        }

                        // If there are more than 2 distributors assigned to the same unit we need to modify the
                        // tee's quantity to be equal to the difference between the total number of distributors and the
                        // minimal number of distributors (2)
                        if (unit.distributors.length > 2) {
                            epsTee.diameter = DN_VARIANTS.DN160;
                        }
                        break;
                    case 200:
                        distributorRequirement.diameter = DN_VARIANTS.DN200;
                        if (distributor.type === 2) {
                            distributorRequirement.additional = "fara niplu";
                            distributorNipple.diameter = DN_VARIANTS.DN200;
                            distributorsRequirements.push(distributorNipple);
                        }

                        // If there are more than 2 distributors assigned to the same unit we need to modify the
                        // tee's quantity to be equal to the difference between the total number of distributors and the
                        // minimal number of distributors (2)
                        if (unit.distributors.length > 2) {
                            epsTee.variant = "normal";
                            epsTee.diameter = DN_VARIANTS.DN200;
                        }
                        break;
                }
            } else if (unit.tubing === 2) {
                switch (unit.tubing_diameter) {
                    case 125:
                        distributorRequirement.diameter = DN_VARIANTS.DN125;
                        // If the distributor is modular and the unit's tubing is flexible, we set the additional property
                        // to be without a nipple to avoid doubling the distributors (there is a modular
                        // distributor of 8 circuits without a nipple, respectively with 3 connections without a nipple).
                        if (distributor.type === 2) {
                            distributorRequirement.additional = "fara niplu";
                            distributorNipple.diameter = DN_VARIANTS.DN125;
                            distributorsRequirements.push(distributorNipple);
                        }

                        // If there are more than 2 distributors assigned to the same unit we need to modify the
                        // tee's quantity to be equal to the difference between the total number of distributors and the
                        // minimal number of distributors (2)
                        if (unit.distributors.length > 2) {
                            epsTee.diameter = DN_VARIANTS.DN125;
                        }
                        break;
                    case 160:
                        distributorRequirement.diameter = DN_VARIANTS.DN160;
                        // If the distributor is modular and the unit's tubing is flexible, we set the additional property
                        // to be without a nipple to avoid doubling the distributors (there is a modular
                        // distributor of 8 circuits without a nipple, respectively with 3 connections without a nipple).
                        if (distributor.type === 2) {
                            distributorRequirement.additional = "fara niplu";
                            distributorNipple.diameter = DN_VARIANTS.DN160;
                            distributorsRequirements.push(distributorNipple);
                        }

                        // If there are more than 2 distributors assigned to the same unit we need to modify the
                        // tee's quantity to be equal to the difference between the total number of distributors and the
                        // minimal number of distributors (2)
                        if (unit.distributors.length > 2) {
                            epsTee.diameter = DN_VARIANTS.DN160;
                        }
                        break;
                }
            }

            if (distributor.type === 2 && unit.tubing) {
                if (distributor.size < 4) {
                    let circuitRequirement = {
                        "type": "dop",
                        "subtype": "DN75",
                        "quantity": 1,
                    };

                    // If the distributor is modular and it's size is smaller than 4 we should add a number of
                    // general reductions that is equal to the difference between the distributor's size and the number 4
                    circuitRequirement.quantity = Math.ceil((4 - distributor.size) / 3); // Calculate the required quantity
                    // generalReduction.quantity = 4 - distributor.size; // Calculate the required quantity

                    // If the distributor is modular and it's size is smaller than 4 we should still add
                    // a modular distributor with 4 as its size
                    distributorRequirement.size = 4;

                    distributorsRequirements.push(circuitRequirement);

                }
                // If distributor's requirement.type === 2 (modular) then we remove the diameter property
                delete distributorRequirement.diameter
            }

            distributorsRequirements.push(distributorRequirement);
        });

        // If there are more than 2 distributors assigned to the same unit and the tubing is not Flexible
        // we need to modify the tee's quantity to be equal to the difference between the total number of
        // distributors and the minimal number of distributors (2)
        if (unit.distributors.length > 2) {
            let tee; // To hold the specific tee based on tubing type
            if (unit.tubing === 1) {
                epsTee.quantity = unit.distributors.length - 2;
                tee = epsTee;
                // Specific logic for epsTee
                epdmConnector.diameter = tee.diameter; // Assuming epsTee and epdmConnector share a 'diameter' property
                distributorsRequirements = pushBasedOnReference(tee, epdmConnector, distributorsRequirements, epdmPushLogic);
            } else if (unit.tubing === 2) {
                flexTee.quantity = unit.distributors.length - 2;
                tee = flexTee;
            }

            // For both conditions, push the tee to distributorsRequirements if it's defined
            if (tee) {
                distributorsRequirements.push(tee);
            }
        }
    });
    return distributorsRequirements;
}