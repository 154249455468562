import {basicUnitSensors, premiumUnitSensors} from "@/VMC/vmcData";
import {DN_VARIANTS} from "@/VMC/vmcLogic";

export const products = [
    {
        "id": 1,
        "product_code": "806.01.50",
        "name": "Distribuitor",
        "type": "distribuior",
        "specifications": {
            "type": 1,
            "diameter": DN_VARIANTS.DN125,
            "outlets": "4xDN75",
            "size": 4,
        },
        "requirements": []
    },
    {
        "id": 2,
        "product_code": "806.02.50",
        "name": "Distribuitor",
        "type": "distributor",
        "specifications": {
            "type": 1,
            "diameter": DN_VARIANTS.DN125,
            "outlets": "6xDN75",
            "size": 6,
        },
        "requirements": []
    },
    {
        "id": 3,
        "product_code": "806.03.50",
        "name": "Distribuitor",
        "type": "distributor",
        "specifications": {
            "type": 1,
            "diameter": DN_VARIANTS.DN125,
            "outlets": "10xDN75",
            "size": 10,
        },
        "requirements": []
    },
    {
        "id": 4,
        "product_code": "806.04.50",
        "name": "Distribuitor",
        "type": "distributor",
        "specifications": {
            "type": 1,
            "diameter": DN_VARIANTS.DN160,
            "outlets": "10xDN75",
            "size": 10,
        },
        "requirements": []
    },
    {
        "id": 5,
        "product_code": "806.05.50",
        "name": "Distribuitor",
        "type": "distributor",
        "specifications": {
            "type": 1,
            "diameter": DN_VARIANTS.DN160,
            "outlets": "15xDN75",
            "size": 15,
        },
        "requirements": []
    },
    {
        "id": 6,
        "product_code": "806.06.91",
        "name": "Distribuitor modular",
        "type": "distributor",
        "specifications": {
            "outlets": "4xDN75",
            "type": 2,
            "additional": "fara niplu",
            "size": 4,
        },
        "requirements": []
    },
    {
        "id": 7,
        "product_code": "806.07.91",
        "name": "Distribuitor modular",
        "type": "distributor",
        "specifications": {
            "outlets": "5xDN75",
            "type": 2,
            "additional": "fara niplu",
            "size": 5,
        },
        "requirements": []
    },
    {
        "id": 8,
        "product_code": "806.08.91",
        "name": "Distribuitor modular",
        "type": "distributor",
        "specifications": {
            "outlets": "6xDN75",
            "type": 2,
            "additional": "fara niplu",
            "size": 6,
        },
        "requirements": []
    },
    {
        "id": 9,
        "product_code": "806.09.91",
        "name": "Distribuitor modular",
        "type": "distributor",
        "specifications": {
            "outlets": "7xDN75",
            "type": 2,
            "additional": "fara niplu",
            "size": 7,
        },
        "requirements": []
    },
    {
        "id": 10,
        "product_code": "806.10.91",
        "name": "Distribuitor modular",
        "type": "distributor",
        "specifications": {
            "outlets": "8xDN75",
            "type": 2,
            "additional": "fara niplu",
            "size": 8,
        },
        "requirements": []
    },

    {
        "id": 11,
        "product_code": "806.11.91",
        "name": "Distribuitor modular",
        "type": "distributor",
        "specifications": {
            "outlets": "8xDN75",
            "type": 2,
            "additional": "racordare 3 laturi (fara niplu)",
            "size": 8,
        },
        "requirements": []
    },
    {
        "id": 12,
        "product_code": "806.12.91",
        "name": "Distribuitor modular",
        "type": "distributor",
        "specifications": {
            "outlets": {
                "variantKey": 2,
                "count": 9
            },
            "type": 2,
            "additional": "fara niplu",
            "size": 9,
        },
        "requirements": []
    },
    {
        "id": 13,
        "product_code": "806.13.91",
        "name": "Distribuitor modular",
        "type": "distributor",
        "specifications": {
            "outlets": {
                "variantKey": 2,
                "count": 10
            },
            "type": 2,
            "additional": "fara niplu",
            "size": 10,
        },
        "requirements": []
    },
    {
        "id": 14,
        "product_code": "806.14.91",
        "name": "Distribuitor modular",
        "type": "distributor",
        "specifications": {
            "outlets": {
                "variantKey": 2,
                "count": 11
            },
            "type": 2,
            "additional": "fara niplu",
            "size": 11,
        },
        "requirements": []
    },
    {
        "id": 15,
        "product_code": "806.15.91",
        "name": "Distribuitor modular",
        "type": "distributor",
        "specifications": {
            "outlets": {
                "variantKey": 2,
                "count": 12
            },
            "type": 2,
            "additional": "fara niplu",
            "size": 12,
        },
        "requirements": []
    },
    {
        "id": 16,
        "product_code": "806.16.91",
        "name": "Distribuitor modular",
        "type": "distributor",
        "specifications": {
            "outlets": {
                "variantKey": 2,
                "count": 13
            },
            "type": 2,
            "additional": "fara niplu",
            "size": 13,
        },
        "requirements": []
    },
    {
        "id": 17,
        "product_code": "806.17.91",
        "name": "Distribuitor modular",
        "type": "distributor",
        "specifications": {
            "outlets": {
                "variantKey": 2,
                "count": 14
            },
            "type": 2,
            "additional": "fara niplu",
            "size": 14,
        },
        "requirements": []
    },
    {
        "id": 18,
        "product_code": "806.18.91",
        "name": "Distribuitor modular",
        "type": "distributor",
        "specifications": {
            "outlets": {
                "variantKey": 2,
                "count": 15
            },
            "type": 2,
            "additional": "fara niplu",
            "size": 15,
        },
        "requirements": []
    },
    {
        "id": 19,
        "product_code": "806.19.91",
        "name": "Distribuitor modular",
        "type": "distributor",
        "specifications": {
            "outlets": {
                "variantKey": 2,
                "count": 16
            },
            "type": 2,
            "additional": "fara niplu",
            "size": 16,
        },
        "requirements": []
    },
    {
        "id": 20,
        "product_code": "813.08.91",
        "name": "Niplu distribuitor modular",
        "type": "niplu",
        "subtype": "modular",
        "specifications": {
            "variantKey": 1,
            "type": "niplu",
            "subtype": "modular",
            "diameter": DN_VARIANTS.DN125,
        }
    },
    {
        "id": 21,
        "product_code": "813.10.91",
        "name": "Niplu distribuitor modular DN160",
        "type": "niplu",
        "category": "Distribuitor",
        "subtype": "modular",
        "specifications": {
            "type": "niplu",
            "subtype": "modular",
            "diameter": DN_VARIANTS.DN160,
            "variantKey": 4
        }
    },
    {
        "id": 22,
        "product_code": "813.11.91",
        "name": "Niplu distribuitor modular DN200",
        "type": "niplu",
        "category": "Distribuitor",
        "subtype": "modular",
        "specifications": {
            "type": "niplu",
            "subtype": "modular",
            "diameter": DN_VARIANTS.DN200,
            "variantKey": 5
        }
    },
    {
        "id": 23,
        "product_code": "807.01.50",
        "name": "Plenum racordare laterala - 1xDN75 (200x100)",
        "category": "Plenum",
        "type": "Plenum",
        "specifications": {
            "type": "Plenum",
            "subtype": 3,
            "variantKey": 2,
            "dimensions": "200x100",
            "size": 1,
        },
        "requirements": [
            {
                "type": "grate",
                "subtype": "perforated",
                "version": 200,
                "quantity": 1
            },
            {
                "type": "damper",
                "subtype": "air",
                "quantity": 1
            }
        ],
    },
    {
        "id": 24,
        "product_code": "807.02.50",
        "name": "Plenum racordare laterala - 2xDN75 (300x100)",
        "category": "Plenum",
        "type": "Plenum",
        "specifications": {
            "type": "Plenum",
            "subtype": 3,
            "variantKey": 2,
            "dimensions": "300x100",
            "size": 2,
        },
        "requirements": [
            {
                "type": "grate",
                "subtype": "perforated",
                "version": 300,
                "quantity": 1
            },
            {
                "type": "damper",
                "subtype": "air",
                "quantity": 2
            }
        ],
    },
    {
        "id": 25,
        "product_code": "807.03.50",
        "name": "Plenum racordare laterala - 3xDN75 (300x100)",
        "category": "Plenum",
        "type": "Plenum",
        "specifications": {
            "type": "Plenum",
            "subtype": 3,
            "variantKey": 2,
            "dimensions": "300x100",
            "size": 3,
        },
        "requirements": [
            {
                "type": "grate",
                "subtype": "perforated",
                "version": 300,
                "quantity": 1
            },
            {
                "type": "damper",
                "subtype": "air",
                "quantity": 3
            }
        ],
    },
    {
        "id": 26,
        "product_code": "807.04.50",
        "name": "Plenum racordare spate - 1xDN75 (200x100)",
        "type": "Plenum",
        "category": "Plenum",
        "specifications": {
            "type": "Plenum",
            "subtype": 2,
            "variantKey": 2,
            "dimensions": "200x100",
            "size": 1,
        },
        "requirements": [
            {
                "type": "grate",
                "subtype": "perforated",
                "version": 200,
                "quantity": 1
            },
            {
                "type": "damper",
                "subtype": "air",
                "quantity": 1
            }
        ],
    },
    {
        "id": 27,
        "product_code": "807.05.50",
        "name": "Plenum racordare spate - 2xDN75 (300x100)",
        "type": "Plenum",
        "category": "Plenum",
        "specifications": {
            "type": "Plenum",
            "subtype": 2,
            "variantKey": 2,
            "dimensions": "300x100",
            "size": 2,
        },
        "requirements": [
            {
                "type": "grate",
                "subtype": "perforated",
                "version": 300,
                "quantity": 1
            },
            {
                "type": "damper",
                "subtype": "air",
                "quantity": 2
            }
        ],
    },
    {
        "id": 28,
        "product_code": "807.06.50",
        "name": "Plenum racordare spate - 3xDN75 (300x100)",
        "type": "Plenum",
        "category": "Plenum",
        "specifications": {
            "type": "Plenum",
            "subtype": 2,
            "variantKey": 2,
            "dimensions": "300x100",
            "size": 3,
        },
        "requirements": [
            {
                "type": "grate",
                "subtype": "perforated",
                "version": 300,
                "quantity": 1
            },
            {
                "type": "damper",
                "subtype": "air",
                "quantity": 3
            }
        ],
    },
    {
        "id": 29,
        "product_code": "807.07.50",
        "name": "Plenum racordare laterala \"Design\" - 1xDN75 (140x140)",
        "type": "Plenum",
        "category": "Plenum",
        "specifications": {
            "type": "Plenum",
            "subtype": 5,
            "variantKey": 2,
            "dimensions": "140x140",
            "size": 1,
        },
        "requirements": [
            {
                "type": "grate",
                "subtype": "design",
                "version": DN_VARIANTS.DN100,
            }
        ],
    },
    {
        "id": 30,
        "product_code": "807.08.50",
        "name": "Plenum racordare laterala \"Design\" - 2xDN75 (200x200)",
        "type": "Plenum",
        "category": "Plenum",
        "specifications": {
            "type": "Plenum",
            "subtype": 5,
            "variantKey": 2,
            "dimensions": "200x200",
            "size": 2,
        },
        "requirements": [
            {
                "type": "grate",
                "subtype": "design",
                "version": DN_VARIANTS.DN150,
            }
        ],
    },
    {
        "id": 31,
        "product_code": "807.09.50",
        "name": "Plenum racordare spate \"Design\" - 1xDN75 (140x140)",
        "type": "Plenum",
        "category": "Plenum",
        "specifications": {
            "type": "Plenum",
            "subtype": 4,
            "variantKey": 2,
            "dimensions": "140x140",
            "size": 1,
        },
        "requirements": [
            {
                "type": "grate",
                "subtype": "design",
                "version": DN_VARIANTS.DN100,
            }
        ],
    },
    {
        "id": 32,
        "product_code": "807.10.50",
        "name": "Plenum racordare spate \"Design\" - 2xDN75 (200x200)",
        "type": "Plenum",
        "category": "Plenum",
        "specifications": {
            "type": "Plenum",
            "subtype": 4,
            "variantKey": 2,
            "dimensions": "200x200",
            "size": 2,
        },
        "requirements": [
            {
                "type": "grate",
                "subtype": "design",
                "version": DN_VARIANTS.DN150,
            }
        ],
    },
    {
        "id": 33,
        "product_code": "807.11.50",
        "name": "Plenum dublu tavan - 2xDN75-DN125",
        "type": "Plenum",
        "category": "Plenum",
        "specifications": {
            "type": "Plenum",
            "subtype": 7,
            "variantKeys": [2, 1],
            "size": 1,
        },
        "requirements": [
        ],
    },
    {
        "id": 34,
        "product_code": "807.12.50",
        "name": "Damper reglare volum aer",
        "type": "Damper",
        "category": "Reglare",
        "subtype": "aer",
        "specifications": {
            "type": "damper",
            "subtype": "air",
        },
    },
    {
        "id": 35,
        "product_code": "807.13.91",
        "name": "Plenum modular - 1xDN75-DN125",
        "type": "Plenum",
        "category": "Plenum",
        "specifications": {
            "type": "Plenum",
            "subtype": 1,
            "variantKeys": [2, 1],
            "size": 1,
        },
        "requirements": [
        ],
    },
    {
        "id": 36,
        "product_code": "807.14.91",
        "name": "Plenum modular - 2xDN75-DN125",
        "type": "Plenum",
        "category": "Plenum",
        "specifications": {
            "type": "Plenum",
            "subtype": 1,
            "variantKeys": [2, 1],
            "size": 2,
        },
        "requirements": [
        ],
    },
    {
        "id": 37,
        "product_code": "807.15.91",
        "name": "Plenum modular - 3xDN75-DN125",
        "type": "Plenum",
        "category": "Plenum",
        "specifications": {
            "type": "Plenum",
            "subtype": 1,
            "variantKeys": [2, 1],
            "size": 3,
        },
        "requirements": [
        ],
    },
    {
        "id": 38,
        "product_code": "809.01.50",
        "name": "Tub flexibil antifonic DN127 (10m)",
        "type": "Tub",
        "category": "Flexibil",
        "subtype": "antifonic",
        "specifications": {
            "length": "10m",
            "type": "Tub",
            "subtype": "antifonic",
            "diameter": DN_VARIANTS.DN125,
        },
        "requirements": [
            {
                "type": "prezon",
                "subtype": "flexibil",
                "quantity": 10
            },
            {
                "type": "brida",
                "subtype": DN_VARIANTS.DN125,
                "quantity": 10,
            },
        ],
    },
    // {
    //     "id": 39,
    //     "product_code": "809.02.50",
    //     "name": "Tub flexibil antifonic DN160 (10m)",
    //     "type": "Tub",
    //     "category": "Flexibil",
    //     "subtype": "antifonic",
    //     "specifications": {
    //         "variantKey": 4,
    //         "length": "10m",
    //         "type": "Tub",
    //         "subtype": "antifonic",
    //         "diameter": DN_VARIANTS.DN160,
    //     },
    //     "requirements": [
    //         {
    //             "type": "prezon",
    //             "subtype": "flexibil",
    //             "quantity": 10
    //         },
    //         {
    //             "type": "brida",
    //             "subtype": DN_VARIANTS.DN160,
    //             "quantity": 10,
    //         },
    //     ],
    // },
    {
        "id": 40,
        "product_code": "809.03.50",
        "name": "Kit terminal perete exterior DN125",
        "type": "kit",
        "category": "Terminal",
        "subtype": "exterior",
        "specifications": {
            "type": "kit",
            "subtype": "exterior",
            "diameter": DN_VARIANTS.DN125,
            "variantKey": 1
        }
    },
    {
        "id": 41,
        "product_code": "809.04.50",
        "name": "Kit terminal perete exterior DN150",
        "type": "kit",
        "category": "Terminal",
        "subtype": "exterior",
        "specifications": {
            "type": "kit",
            "subtype": "exterior",
            "diameter": DN_VARIANTS.DN160,
            "variantKey": 3
        }
    },
    {
        "id": 42,
        "product_code": "809.53.32",
        "name": "Gratar terminal perete exterior DN160",
        "type": "gratar",
        "category": "Terminal",
        "subtype": "exterior",
        "specifications": {
            "type": "gratar",
            "subtype": "exterior",
            "diameter": DN_VARIANTS.DN160,
            "variantKey": 4
        }
    },
    {
        "id": 43,
        "product_code": "809.54.20",
        "name": "Gratar terminal perete exterior DN200",
        "type": "gratar",
        "category": "Terminal",
        "subtype": "exterior",
        "specifications": {
            "type": "gratar",
            "subtype": "exterior",
            "diameter": DN_VARIANTS.DN200,
            "variantKey": 5
        }
    },
    {
        "id": 45,
        "product_code": "809.35.91",
        "name": "Tub flexibil antibacterian D.75 (25m)",
        "type": "tubulatura",
        "category": "Flexibil",
        "subtype": "antibacterian",
        "specifications": {
            "type": "tubulatura",
            "diameter": "DN75",
            "length": 25,
        },
        "requirements": [
            {
                "type": "prezon",
                "subtype": "flexibil",
                "quantity": 25
            },
        ],
    },
    {
        "id": 46,
        "product_code": "809.06.75",
        "name": "Cot la 90 de grade DN75",
        "type": "Cot",
        "category": "Conexiune",
        "subtype": "DN75",
        "specifications": {
            "type": "Cot",
            "subtype": "DN75",
            "variantKey": 2
        },
        "requirements": []
    },
    {
        "id": 47,
        "product_code": "809.08.91",
        "name": "Racord imbinare pt teava DN75",
        "type": "racord",
        "category": "Conexiune",
        "subtype": null,
        "specifications": {
            "type": "racord",
            "subtype": "",
            "variantKey": 2
        }
    },
    {
        "id": 48,
        "product_code": "809.09.91",
        "name": "Kit o-ring alb DN75",
        "type": "kit",
        "category": "Conexiune",
        "subtype": "o-ring",
        "specifications": {
            "type": "kit",
            "subtype": "o-ring",
            "variantKey": 2
        }
    },
    {
        "id": 49,
        "product_code": "809.10.75",
        "name": "Dop DN75",
        "type": "dop",
        "category": "Accesorii",
        "subtype": 'DN75',
        "specifications": {
            "type": "dop",
            "subtype": "DN75",
            "variantKey": 2
        }
    },
    {
        "id": 50,
        "product_code": "809.11.75",
        "name": "Cleme fixare teava flex DN75 albastru",
        "type": "Cleme",
        "category": "Accesorii",
        "subtype": "fixare",
        "specifications": {
            "variantKey": 2
        }
    },

    {
        "id": 51,
        "product_code": "809.12.75",
        "name": "Cleme fixare teava flex DN75 rosu",
        "type": "Cleme",
        "category": "Accesorii",
        "subtype": "fixare",
        "specifications": {
            "variantKey": 2
        }
    },
    {
        "id": 52,
        "product_code": "1401.02.65",
        "name": "Banda perforata 12 mm",
        "type": "banda",
        "category": "Accesorii",
        "subtype": "perforata",
        "specifications": {
            "type": "banda",
            "subtype": "perforata",
        }
    },
    {
        "id": 53,
        "product_code": "1401.03.65",
        "name": "Diblu prin bataie 6x40",
        "type": "diblu",
        "category": "Accesorii",
        "subtype": "bataie",
        "specifications": {
            "type": "diblu",
            "subtype": "bataie",
        }
    },
    {
        "id": 54,
        "product_code": "1401.05.65",
        "name": "Banda Aluminiu (10m)",
        "type": "banda",
        "category": "Accesorii",
        "subtype": "aluminiu",
        "specifications": {
            "length": "10m",
            "type": "banda",
            "subtype": "aluminiu",
        }
    },
    {
        "id": 55,
        "product_code": "1401.12.81",
        "name": "Prezon M8x60 mm",
        "type": "prezon",
        "category": "Accesorii",
        "subtype": "flexibil",
        "specifications": {
            "type": "prezon",
            "subtype": "flexibil",
        },
    },
    {
        "id": 56,
        "product_code": "1401.13.81",
        "name": "Prezon M8x120 mm",
        "type": "Prezon",
        "category": "Accesorii",
        "subtype": "EPS",
        "specifications": {
            "type": "Prezon",
            "subtype": "EPS",
        },
    },
    {
        "id": 58,
        "product_code": "1401.20.74",
        "name": "Brida M8/M10 125mm",
        "type": "brida",
        "category": "Accesorii",
        "subtype": DN_VARIANTS.DN125,
        "specifications": {
            "type": "brida",
            "subtype": DN_VARIANTS.DN125,
        }
    },
    {
        "id": 59,
        "product_code": "1401.21.74",
        "name": "Brida M8/M10 150mm",
        "type": "brida",
        "category": "Accesorii",
        "subtype": DN_VARIANTS.DN160,
        "specifications": {
            "type": "brida",
            "subtype": DN_VARIANTS.DN160,
        }
    },
    {
        "id": 60,
        "product_code": "1401.22.74",
        "name": "Brida M8/M10 200mm",
        "type": "brida",
        "category": "Accesorii",
        "subtype": DN_VARIANTS.DN200,
        "specifications": {
            "type": "brida",
            "subtype": DN_VARIANTS.DN200,
        }
    },
    {
        "id": 61,
        "product_code": "1401.23.74",
        "name": "Brida M8/M10 250mm",
        "type": "brida",
        "category": "Accesorii",
        "subtype": DN_VARIANTS.DN250,
        "specifications": {
            "type": "brida",
            "subtype": DN_VARIANTS.DN250,
        }
    },
    {
        "id": 62,
        "product_code": "809.13.66",
        "name": "Conector PVC DN125",
        "type": "conector",
        "category": "Conexiuni",
        "subtype": "PVC",
        "specifications": {
            "type": "conector",
            "subtype": "PVC",
            "diameter": DN_VARIANTS.DN125,
        }
    },
    {
        "id": 63,
        "product_code": "809.14.66",
        "name": "Conector PVC DN150",
        "type": "conector",
        "category": "Conexiuni",
        "subtype": "PVC",
        "specifications": {
            "type": "conector",
            "subtype": "PVC",
            "diameter": DN_VARIANTS.DN160,
            "variantKey": 3
        }
    },
    {
        "id": 64,
        "product_code": "809.15.66",
        "name": "Cot PVC DN125",
        "type": "Cot",
        "category": "Conexiuni",
        "subtype": "PVC",
        "specifications": {
            "variantKey": 1
        }
    },
    {
        "id": 65,
        "product_code": "809.16.66",
        "name": "Cot PVC DN150",
        "type": "Cot",
        "category": "Conexiuni",
        "subtype": "PVC",
        "specifications": {
            "variantKey": 3
        }
    },
    {
        "id": 66,
        "product_code": "809.17.66",
        "name": "Reductie PVC DN150-125",
        "type": "reductie",
        "category": "Conexiuni",
        "subtype": "PVC",
        "specifications": {}
    },
    {
        "id": 67,
        "product_code": "809.18.66",
        "name": "Reductie PVC DN200-150",
        "type": "reductie",
        "category": "Conexiuni",
        "subtype": "PVC",
        "specifications": {
            "type": "reductie",
            "subtype": "PVC",
            "variant": 200,
        }
    },
    {
        "id": 68,
        "product_code": "809.19.66",
        "name": "Colier metalic 110-130mm",
        "type": "colier",
        "category": "Accesorii",
        "subtype": "metalic",
        "specifications": {
            "type": "colier",
            "subtype": "metalic",
            "diameter": DN_VARIANTS.DN125,
        }
    },
    {
        "id": 69,
        "product_code": "809.20.66",
        "name": "Colier metalic 140-160mm",
        "type": "colier",
        "category": "Accesorii",
        "subtype": "metalic",
        "specifications": {
            "type": "colier",
            "subtype": "metalic",
            "diameter": DN_VARIANTS.DN150,
        }
    },
    {
        "id": 70,
        "product_code": "809.21.66",
        "name": "Colier metalic 150-170mm",
        "type": "Colier",
        "category": "Accesorii",
        "subtype": "metalic",
        "specifications": {
            "type": "colier",
            "subtype": "metalic",
            "diameter": DN_VARIANTS.DN160,
        }
    },

    {
        "id": 71,
        "product_code": "809.22.66",
        "name": "Colier metalic 190-210mm",
        "type": "Colier",
        "category": "Accesorii",
        "subtype": "metalic",
        "specifications": {}
    },
    {
        "id": 72,
        "product_code": "809.23.83",
        "name": "Tubulatura EPS d.200",
        "type": "Tubulatura",
        "category": "tubulatura",
        "subtype": 1,
        "specifications": {
            "type": "tubulatura",
            "subtype": 1,
            "diameter": DN_VARIANTS.DN200,
            "variantKey": 5,
            "variant": "normal",
        },
        "requirements": [
            {
                "type": "prezon",
                "subtype": "flexibil",
                "quantity": 1
            },
            {
                "type": "brida",
                "subtype": DN_VARIANTS.DN250,
            },
        ],
    },
    {
        "id": 73,
        "product_code": "809.40.32",
        "name": "Tubulatura EPS d.125 (slim)",
        "type": "Tubulatura",
        "category": "tubulatura",
        "subtype": 1,
        "specifications": {
            "type": "tubulatura",
            "subtype": 1,
            "diameter": DN_VARIANTS.DN125,
            "variantKey": 1,
            "variant": "slim",
        },
        "requirements": [
            {
                "type": "prezon",
                "subtype": "flexibil",
                "quantity": 1
            },
            {
                "type": "brida",
                "subtype": DN_VARIANTS.DN160,
            },
        ],
    },
    {
        "id": 74,
        "product_code": "809.41.32",
        "name": "Tubulatura EPS d.160 (slim)",
        "type": "tubulatura",
        "category": "Tubulatura",
        "subtype": 1,
        "specifications": {
            "type": "tubulatura",
            "subtype": 1,
            "diameter": DN_VARIANTS.DN160,
            "variantKey": 4,
            "variant": "slim",
        },
        "requirements": [
            {
                "type": "prezon",
                "subtype": "flexibil",
                "quantity": 1
            },
            {
                "type": "brida",
                "subtype": DN_VARIANTS.DN200,
            },
        ],
    },
    {
        "id": 75,
        "product_code": "809.42.32",
        "name": "Tubulatura EPS d.200 (slim)",
        "type": "Tubulatutubulaturara",
        "category": "Tubulatura",
        "subtype": 1,
        "specifications": {
            "type": "tubulatura",
            "subtype": 1,
            "diameter": DN_VARIANTS.DN200,
            "variant": "slim",
            "variantKey": 5
        },
        "requirements": [
            {
                "type": "prezon",
                "subtype": "flexibil",
                "quantity": 1
            },
            {
                "type": "brida",
                "subtype": DN_VARIANTS.DN250,
            },
        ],
    },
    {
        "id": 76,
        "product_code": "809.24.83",
        "name": "Cot EPS 45 d.200",
        "type": "Cot",
        "category": "Conexiuni",
        "subtype": "EPS",
        "specifications": {
            "type": "cot",
            "subtype": "EPS",
            "diameter": DN_VARIANTS.DN200,
            "variant": "normal"
        }
    },
    {
        "id": 77,
        "product_code": "809.43.32",
        "name": "Cot EPS 45 d.125 (slim)",
        "type": "cot",
        "category": "Conexiuni",
        "subtype": "EPS",
        "specifications": {
            "type": "cot",
            "subtype": "EPS",
            "diameter": DN_VARIANTS.DN125,
            "variant": "slim",
        }
    },
    {
        "id": 78,
        "product_code": "809.44.32",
        "name": "Cot EPS 45 d.160 (slim)",
        "type": "cot",
        "category": "Conexiuni",
        "subtype": "EPS",
        "specifications": {
            "type": "cot",
            "subtype": "EPS",
            "diameter": DN_VARIANTS.DN160,
            "variant": "slim",
        }
    },
    {
        "id": 79,
        "product_code": "809.45.32",
        "name": "Cot EPS 45 d.200 (slim)",
        "type": "cot",
        "category": "Conexiuni",
        "subtype": "EPS",
        "specifications": {
            "type": "cot",
            "subtype": "EPS",
            "diameter": DN_VARIANTS.DN200,
            "variant": "slim",
        }
    },
    {
        "id": 80,
        "product_code": "809.25.83",
        "name": "Teu EPS d.200",
        "type": "Teu",
        "category": "Conexiuni",
        "subtype": "EPS",
        "specifications": {
            "type": "teu",
            "subtype": "EPS",
            "diameter": DN_VARIANTS.DN200,
            "variant": "normal",
        }
    },
    {
        "id": 81,
        "product_code": "809.46.32",
        "name": "Teu EPS d.125 (slim)",
        "type": "teu",
        "category": "Conexiuni",
        "subtype": "EPS",
        "specifications": {
            "type": "teu",
            "subtype": "EPS",
            "diameter": DN_VARIANTS.DN125,
            "variant": "slim",
            "variantKey": 1
        }
    },
    {
        "id": 82,
        "product_code": "809.47.32",
        "name": "Teu EPS d.160 (slim)",
        "type": "teu",
        "category": "Conexiuni",
        "subtype": "EPS",
        "specifications": {
            "type": "teu",
            "subtype": "EPS",
            "diameter": DN_VARIANTS.DN160,
            "variant": "slim",
            "variantKey": 4
        }
    },
    {
        "id": 83,
        "product_code": "809.48.32",
        "name": "Teu EPS d.200 (slim)",
        "type": "teu",
        "category": "Conexiuni",
        "subtype": "EPS",
        "specifications": {
            "type": "teu",
            "subtype": "EPS",
            "diameter": DN_VARIANTS.DN200,
            "variant": "slim",
            "variantKey": 5
        }
    },
    {
        "id": 84,
        "product_code": "809.26.83",
        "name": "Mufa EPS d.200",
        "type": "mufa",
        "category": "Conexiuni",
        "subtype": "EPDM",
        "specifications": {
             "type": "mufa",
            "subtype": "EPDM",
            "diameter": DN_VARIANTS.DN200,
            "variant": "normal",
            "variantKey": 4
        }
    },
    {
        "id": 85,
        "product_code": "809.49.32",
        "name": "Mufa EPDM d.125 (slim)",
        "type": "mufa",
        "category": "Conexiuni",
        "subtype": "EPDM",
        "specifications": {
            "type": "mufa",
            "subtype": "EPDM",
            "diameter": DN_VARIANTS.DN125,
            "variant": "slim",
            "variantKey": 1
        }
    },
    {
        "id": 86,
        "product_code": "809.50.32",
        "name": "Mufa EPDM d.160 (slim)",
        "type": "mufa",
        "category": "Conexiuni",
        "subtype": "EPDM",
        "specifications": {
            "type": "mufa",
            "subtype": "EPDM",
            "diameter": DN_VARIANTS.DN160,
            "variant": "slim",
            "variantKey": 4
        }
    },
    {
        "id": 87,
        "product_code": "809.51.32",
        "name": "Mufa EPDM d.200 (slim)",
        "type": "mufa",
        "category": "Conexiuni",
        "subtype": "EPDM",
        "specifications": {
            "type": "mufa",
            "subtype": "EPDM",
            "diameter": DN_VARIANTS.DN200,
            "variant": "slim",
            "variantKey": 5
        }
    },
    {
        "id": 88,
        "product_code": "809.52.32",
        "name": "Reductie 200/160/125 (slim)",
        "type": "reductie",
        "category": "Conexiuni",
        "subtype": "EPS",
        "specifications": {
            "type": "reductie",
            "subtype": "general",
        },
    },
    {
        "id": 89,
        "product_code": "809.05.91",
        "name": "Tub flexibil antibacterian D.75 (50m)",
        "type": "tubulatura",
        "specifications": {
            "type": "tubulatura",
            "diameter": "DN75",
            "length": 50,
        },
        "requirements": [
            {
                "type": "racord",
                "subtype": "",
                "quantity": 1,
            },
        ]
    },
    {
        "id": 92,
        "product_code": "803.01.50",
        "name": "Unitate Standard 180A",
        "type": "unit",
        "subtype": "standard",
        "specifications": {
            "type": "unit",
            "subtype": "standard",
            "version": "180A",
            "plus": 0,
            "sensors": basicUnitSensors,
        },
        "recommendations": [
            {
                "type": "filter",
                "subtype": "standard",
                "variant": "G4",
                "versions": ["180A"],
            },
            {
                "type": "sensor",
                "subtype": "Dispenser parfum",
            },
            {
                "type": "sensor",
                "subtype": "Purificator Jonix",
                "volume": 500,
                "quantity": 1,
            },
        ],
        "requirements": [
            {
                "type": "filter",
                "subtype": "standard",
                "variant": "G4",
                "versions": ["180A"],
                "quantity": 0,
            },
            {
                "type": "sensor",
                "subtype": "Dispenser parfum",
                "quantity": 0,
            },
            {
                "type": "sensor",
                "subtype": "Purificator Jonix",
                "volume": 500,
                "quantity": 0,
            },
            {
                "type": "mounting",
                "subtype": "purifier",
                "quantity": 0,
            },
        ]
    },
    {
        "id": 93,
        "product_code": "803.02.50",
        "name": "Unitate Standard 280A",
        "type": "unit",
        "subtype": "standard",
        "specifications": {
            "type": "unit",
            "subtype": "standard",
            "version": "280A",
            "plus": 0,
            "sensors": basicUnitSensors,
        },
        "recommendations": [
            {
                "type": "filter",
                "subtype": "standard",
                "variant": "G4",
                "versions": ["280A"],
            },
            {
                "type": "sensor",
                "subtype": "Dispenser parfum",
            },
            {
                "type": "sensor",
                "subtype": "Purificator Jonix",
                "volume": 500,
                "quantity": 1,
            },
        ],
        "requirements": [
            {
                "type": "filter",
                "subtype": "standard",
                "variant": "G4",
                "versions": ["280A"],
                "quantity": 0,
            },
            {
                "type": "sensor",
                "subtype": "Dispenser parfum",
                "quantity": 0,
            },
            {
                "type": "sensor",
                "subtype": "Purificator Jonix",
                "volume": 500,
                "quantity": 0,
            },
            {
                "type": "mounting",
                "subtype": "purifier",
                "quantity": 0,
            },
        ]
    },
    {
        "id": 94,
        "product_code": "803.04.50",
        "name": "Unitate Standard 400A",
        "type": "unit",
        "subtype": "standard",
        "specifications": {
            "type": "unit",
            "subtype": "standard",
            "version": "400A",
            "plus": 0,
            "sensors": basicUnitSensors,
        },
        "recommendations": [
            {
                "type": "filter",
                "subtype": "standard",
                "variant": "G4",
                "versions": ["400A"],
            },
            {
                "type": "filter",
                "subtype": "standard",
                "variant": "F7",
                "versions": ["400A"],
            },
            {
                "type": "sensor",
                "subtype": "Dispenser parfum",
            },
            {
                "type": "sensor",
                "subtype": "Purificator Jonix",
                "volume": 500,
                "quantity": 1,
            },
        ],
        "requirements": [
            {
                "type": "filter",
                "subtype": "standard",
                "variant": "G4",
                "versions": ["400A"],
                "quantity": 0,
            },
            {
                "type": "filter",
                "subtype": "standard",
                "variant": "F7",
                "versions": ["400A"],
                "quantity": 0,
            },
            {
                "type": "sensor",
                "subtype": "Dispenser parfum",
                "quantity": 0,
            },
            {
                "type": "sensor",
                "subtype": "Purificator Jonix",
                "volume": 500,
                "quantity": 0,
            },
            {
                "type": "mounting",
                "subtype": "purifier",
                "quantity": 0,
            },
        ]
    },
    {
        "id": 95,
        "product_code": "803.05.50",
        "name": "Unitate Standard 550A",
        "type": "unit",
        "subtype": "standard",
        "specifications": {
            "type": "unit",
            "subtype": "standard",
            "version": "550A",
            "plus": 0,
            "sensors": basicUnitSensors,
        },
        "recommendations": [
            {
                "type": "filter",
                "subtype": "standard",
                "variant": "G4",
                "versions": ["550A"],
            },
            {
                "type": "filter",
                "subtype": "standard",
                "variant": "F7",
                "versions": ["550A"],
                "quantity": 0,
            },
            {
                "type": "sensor",
                "subtype": "Dispenser parfum",
            },
            {
                "type": "sensor",
                "subtype": "Purificator Jonix",
                "volume": 1000,
                "quantity": 1,
            },
        ],
        "requirements": [
            {
                "type": "filter",
                "subtype": "standard",
                "variant": "G4",
                "versions": ["550A"],
                "quantity": 0,
            },
            {
                "type": "filter",
                "subtype": "standard",
                "variant": "F7",
                "versions": ["550A"],
                "quantity": 0,
            },
            {
                "type": "sensor",
                "subtype": "Dispenser parfum",
                "quantity": 0,
            },
            {
                "type": "sensor",
                "subtype": "Purificator Jonix",
                "volume": 1000,
                "quantity": 0,
            },
            {
                "type": "mounting",
                "subtype": "purifier",
                "quantity": 0,
            },
        ]
    },
    {
        "id": 96,
        "product_code": "803.07.83",
        "name": "Unitate Premium 300h +",
        "type": "unit",
        "subtype": "premium",
        "specifications": {
            "type": "unit",
            "subtype": "premium",
            "version": "300h",
            "plus": 1,
            "sensors": [...basicUnitSensors, ...premiumUnitSensors],
        },
        "recommendations": [
            {
                "type": "sensor",
                "subtype": "Purificator Jonix",
                "volume": 500,
                "quantity": 1,
            },
            {
                "type": "filter",
                "subtype": "premium",
                "variant": "M5",
                "versions": [
                    "300h +"
                ],
            },
            {
                "type": "sensor",
                "subtype": "Dispenser parfum",
            },
        ],
        "requirements": [
            {
                "type": "sensor",
                "subtype": "Purificator Jonix",
                "volume": 500,
                "quantity": 0,
            },
            {
                "type": "filter",
                "subtype": "premium",
                "variant": "M5",
                "versions": [
                    "300h +"
                ],
                "quantity": 0,
            },
            {
                "type": "sensor",
                "subtype": "Dispenser parfum",
                "quantity": 0,
            },
        ]
    },
    {
        "id": 97,
        "product_code": "803.08.83",
        "name": "Unitate Premium 300h ++",
        "type": "unit",
        "subtype": "premium",
        "specifications": {
            "type": "unit",
            "subtype": "premium",
            "version": "300h",
            "plus": 2,
            "sensors": [...basicUnitSensors, ...premiumUnitSensors],
        },
        "recommendations": [
            {
                "type": "sensor",
                "subtype": "Purificator Jonix",
                "volume": 500,
                "quantity": 1,
            },
            {
                "type": "filter",
                "subtype": "premium",
                "variant": "M5",
                "versions": [
                    "300h ++"
                ],
            },
            {
                "type": "sensor",
                "subtype": "Dispenser parfum",
            },
        ],
        "requirements": [
            {
                "type": "sensor",
                "subtype": "Purificator Jonix",
                "volume": 500,
                "quantity": 0,
            },
            {
                "type": "filter",
                "subtype": "premium",
                "variant": "M5",
                "versions": [
                    "300h ++"
                ],
                "quantity": 0,
            },
            {
                "type": "sensor",
                "subtype": "Dispenser parfum",
                "quantity": 0,
            },
        ]
    },
    {
        "id": 98,
        "product_code": "803.10.83",
        "name": "Unitate Premium 400h +",
        "type": "unit",
        "subtype": "premium",
        "specifications": {
            "type": "unit",
            "subtype": "premium",
            "version": "400h",
            "plus": 1,
            "sensors": [...basicUnitSensors, ...premiumUnitSensors],
        },
        "recommendations": [
            {
                "type": "sensor",
                "subtype": "Purificator Jonix",
                "volume": 500,
                "quantity": 1,
            },
            {
                "type": "filter",
                "subtype": "premium",
                "variant": "M5",
                "versions": [
                    "400h +"
                ],
            },
            {
                "type": "sensor",
                "subtype": "Dispenser parfum",
            },
        ],
        "requirements": [
            {
                "type": "sensor",
                "subtype": "Purificator Jonix",
                "volume": 500,
                "quantity": 0,
            },
            {
                "type": "filter",
                "subtype": "premium",
                "variant": "M5",
                "versions": [
                    "400h +"
                ],
                "quantity": 0,
            },
            {
                "type": "sensor",
                "subtype": "Dispenser parfum",
                "quantity": 0,
            },
        ]
    },
    {
        "id": 99,
        "product_code": "803.11.83",
        "name": "Unitate Premium 400h ++",
        "type": "unit",
        "subtype": "premium",
        "specifications": {
            "type": "unit",
            "subtype": "premium",
            "version": "400h",
            "plus": 2,
            "sensors": [...basicUnitSensors, ...premiumUnitSensors]
        },
        "recommendations": [
            {
                "type": "sensor",
                "subtype": "Purificator Jonix",
                "volume": 500,
                "quantity": 1,
            },
            {
                "type": "filter",
                "subtype": "premium",
                "variant": "M5",
                "versions": [
                    "400h ++"
                ],
            },
            {
                "type": "sensor",
                "subtype": "Dispenser parfum",
            },
        ],
        "requirements": [
            {
                "type": "sensor",
                "subtype": "Purificator Jonix",
                "volume": 500,
                "quantity": 0,
            },
            {
                "type": "filter",
                "subtype": "premium",
                "variant": "M5",
                "versions": [
                    "400h ++"
                ],
                "quantity": 0,
            },
            {
                "type": "sensor",
                "subtype": "Dispenser parfum",
                "quantity": 0,
            },
        ]
    },
    {
        "id": 100,
        "product_code": "803.13.83",
        "name": "Unitate Premium 500h +",
        "type": "unit",
        "subtype": "premium",
        "specifications": {
            "type": "unit",
            "subtype": "premium",
            "version": "500h",
            "plus": 1,
            "sensors": [...basicUnitSensors, ...premiumUnitSensors]
        },
        "recommendations": [
            {
                "type": "sensor",
                "subtype": "Purificator Jonix",
                "volume": 1000,
                "quantity": 1,
            },
            {
                "type": "filter",
                "subtype": "premium",
                "variant": "M5",
                "versions": [
                    "500h +"
                ],
            },
            {
                "type": "sensor",
                "subtype": "Dispenser parfum",
            },
        ],
        "requirements": [
            {
                "type": "sensor",
                "subtype": "Purificator Jonix",
                "volume": 1000,
                "quantity": 0,
            },
            {
                "type": "filter",
                "subtype": "premium",
                "variant": "M5",
                "versions": [
                    "500h +"
                ],
                "quantity": 0,
            },
            {
                "type": "sensor",
                "subtype": "Dispenser parfum",
                "quantity": 0,
            },
        ]
    },
    {
        "id": 101,
        "product_code": "803.14.83",
        "name": "Unitate Premium 500h ++",
        "type": "unit",
        "subtype": "premium",
        "specifications": {
            "type": "unit",
            "subtype": "premium",
            "version": "500h",
            "plus": 2,
            "sensors": [...basicUnitSensors, ...premiumUnitSensors]
        },
        "recommendations": [
            {
                "type": "sensor",
                "subtype": "Purificator Jonix",
                "volume": 1000,
                "quantity": 1,
            },
            {
                "type": "filter",
                "subtype": "premium",
                "variant": "M5",
                "versions": [
                    "500h ++"
                ],
            },
            {
                "type": "sensor",
                "subtype": "Dispenser parfum",
            },
        ],
        "requirements": [
            {
                "type": "sensor",
                "subtype": "Purificator Jonix",
                "volume": 1000,
                "quantity": 0,
            },
            {
                "type": "filter",
                "subtype": "premium",
                "variant": "M5",
                "versions": [
                    "500h ++"
                ],
                "quantity": 0,
            },
            {
                "type": "sensor",
                "subtype": "Dispenser parfum",
                "quantity": 0,
            },
        ]
    },
    {
        "id": 102,
        "product_code": "803.27.83",
        "name": "Unitate Premium 300v +",
        "type": "unit",
        "subtype": "premium",
        "specifications": {
            "type": "unit",
            "subtype": "premium",
            "version": "300v",
            "plus": 1,
            "sensors": [...basicUnitSensors, ...premiumUnitSensors]
        },
        "recommendations": [
            {
                "type": "sensor",
                "subtype": "Purificator Jonix",
                "volume": 500,
                "quantity": 1,
            },
            {
                "type": "filter",
                "subtype": "premium",
                "variant": "M5",
                "versions": [
                    "300v +"
                ],
            },
            {
                "type": "sensor",
                "subtype": "Dispenser parfum",
            },
        ],
        "requirements": [
            {
                "type": "sensor",
                "subtype": "Purificator Jonix",
                "volume": 500,
                "quantity": 0,
            },
            {
                "type": "filter",
                "subtype": "premium",
                "variant": "M5",
                "versions": [
                    "300v +"
                ],
                "quantity": 0,
            },
            {
                "type": "sensor",
                "subtype": "Dispenser parfum",
                "quantity": 0,
            },
        ]
    },
    {
        "id": 103,
        "product_code": "803.28.83",
        "name": "Unitate Premium 300v ++",
        "type": "unit",
        "subtype": "premium",
        "specifications": {
            "type": "unit",
            "subtype": "premium",
            "version": "300v",
            "plus": 2,
            "sensors": [...basicUnitSensors, ...premiumUnitSensors]
        },
        "recommendations": [
            {
                "type": "sensor",
                "subtype": "Purificator Jonix",
                "volume": 500,
                "quantity": 1,
            },
            {
                "type": "filter",
                "subtype": "premium",
                "variant": "M5",
                "versions": [
                    "300v ++"
                ],
            },
            {
                "type": "sensor",
                "subtype": "Dispenser parfum",
            },
        ],
        "requirements": [
            {
                "type": "sensor",
                "subtype": "Purificator Jonix",
                "volume": 500,
                "quantity": 0,
            },
            {
                "type": "filter",
                "subtype": "premium",
                "variant": "M5",
                "versions": [
                    "300v ++"
                ],
                "quantity": 0,
            },
            {
                "type": "sensor",
                "subtype": "Dispenser parfum",
                "quantity": 0,
            },
        ]
    },
    {
        "id": 104,
        "product_code": "803.29.83",
        "name": "Unitate Premium 400v +",
        "type": "unit",
        "subtype": "premium",
        "specifications": {
            "type": "unit",
            "subtype": "premium",
            "version": "400v",
            "plus": 1,
            "sensors": [...basicUnitSensors, ...premiumUnitSensors]
        },
        "recommendations": [
            {
                "type": "sensor",
                "subtype": "Purificator Jonix",
                "volume": 500,
                "quantity": 1,
            },
            {
                "type": "filter",
                "subtype": "premium",
                "variant": "M5",
                "versions": [
                    "400v +"
                ],
            },
            {
                "type": "sensor",
                "subtype": "Dispenser parfum",
            },
        ],
        "requirements": [
            {
                "type": "sensor",
                "subtype": "Purificator Jonix",
                "volume": 500,
                "quantity": 0,
            },
            {
                "type": "filter",
                "subtype": "premium",
                "variant": "M5",
                "versions": [
                    "400v +"
                ],
                "quantity": 0,
            },
            {
                "type": "sensor",
                "subtype": "Dispenser parfum",
                "quantity": 0,
            },
        ]
    },
    {
        "id": 105,
        "product_code": "803.30.83",
        "name": "Unitate Premium 400v ++",
        "type": "unit",
        "subtype": "premium",
        "specifications": {
            "type": "unit",
            "subtype": "premium",
            "version": "400v",
            "plus": 2,
            "sensors": [...basicUnitSensors, ...premiumUnitSensors]
        },
        "recommendations": [
            {
                "type": "sensor",
                "subtype": "Purificator Jonix",
                "volume": 500,
                "quantity": 1,
            },
            {
                "type": "filter",
                "subtype": "premium",
                "variant": "M5",
                "versions": [
                    "400v ++"
                ],
            },
            {
                "type": "sensor",
                "subtype": "Dispenser parfum",
            },
        ],
        "requirements": [
            {
                "type": "sensor",
                "subtype": "Purificator Jonix",
                "volume": 500,
                "quantity": 0,
            },
            {
                "type": "filter",
                "subtype": "premium",
                "variant": "M5",
                "versions": [
                    "400v ++"
                ],
                "quantity": 0,
            },
            {
                "type": "sensor",
                "subtype": "Dispenser parfum",
                "quantity": 0,
            },
        ]
    },
    {
        "id": 106,
        "product_code": "803.31.83",
        "name": "Unitate Premium 500v +",
        "type": "unit",
        "subtype": "premium",
        "specifications": {
            "type": "unit",
            "subtype": "premium",
            "version": "500v",
            "plus": 1,
            "sensors": [...basicUnitSensors, ...premiumUnitSensors]
        },
        "recommendations": [
            {
                "type": "sensor",
                "subtype": "Purificator Jonix",
                "volume": 1000,
                "quantity": 1,
            },
            {
                "type": "filter",
                "subtype": "premium",
                "variant": "M5",
                "versions": [
                    "500v +"
                ],
            },
            {
                "type": "sensor",
                "subtype": "Dispenser parfum",
            },
        ],
        "requirements": [
            {
                "type": "sensor",
                "subtype": "Purificator Jonix",
                "volume": 1000,
                "quantity": 0,
            },
            {
                "type": "filter",
                "subtype": "premium",
                "variant": "M5",
                "versions": [
                    "500v +"
                ],
                "quantity": 0,
            },
            {
                "type": "sensor",
                "subtype": "Dispenser parfum",
                "quantity": 0,
            },
        ]
    },
    {
        "id": 107,
        "product_code": "803.32.83",
        "name": "Unitate Premium 500v ++",
        "type": "unit",
        "subtype": "premium",
        "specifications": {
            "type": "unit",
            "subtype": "premium",
            "version": "500v",
            "plus": 2,
            "sensors": [...basicUnitSensors, ...premiumUnitSensors]
        },
        "recommendations": [
            {
                "type": "sensor",
                "subtype": "Purificator Jonix",
                "volume": 1000,
                "quantity": 1,
            },
            {
                "type": "filter",
                "subtype": "premium",
                "variant": "M5",
                "versions": [
                    "500v ++"
                ],
            },
            {
                "type": "sensor",
                "subtype": "Dispenser parfum",
            },
        ],
        "requirements": [
            {
                "type": "sensor",
                "subtype": "Purificator Jonix",
                "volume": 1000,
                "quantity": 0,
            },
            {
                "type": "filter",
                "subtype": "premium",
                "variant": "M5",
                "versions": [
                    "500v ++"
                ],
                "quantity": 0,
            },
            {
                "type": "sensor",
                "subtype": "Dispenser parfum",
                "quantity": 0,
            },
        ]
    },
    {
        "id": 108,
        "product_code": "803.33.83",
        "name": "Unitate Premium 550v +",
        "type": "unit",
        "subtype": "premium",
        "specifications": {
            "type": "unit",
            "subtype": "premium",
            "version": "550v",
            "plus": 1,
            "sensors": [...basicUnitSensors, ...premiumUnitSensors]
        },
        "recommendations": [
            {
                "type": "sensor",
                "subtype": "Purificator Jonix",
                "volume": 1000,
                "quantity": 1,
            },
            {
                "type": "filter",
                "subtype": "premium",
                "variant": "M5",
                "versions": [
                    "550v +",
                ],
            },
            {
                "type": "sensor",
                "subtype": "Dispenser parfum",
            },
        ],
        "requirements": [
            {
                "type": "sensor",
                "subtype": "Purificator Jonix",
                "volume": 1000,
                "quantity": 0,
            },
            {
                "type": "filter",
                "subtype": "premium",
                "variant": "M5",
                "versions": [
                    "550v +"
                ],
                "quantity": 0,
            },
            {
                "type": "sensor",
                "subtype": "Dispenser parfum",
                "quantity": 0,
            },
        ]
    },
    {
        "id": 109,
        "product_code": "803.34.83",
        "name": "Unitate Premium 550v ++",
        "type": "unit",
        "subtype": "premium",
        "specifications": {
            "type": "unit",
            "subtype": "premium",
            "version": "550v",
            "plus": 2,
            "sensors": [...basicUnitSensors, ...premiumUnitSensors]
        },
        "recommendations": [
            {
                "type": "sensor",
                "subtype": "Purificator Jonix",
                "volume": 1000,
                "quantity": 1,
            },
            {
                "type": "filter",
                "subtype": "premium",
                "variant": "M5",
                "versions": [
                    "550v ++",
                ],
            },
            {
                "type": "sensor",
                "subtype": "Dispenser parfum",
            },
        ],
        "requirements": [
            {
                "type": "sensor",
                "subtype": "Purificator Jonix",
                "volume": 1000,
                "quantity": 0,
            },
            {
                "type": "filter",
                "subtype": "premium",
                "variant": "M5",
                "versions": [
                    "550v ++"
                ],
                "quantity": 0,
            },
            {
                "type": "sensor",
                "subtype": "Dispenser parfum",
                "quantity": 0,
            },
        ]
    },
    {
        "id": 110,
        "product_code": "803.15.83",
        "name": "Unitate Premium Home 200f",
        "type": "unit",
        "subtype": "home",
        "specifications": {
            "type": "unit",
            "subtype": "home",
            "version": "200f",
            "plus": 0,
            "sensors": [...basicUnitSensors, ...premiumUnitSensors]
        },
        "requirements": [
            {
                "type": "support",
                "subtype": "ceiling",
            },
            {
                "type": "sensor",
                "subtype": "Purificator Jonix",
                "volume": 500,
                "quantity": 0,
            },
            {
                "type": "filter",
                "subtype": "home",
                "variant": "M5",
                "versions": [
                    "200f",
                ],
                "quantity": 0,
            },
            {
                "type": "sensor",
                "subtype": "Dispenser parfum",
                "quantity": 0,
            },
        ],
        "recommendations": [
            {
                "type": "sensor",
                "subtype": "Purificator Jonix",
                "volume": 500,
                "quantity": 1,
            },
            {
                "type": "filter",
                "subtype": "home",
                "variant": "M5",
                "versions": [
                    "200f",
                ],
            },
            {
                "type": "sensor",
                "subtype": "Dispenser parfum",
            },
        ],
    },
    {
        "id": 111,
        "product_code": "803.16.83",
        "name": "Unitate Premium Home 200f +",
        "type": "unit",
        "subtype": "home",
        "specifications": {
            "type": "unit",
            "subtype": "home",
            "version": "200f",
            "plus": 1,
            "sensors": [...basicUnitSensors, ...premiumUnitSensors]
        },
        "requirements": [
            {
                "type": "support",
                "subtype": "ceiling",
            },
            {
                "type": "sensor",
                "subtype": "Purificator Jonix",
                "volume": 500,
                "quantity": 0,
            },
            {
                "type": "filter",
                "subtype": "home",
                "variant": "M5",
                "versions": [
                    "200f +",
                ],
                "quantity": 0,
            },
            {
                "type": "sensor",
                "subtype": "Dispenser parfum",
                "quantity": 0,
            },
        ],
        "recommendations": [
            {
                "type": "sensor",
                "subtype": "Purificator Jonix",
                "volume": 500,
                "quantity": 1,
            },
            {
                "type": "filter",
                "subtype": "home",
                "variant": "M5",
                "versions": [
                    "200f +",
                ],
            },
            {
                "type": "sensor",
                "subtype": "Dispenser parfum",
            },
        ],
    },
    // {
    //     "id": 112,
    //     "product_code": "803.17.83",
    //     "name": "Unitate Premium Home 300v",
    //     "type": "unit",
    //     "subtype": "home",
    //     "specifications": {
    //         "type": "unit",
    //         "subtype": "home",
    //         "version": "300v",
    //         "plus": 0,
    //         "sensors": [...basicUnitSensors, ...premiumUnitSensors]
    //     },
    //     "recommendations": [
    //         {
    //             "type": "sensor",
    //             "subtype": "Purificator Jonix",
    //             "volume": 500,
    //             "quantity": 1,
    //         },
    //         {
    //             "type": "filter",
    //             "subtype": "home",
    //             "variant": "M5",
    //             "versions": [
    //                 "300v"
    //             ],
    //         },
    //         {
    //             "type": "sensor",
    //             "subtype": "Dispenser parfum",
    //         },
    //     ],
    //     "requirements": [
    //         {
    //             "type": "sensor",
    //             "subtype": "Purificator Jonix",
    //             "volume": 500,
    //             "quantity": 0,
    //         },
    //         {
    //             "type": "filter",
    //             "subtype": "home",
    //             "variant": "M5",
    //             "versions": [
    //                 "300v"
    //             ],
    //             "quantity": 0,
    //         },
    //         {
    //             "type": "sensor",
    //             "subtype": "Dispenser parfum",
    //             "quantity": 0,
    //         },
    //     ]
    // },
    // {
    //     "id": 113,
    //     "product_code": "803.18.83",
    //     "name": "Unitate Premium Home 300v +",
    //     "type": "unit",
    //     "subtype": "home",
    //     "specifications": {
    //         "type": "unit",
    //         "subtype": "home",
    //         "version": "300v",
    //         "plus": 1,
    //         "sensors": [...basicUnitSensors, ...premiumUnitSensors]
    //     },
    //     "recommendations": [
    //         {
    //             "type": "sensor",
    //             "subtype": "Purificator Jonix",
    //             "volume": 500,
    //             "quantity": 1,
    //         },
    //         {
    //             "type": "filter",
    //             "subtype": "home",
    //             "variant": "M5",
    //             "versions": [
    //                 "300v +"
    //             ],
    //         },
    //         {
    //             "type": "sensor",
    //             "subtype": "Dispenser parfum",
    //         },
    //     ],
    //     "requirements": [
    //         {
    //             "type": "sensor",
    //             "subtype": "Purificator Jonix",
    //             "volume": 500,
    //             "quantity": 0,
    //         },
    //         {
    //             "type": "filter",
    //             "subtype": "home",
    //             "variant": "M5",
    //             "versions": [
    //                 "300v +"
    //             ],
    //             "quantity": 0,
    //         },
    //         {
    //             "type": "sensor",
    //             "subtype": "Dispenser parfum",
    //             "quantity": 0,
    //         },
    //     ]
    // },
    // {
    //     "id": 114,
    //     "product_code": "803.19.83",
    //     "name": "Unitate Premium Home 400v",
    //     "type": "unit",
    //     "subtype": "home",
    //     "specifications": {
    //         "type": "unit",
    //         "subtype": "home",
    //         "version": "400v",
    //         "plus": 0,
    //         "sensors": [...basicUnitSensors, ...premiumUnitSensors]
    //     },
    //     "recommendations": [
    //         {
    //             "type": "sensor",
    //             "subtype": "Purificator Jonix",
    //             "volume": 500,
    //             "quantity": 1,
    //         },
    //         {
    //             "type": "filter",
    //             "subtype": "home",
    //             "variant": "M5",
    //             "versions": [
    //                 "400v"
    //             ],
    //         },
    //         {
    //             "type": "sensor",
    //             "subtype": "Dispenser parfum",
    //         },
    //     ],
    //     "requirements": [
    //         {
    //             "type": "sensor",
    //             "subtype": "Purificator Jonix",
    //             "volume": 500,
    //             "quantity": 0,
    //         },
    //         {
    //             "type": "filter",
    //             "subtype": "home",
    //             "variant": "M5",
    //             "versions": [
    //                 "400v"
    //             ],
    //             "quantity": 0,
    //         },
    //         {
    //             "type": "sensor",
    //             "subtype": "Dispenser parfum",
    //             "quantity": 0,
    //         },
    //     ]
    // },
    // {
    //     "id": 115,
    //     "product_code": "803.20.83",
    //     "name": "Unitate Premium Home 400v +",
    //     "type": "unit",
    //     "subtype": "home",
    //     "specifications": {
    //         "type": "unit",
    //         "subtype": "home",
    //         "version": "400v",
    //         "plus": 1,
    //         "sensors": [...basicUnitSensors, ...premiumUnitSensors]
    //     },
    //     "recommendations": [
    //         {
    //             "type": "sensor",
    //             "subtype": "Purificator Jonix",
    //             "volume": 500,
    //             "quantity": 1,
    //         },
    //         {
    //             "type": "filter",
    //             "subtype": "home",
    //             "variant": "M5",
    //             "versions": [
    //                 "400v +"
    //             ],
    //         },
    //         {
    //             "type": "sensor",
    //             "subtype": "Dispenser parfum",
    //         },
    //     ],
    //     "requirements": [
    //         {
    //             "type": "sensor",
    //             "subtype": "Purificator Jonix",
    //             "volume": 500,
    //             "quantity": 0,
    //         },
    //         {
    //             "type": "filter",
    //             "subtype": "home",
    //             "variant": "M5",
    //             "versions": [
    //                 "400v +"
    //             ],
    //             "quantity": 0,
    //         },
    //         {
    //             "type": "sensor",
    //             "subtype": "Dispenser parfum",
    //             "quantity": 0,
    //         },
    //     ]
    // },
    // {
    //     "id": 116,
    //     "product_code": "803.21.83",
    //     "name": "Unitate Premium Home 500v",
    //     "type": "unit",
    //     "subtype": "home",
    //     "specifications": {
    //         "type": "unit",
    //         "subtype": "home",
    //         "version": "500v",
    //         "plus": 0,
    //         "sensors": [...basicUnitSensors, ...premiumUnitSensors]
    //     },
    //     "recommendations": [
    //         {
    //             "type": "sensor",
    //             "subtype": "Purificator Jonix",
    //             "volume": 1000,
    //             "quantity": 1,
    //         },
    //         {
    //             "type": "filter",
    //             "subtype": "home",
    //             "variant": "M5",
    //             "versions": [
    //                 "500v"
    //             ],
    //         },
    //         {
    //             "type": "sensor",
    //             "subtype": "Dispenser parfum",
    //         },
    //     ],
    //     "requirements": [
    //         {
    //             "type": "sensor",
    //             "subtype": "Purificator Jonix",
    //             "volume": 1000,
    //             "quantity": 0,
    //         },
    //         {
    //             "type": "filter",
    //             "subtype": "home",
    //             "variant": "M5",
    //             "versions": [
    //                 "500v"
    //             ],
    //             "quantity": 0,
    //         },
    //         {
    //             "type": "sensor",
    //             "subtype": "Dispenser parfum",
    //             "quantity": 0,
    //         },
    //     ]
    // },
    // {
    //     "id": 117,
    //     "product_code": "803.22.83",
    //     "name": "Unitate Premium Home 500v +",
    //     "type": "unit",
    //     "subtype": "home",
    //     "specifications": {
    //         "type": "unit",
    //         "subtype": "home",
    //         "version": "500v",
    //         "plus": 1,
    //         "sensors": [...basicUnitSensors, ...premiumUnitSensors]
    //     },
    //     "recommendations": [
    //         {
    //             "type": "sensor",
    //             "subtype": "Purificator Jonix",
    //             "volume": 1000,
    //             "quantity": 1,
    //         },
    //         {
    //             "type": "filter",
    //             "subtype": "home",
    //             "variant": "M5",
    //             "versions": [
    //                 "500v +"
    //             ],
    //         },
    //         {
    //             "type": "sensor",
    //             "subtype": "Dispenser parfum",
    //         },
    //     ],
    //     "requirements": [
    //         {
    //             "type": "sensor",
    //             "subtype": "Purificator Jonix",
    //             "volume": 1000,
    //             "quantity": 0,
    //         },
    //         {
    //             "type": "filter",
    //             "subtype": "home",
    //             "variant": "M5",
    //             "versions": [
    //                 "500v +"
    //             ],
    //             "quantity": 0,
    //         },
    //         {
    //             "type": "sensor",
    //             "subtype": "Dispenser parfum",
    //             "quantity": 0,
    //         },
    //     ]
    // },
    {
        "id": 118,
        "product_code": "803.23.83",
        "name": "Unitate Premium Home 600v",
        "type": "unit",
        "subtype": "home",
        "specifications": {
            "type": "unit",
            "subtype": "home",
            "version": "600v",
            "plus": 0,
            "sensors": [...basicUnitSensors, ...premiumUnitSensors]
        },
        "recommendations": [
            {
                "type": "sensor",
                "subtype": "Purificator Jonix",
                "volume": 1000,
                "quantity": 1,
            },
            {
                "type": "filter",
                "subtype": "home",
                "variant": "M5",
                "versions": [
                    "600v"
                ],
            },
            {
                "type": "sensor",
                "subtype": "Dispenser parfum",
            },
        ],
        "requirements": [
            {
                "type": "sensor",
                "subtype": "Purificator Jonix",
                "volume": 1000,
                "quantity": 0,
            },
            {
                "type": "filter",
                "subtype": "home",
                "variant": "M5",
                "versions": [
                    "600v"
                ],
                "quantity": 0,
            },
            {
                "type": "sensor",
                "subtype": "Dispenser parfum",
                "quantity": 0,
            },
        ]
    },
    {
        "id": 119,
        "product_code": "803.24.83",
        "name": "Unitate Premium Home 600v +",
        "type": "unit",
        "subtype": "home",
        "specifications": {
            "type": "unit",
            "subtype": "home",
            "version": "600v",
            "plus": 1,
            "sensors": [...basicUnitSensors, ...premiumUnitSensors]
        },
        "recommendations": [
            {
                "type": "sensor",
                "subtype": "Purificator Jonix",
                "volume": 1000,
                "quantity": 1,
            },
            {
                "type": "filter",
                "subtype": "home",
                "variant": "M5",
                "versions": [
                    "600v +"
                ],
            },
            {
                "type": "sensor",
                "subtype": "Dispenser parfum",
            },
        ],
        "requirements": [
            {
                "type": "filter",
                "subtype": "home",
                "variant": "M5",
                "versions": [
                    "600v +"
                ],
                "quantity": 0,
            },
            {
                "type": "sensor",
                "subtype": "Purificator Jonix",
                "volume": 1000,
                "quantity": 0,
            },
            {
                "type": "sensor",
                "subtype": "Dispenser parfum",
                "quantity": 0,
            },
        ],
    },
    {
        "id": 120,
        "product_code": "803.25.83",
        "name": "Unitate Premium Home 800v",
        "type": "unit",
        "subtype": "home",
        "specifications": {
            "type": "unit",
            "subtype": "home",
            "version": "800v",
            "plus": 0,
            "sensors": [...basicUnitSensors, ...premiumUnitSensors]
        },
        "recommendations": [
            {
                "type": "sensor",
                "subtype": "Purificator Jonix",
                "volume": 1000,
                "quantity": 1,
            },
            {
                "type": "filter",
                "subtype": "home",
                "variant": "M5",
                "versions": [
                    "800v"
                ],
            },
            {
                "type": "sensor",
                "subtype": "Dispenser parfum",
            },
        ],
        "requirements": [
            {
                "type": "filter",
                "subtype": "home",
                "variant": "M5",
                "versions": [
                    "800v"
                ],
                "quantity": 0,
            },
            {
                "type": "sensor",
                "subtype": "Purificator Jonix",
                "volume": 1000,
                "quantity": 0,
            },
            {
                "type": "sensor",
                "subtype": "Dispenser parfum",
                "quantity": 0,
            },
        ],
    },
    {
        "id": 121,
        "product_code": "803.26.83",
        "name": "Unitate Premium Home 800v +",
        "type": "unit",
        "subtype": "home",
        "specifications": {
            "type": "unit",
            "subtype": "home",
            "version": "800v",
            "plus": 1,
            "sensors": [...basicUnitSensors, ...premiumUnitSensors]
        },
        "recommendations": [
            {
                "type": "sensor",
                "subtype": "Purificator Jonix",
                "volume": 1000,
                "quantity": 1,
            },
            {
                "type": "filter",
                "subtype": "home",
                "variant": "M5",
                "versions": [
                    "800v +"
                ],
            },
            {
                "type": "sensor",
                "subtype": "Dispenser parfum",
            },
        ],
        "requirements": [
            {
                "type": "filter",
                "subtype": "home",
                "variant": "M5",
                "versions": [
                    "800v +"
                ],
                "quantity": 0,
            },
            {
                "type": "sensor",
                "subtype": "Purificator Jonix",
                "volume": 1000,
                "quantity": 0,
            },
            {
                "type": "sensor",
                "subtype": "Dispenser parfum",
                "quantity": 0,
            },
        ],
    },
    {
        "id": 122,
        "product_code": "804.01.50",
        "name": "Unitate Standard 230E",
        "type": "unit",
        "subtype": "standard",
        "specifications": {
            "type": "unit",
            "subtype": "standard",
            "version": "230E",
            "plus": 0,
            "sensors": [...basicUnitSensors, ...premiumUnitSensors]
        },
        "recommendations": [
            {
                "type": "sensor",
                "subtype": "Purificator Jonix",
                "volume": 500,
                "quantity": 1,
            },
            {
                "type": "filter",
                "subtype": "standard",
                "variant": "G4",
                "versions": ["230E"],
            },
            {
                "type": "sensor",
                "subtype": "Dispenser parfum",
            },
        ],
        "requirements": [
            {
                "type": "sensor",
                "subtype": "Purificator Jonix",
                "volume": 500,
                "quantity": 0,
            },
            {
                "type": "filter",
                "subtype": "standard",
                "variant": "G4",
                "versions": ["230E"],
                "quantity": 0,
            },
            {
                "type": "sensor",
                "subtype": "Dispenser parfum",
                "quantity": 0,
            },
        ]
    },
    {
        "id": 123,
        "product_code": "804.02.50",
        "name": "Unitate Standard 280E",
        "type": "unit",
        "subtype": "standard",
        "specifications": {
            "type": "unit",
            "subtype": "standard",
            "version": "280E",
            "plus": 0,
            "sensors": [...basicUnitSensors, ...premiumUnitSensors]
        },
        "recommendations": [
            {
                "type": "sensor",
                "subtype": "Purificator Jonix",
                "volume": 500,
                "quantity": 1,
            },
            {
                "type": "filter",
                "subtype": "standard",
                "variant": "G4",
                "versions": ["280E"],
            },
            {
                "type": "sensor",
                "subtype": "Dispenser parfum",
            },
        ],
        "requirements": [
            {
                "type": "sensor",
                "subtype": "Purificator Jonix",
                "volume": 500,
                "quantity": 0,
            },
            {
                "type": "filter",
                "subtype": "standard",
                "variant": "G4",
                "versions": ["280E"],
                "quantity": 0,
            },
            {
                "type": "sensor",
                "subtype": "Dispenser parfum",
                "quantity": 0,
            },
        ]
    },
    {
        "id": 124,
        "product_code": "804.03.50",
        "name": "Unitate Standard 590E",
        "type": "unit",
        "subtype": "standard",
        "specifications": {
            "type": "unit",
            "subtype": "standard",
            "version": "590E",
            "plus": 0,
            "sensors": [...basicUnitSensors, ...premiumUnitSensors]
        },
        "recommendations": [
            {
                "type": "sensor",
                "subtype": "Purificator Jonix",
                "volume": 1000,
                "quantity": 1,
            },
            {
                "type": "filter",
                "subtype": "standard",
                "variant": "G4",
                "versions": ["590E"],
            },
            {
                "type": "filter",
                "subtype": "standard",
                "variant": "F7",
                "versions": ["590E"],
            },
            {
                "type": "sensor",
                "subtype": "Dispenser parfum",
            },
        ],
        "requirements": [
            {
                "type": "sensor",
                "subtype": "Purificator Jonix",
                "volume": 1000,
                "quantity": 0,
            },
            {
                "type": "filter",
                "subtype": "standard",
                "variant": "G4",
                "versions": ["590E"],
                "quantity": 0,
            },
            {
                "type": "filter",
                "subtype": "standard",
                "variant": "F7",
                "versions": ["590E"],
                "quantity": 0,
            },
            {
                "type": "sensor",
                "subtype": "Dispenser parfum",
                "quantity": 0,
            },
        ]
    },
    {
        "id": 125,
        "product_code": "804.04.83",
        "name": "Unitate Premium 300h Entalpic",
        "type": "unit",
        "subtype": "enthalpic",
        "specifications": {
            "type": "unit",
            "subtype": "enthalpic",
            "version": "300h",
            "plus": 0,
            "sensors": [...basicUnitSensors, ...premiumUnitSensors]
        },
        "recommendations": [
            {
                "type": "sensor",
                "subtype": "Purificator Jonix",
                "volume": 500,
                "quantity": 1,
            },
            {
                "type": "filter",
                "subtype": "premium",
                "variant": "M5",
                "versions": [
                    "300h"
                ],
            },
            {
                "type": "sensor",
                "subtype": "Dispenser parfum",
            },
        ],
        "requirements": [
            {
                "type": "sensor",
                "subtype": "Purificator Jonix",
                "volume": 500,
                "quantity": 0,
            },
            {
                "type": "filter",
                "subtype": "premium",
                "variant": "M5",
                "versions": [
                    "300h"
                ],
                "quantity": 0,
            },
            {
                "type": "sensor",
                "subtype": "Dispenser parfum",
                "quantity": 0,
            },
        ]
    },
    {
        "id": 126,
        "product_code": "804.05.83",
        "name": "Unitate Premium 400h Entalpic",
        "type": "unit",
        "subtype": "enthalpic",
        "specifications": {
            "type": "unit",
            "subtype": "enthalpic",
            "version": "400h",
            "plus": 0,
            "sensors": [...basicUnitSensors, ...premiumUnitSensors]
        },
        "recommendations": [
            {
                "type": "sensor",
                "subtype": "Purificator Jonix",
                "volume": 500,
                "quantity": 1,
            },
            {
                "type": "filter",
                "subtype": "premium",
                "variant": "M5",
                "versions": [
                    "400h"
                ],
            },
            {
                "type": "sensor",
                "subtype": "Dispenser parfum",
            },
        ],
        "requirements": [
            {
                "type": "sensor",
                "subtype": "Purificator Jonix",
                "volume": 500,
                "quantity": 0,
            },
            {
                "type": "filter",
                "subtype": "premium",
                "variant": "M5",
                "versions": [
                    "400h"
                ],
                "quantity": 0,
            },
            {
                "type": "sensor",
                "subtype": "Dispenser parfum",
                "quantity": 0,
            },
        ]
    },
    {
        "id": 127,
        "product_code": "804.06.83",
        "name": "Unitate Premium 500h Entalpic",
        "type": "unit",
        "subtype": "enthalpic",
        "specifications": {
            "type": "unit",
            "subtype": "enthalpic",
            "version": "500h",
            "plus": 0,
            "sensors": [...basicUnitSensors, ...premiumUnitSensors]
        },
        "recommendations": [
            {
                "type": "sensor",
                "subtype": "Purificator Jonix",
                "volume": 1000,
                "quantity": 1,
            },
            {
                "type": "filter",
                "subtype": "premium",
                "variant": "M5",
                "versions": [
                    "500h"
                ],
            },
            {
                "type": "sensor",
                "subtype": "Dispenser parfum",
            },
        ],
        "requirements": [
            {
                "type": "sensor",
                "subtype": "Purificator Jonix",
                "volume": 1000,
                "quantity": 0,
            },
            {
                "type": "filter",
                "subtype": "premium",
                "variant": "M5",
                "versions": [
                    "500h"
                ],
                "quantity": 0,
            },
            {
                "type": "sensor",
                "subtype": "Dispenser parfum",
                "quantity": 0,
            },
        ]
    },
    {
        "id": 128,
        "product_code": "804.07.83",
        "name": "Unitate Premium 300v Entalpic",
        "type": "unit",
        "subtype": "enthalpic",
        "specifications": {
            "type": "unit",
            "subtype": "enthalpic",
            "version": "300v",
            "plus": 0,
            "sensors": [...basicUnitSensors, ...premiumUnitSensors]
        },
        "recommendations": [
            {
                "type": "sensor",
                "subtype": "Purificator Jonix",
                "volume": 500,
                "quantity": 1,
            },
            {
                "type": "filter",
                "subtype": "premium",
                "variant": "M5",
                "versions": [
                    "300v"
                ],
            },
            {
                "type": "sensor",
                "subtype": "Dispenser parfum",
            },
        ],
        "requirements": [
            {
                "type": "sensor",
                "subtype": "Purificator Jonix",
                "volume": 500,
                "quantity": 0,
            },
            {
                "type": "filter",
                "subtype": "premium",
                "variant": "M5",
                "versions": [
                    "300v"
                ],
                "quantity": 0,
            },
            {
                "type": "sensor",
                "subtype": "Dispenser parfum",
                "quantity": 0,
            },
        ]
    },
    {
        "id": 129,
        "product_code": "804.08.83",
        "name": "Unitate Premium 400v Entalpic",
        "type": "unit",
        "subtype": "enthalpic",
        "specifications": {
            "type": "unit",
            "subtype": "enthalpic",
            "version": "400v",
            "plus": 0,
            "sensors": [...basicUnitSensors, ...premiumUnitSensors]
        },
        "recommendations": [
            {
                "type": "sensor",
                "subtype": "Purificator Jonix",
                "volume": 500,
                "quantity": 1,
            },
            {
                "type": "filter",
                "subtype": "premium",
                "variant": "M5",
                "versions": [
                    "400v"
                ],
            },
            {
                "type": "sensor",
                "subtype": "Dispenser parfum",
            },
        ],
        "requirements": [
            {
                "type": "sensor",
                "subtype": "Purificator Jonix",
                "volume": 500,
                "quantity": 0,
            },
            {
                "type": "filter",
                "subtype": "premium",
                "variant": "M5",
                "versions": [
                    "400v"
                ],
                "quantity": 0,
            },
            {
                "type": "sensor",
                "subtype": "Dispenser parfum",
                "quantity": 0,
            },
        ]
    },
    {
        "id": 130,
        "product_code": "804.09.83",
        "name": "Unitate Premium 500v Entalpic",
        "type": "unit",
        "subtype": "enthalpic",
        "specifications": {
            "type": "unit",
            "subtype": "enthalpic",
            "version": "500v",
            "plus": 0,
            "sensors": [...basicUnitSensors, ...premiumUnitSensors]
        },
        "recommendations": [
            {
                "type": "sensor",
                "subtype": "Purificator Jonix",
                "volume": 1000,
                "quantity": 1,
            },
            {
                "type": "filter",
                "subtype": "premium",
                "variant": "M5",
                "versions": [
                    "500v"
                ],
            },
            {
                "type": "sensor",
                "subtype": "Dispenser parfum",
            },
        ],
        "requirements": [
            {
                "type": "sensor",
                "subtype": "Purificator Jonix",
                "volume": 1000,
                "quantity": 0,
            },
            {
                "type": "filter",
                "subtype": "premium",
                "variant": "M5",
                "versions": [
                    "500v"
                ],
                "quantity": 0,
            },
            {
                "type": "sensor",
                "subtype": "Dispenser parfum",
                "quantity": 0,
            },
        ]
    },
    {
        "id": 131,
        "product_code": "805.01.50",
        "name": "Senzor umiditate relativă",
        "type": "sensor",
        "subtype": "Umiditate",
        "specifications": {
            "type": "sensor",
            "subtype": "Umiditate",
        }
    },
    {
        "id": 132,
        "product_code": "805.06.83",
        "name": "Controler display TS",
        "type": "controller",
        "subtype": "display",
        "specifications": {
            "type": "controller",
            "subtype": "display",
            "version": "TS",
        }
    },
    {
        "id": 133,
        "product_code": "805.14.83",
        "name": "Suport perete Unitate Premium h",
        "type": "mount",
        "subtype": "wall",
        "specifications": {
            "type": "mount",
            "subtype": "wall",
            "variant": "Premium h",
        }
    },
    {
        "id": 134,
        "product_code": "808.10.50",
        "name": "Gratar gaurit 200x100 alb, cu magnet",
        "type": "grate",
        "subtype": "perforated",
        "specifications": {
            "type": "grate",
            "subtype": "perforated",
            "version": 200,
        }
    },
    {
        "id": 135,
        "product_code": "808.11.50",
        "name": "Gratar gaurit 300x100 alb, cu magnet",
        "type": "grate",
        "subtype": "perforated",
        "specifications": {
            "type": "grate",
            "subtype": "perforated",
            "version": 300,
        }
    },
    {
        "id": 136,
        "product_code": "808.05.50",
        "name": "Gratar Design DN100",
        "type": "grate",
        "subtype": "design",
        "specifications": {
            "type": "grate",
            "subtype": "design",
            "version": DN_VARIANTS.DN100,
        }
    },
    {
        "id": 137,
        "product_code": "808.14.50",
        "name": "Gratar Design DN125",
        "type": "grate",
        "subtype": "design",
        "specifications": {
            "type": "grate",
            "subtype": "design",
            "version": DN_VARIANTS.DN125,
        }
    },
    {
        "id": 138,
        "product_code": "808.06.50",
        "name": "Gratar Design DN150",
        "type": "grate",
        "subtype": "design",
        "specifications": {
            "type": "grate",
            "subtype": "design",
            "version": DN_VARIANTS.DN150,
        }
    },
    {
        "id": 139,
        "product_code": "808.06.50",
        "name": "Gratar refulare rotund DN125",
        "type": "grate",
        "subtype": "design",
        "specifications": {
            "type": "grate",
            "subtype": "design",
            "version": DN_VARIANTS.DN160,
        }
    },
    {
        "id": 140,
        "product_code": "810.04.63",
        "name": "Baterie electrica d.125 - 0,5 Kw (150m3)",
        "type": "heater",
        "subtype": "electric",
        "specifications": {
            "type": "heater",
            "subtype": "electric",
            "version": DN_VARIANTS.DN125,
        }
    },
    {
        "id": 141,
        "product_code": "810.05.63",
        "name": "Baterie electrica d.160 - 1,0 Kw (350m3)",
        "type": "heater",
        "subtype": "electric",
        "specifications": {
            "type": "heater",
            "subtype": "electric",
            "version": DN_VARIANTS.DN160,
        }
    },
    {
        "id": 142,
        "product_code": "810.06.63",
        "name": "Baterie electrica d.200 - 1,5 Kw (500m3)",
        "type": "heater",
        "subtype": "electric",
        "specifications": {
            "type": "heater",
            "subtype": "electric",
            "version": DN_VARIANTS.DN200,
        }
    },
    {
        "id": 143,
        "product_code": "808.09.50",
        "name": "Gratar admisie/refulare rotund DN125",
        "type": "grate",
        "subtype": "modular",
        "specifications": {
            "type": "grate",
            "subtype": "modular",
        }
    },
    {
        "id": 144,
        "product_code": "810.01.63",
        "name": "Baterie apa d.125 - 1,1 Kw (150m3)",
        "type": "sensor",
        "subtype": "Baterie apă",
        "specifications": {
            "type": "sensor",
            "subtype": "Baterie apă",
            "diameter": DN_VARIANTS.DN125,
        },
        "requirements": [
            {
                "type": "command",
                "subtype": "cooling",
                "quantity": 1,
            },
        ],
    },
    {
        "id": 145,
        "product_code": "810.02.63",
        "name": "Baterie apa d.160 - 2,2 Kw (350m3)",
        "type": "sensor",
        "subtype": "Baterie apă",
        "specifications": {
            "type": "sensor",
            "subtype": "Baterie apă",
            "diameter": DN_VARIANTS.DN160,
        },
        "requirements": [
            {
                "type": "command",
                "subtype": "cooling",
                "quantity": 1,
            },
        ],
    },
    {
        "id": 146,
        "product_code": "810.03.63",
        "name": "Baterie apa d.200 - 3,1 Kw (500m3)",
        "type": "sensor",
        "subtype": "Baterie apă",
        "specifications": {
            "type": "sensor",
            "subtype": "Baterie apă",
            "diameter": DN_VARIANTS.DN200,
        },
        "requirements": [
            {
                "type": "command",
                "subtype": "cooling",
                "quantity": 1,
            },
        ],
    },
    {
        "id": 147,
        "product_code": "813.18.91",
        "name": "Tubulatura PVC DN125 (30cm)",
        "type": "tubulatura",
        "subtype": "plenum",
        "specifications": {
            "type": "tubulatura",
            "subtype": "plenum",
        }
    },
    {
        "id": 148,
        "product_code": "805.13.83",
        "name": "Modul expansiune",
        "type": "modul",
        "subtype": "expansiune",
        "specifications": {
            "type": "modul",
            "subtype": "expansiune",
        }
    },
    {
        "id": 149,
        "product_code": "805.18.83",
        "name": "Senzor temperatura",
        "type": "sensor",
        "subtype": "temperatura",
        "specifications": {
            "type": "sensor",
            "subtype": "temperatura",
        }
    },
    {
        "id": 150,
        "product_code": "812.01.70",
        "name": "Ionizator montaj Duct 2C (500m3)",
        "type": "sensor",
        "subtype": "Purificator Jonix",
        "specifications": {
            "type": "sensor",
            "subtype": "Purificator Jonix",
            "volume": 500,
        }
    },
    {
        "id": 151,
        "product_code": "812.02.70",
        "name": "Ionizator montaj Duct 4C (1000m3)",
        "type": "sensor",
        "subtype": "Purificator Jonix",
        "specifications": {
            "type": "sensor",
            "subtype": "Purificator Jonix",
            "volume": 1000,
        }
    },
    {
        "id": 152,
        "product_code": "805.16.83",
        "name": "Suport perete unitate H",
        "type": "mount",
        "subtype": "wall",
        "specifications": {
            "type": "mount",
            "subtype": "wall",
            "variant": "horizontal",
        }
    },
    {
        "id": 154,
        "product_code": "805.20.83",
        "name": "Suport perete Unitate Premium V",
        "type": "mount",
        "subtype": "wall",
        "specifications": {
            "type": "mount",
            "subtype": "wall",
            "variant": "Premium v"
        }
    },
    {
        "id": 155,
        "product_code": "805.09.83",
        "name": "Comanda baterie racire",
        "type": "command",
        "subtype": "cooling",
        "specifications": {
            "type": "command",
            "subtype": "cooling",
        }
    },
    {
        "id": 195,
        "product_code": "807.16.91",
        "name": "Plenum dublu tavan modular - 2xDN75-DN125",
        "type": "Plenum",
        "category": "Plenum",
        "specifications": {
            "type": "Plenum",
            "subtype": 6,
            "variantKeys": [2, 1],
            "size": 1,
        },
        "requirements": [
        ],
    },
    {
        "id": 196,
        "product_code": "805.17.83",
        "name": "Suport tavan unitate H",
        "type": "support",
        "subtype": "ceiling",
        "specifications": {
            "type": "mount",
            "subtype": "ceiling",
            "variant": "horizontal",
        }
    },
    {
        "id": 197,
        "product_code": "805.15.83",
        "name": "Suport tavan Unitate Premium h",
        "type": "mount",
        "subtype": "ceiling",
        "specifications": {
            "type": "mount",
            "subtype": "ceiling",
            "variant": "Premium h",
        }
    },
    {
        "id": 198,
        "product_code": "811.01.50",
        "name": "Filtru G4 pentru unitate 180A si 230E",
        "type": "filter",
        "subtype": "standard",
        "specifications": {
            "type": "filter",
            "subtype": "standard",
            "variant": "G4",
            "versions": ["180A", "230E"],
        }
    },
    {
        "id": 199,
        "product_code": "811.02.50",
        "name": "Filtru G4 pentru unitate 280A, 350A si 280E",
        "type": "filter",
        "subtype": "standard",
        "specifications": {
            "type": "filter",
            "subtype": "standard",
            "variant": "G4",
            "versions": ["280A", "350A", "280E"],
        }
    },
    {
        "id": 200,
        "product_code": "811.03.50",
        "name": "Filtru G4 pentru unitate 400A, 550A si 580E",
        "type": "filter",
        "subtype": "standard",
        "specifications": {
            "type": "filter",
            "subtype": "standard",
            "variant": "G4",
            "versions": ["400A", "550A", "590E"],
        }
    },
    {
        "id": 201,
        "product_code": "811.05.50",
        "name": "Filtru F7 pentru unitate 280A, 350A si 280E",
        "type": "filter",
        "subtype": "standard",
        "specifications": {
            "type": "filter",
            "subtype": "standard",
            "variant": "F7",
            "versions": ["180A", "280A", "350A", "280E", "230E"],
        },
        "requirements": [
            {
                "type": "box",
                "subtype": "filter",
                "variant": "F7",
                "quantity": 0,
            }
        ],
        "recommendations": [
            {
                "type": "box",
                "subtype": "filter",
                "variant": "F7",
            },
        ],
    },
    {
        "id": 202,
        "product_code": "811.06.50",
        "name": "Filtru F7 pentru unitate 400A, 550A si 590E",
        "type": "filter",
        "subtype": "standard",
        "specifications": {
            "type": "filter",
            "subtype": "standard",
            "variant": "F7",
            "versions": ["400A", "550A", "590E"],
        },
        "requirements": [
            {
                "type": "box",
                "subtype": "filter",
                "variant": "F7",
                "quantity": 0,
            }
        ],
        "recommendations": [],
    },
    {
        "id": 203,
        "product_code": "811.08.83",
        "name": "Filtru M5 pentru unitatea Premium H 300, 400, 500 si 550",
        "type": "filter",
        "subtype": "premium",
        "specifications": {
            "type": "filter",
            "subtype": "premium",
            "variant": "M5",
            "versions": [
                "300h", "300h +",
                "300h ++", "400h",
                "400h +", "400h ++",
                "500h", "500h +",
                "500h ++",
            ],
        }
    },
    {
        "id": 204,
        "product_code": "811.20.83",
        "name": "Filtru M5 pentru unitatea Premium V 300, 400, 500 si 550",
        "type": "filter",
        "subtype": "premium",
        "specifications": {
            "type": "filter",
            "subtype": "premium",
            "variant": "M5",
            "versions": [
                "300v", "300v +", "300v ++",
                "400v", "400v +", "400v ++",
                "500v", "500v +", "500v ++",
                "550v +", "550v ++",
            ],
        }
    },
    {
        "id": 208,
        "product_code": "811.14.83",
        "name": "Filtru M5 pentru unitatea Premium Home v 300,400 si 500",
        "type": "filter",
        "subtype": "home",
        "specifications": {
            "type": "filter",
            "subtype": "home",
            "variant": "M5",
            "versions": [
                "300v", "300v +",
                "400v", "400v +",
                "500v", "500v +",
            ],
        }
    },
    {
        "id": 210,
        "product_code": "811.16.83",
        "name": "Filtru M5 pentru unitatea Premium Home v 650 si 850",
        "type": "filter",
        "subtype": "home",
        "specifications": {
            "type": "filter",
            "subtype": "home",
            "variant": "M5",
            "versions": [
                "600v", "600v +",
                "800v", "800v +"
            ],
        }
    },
    {
        "id": 212,
        "product_code": "811.18.83",
        "name": "Filtru M5 pentru unitatea Premium Home f 200",
        "type": "filter",
        "subtype": "home",
        "specifications": {
            "type": "filter",
            "subtype": "home",
            "variant": "M5",
            "versions": [
                "200f", "200f +",
            ],
        }
    },
    {
        "id": 214,
        "product_code": "805.12.83",
        "name": "Modul cascadare modbus",
        "type": "sensor",
        "subtype": "Modul modbus",
        "specifications": {
            "type": "sensor",
            "subtype": "Modul modbus",
        }
    },
    {
        "id": 215,
        "product_code": "805.19.83",
        "name": "Cablu degivrare",
        "type": "sensor",
        "subtype": "Cablu degivrare",
        "specifications": {
            "type": "sensor",
            "subtype": "Cablu degivrare",
        }
    },
    {
        "id": 216,
        "product_code": "809.38.83",
        "name": "Cutie duct",
        "type": "mounting",
        "subtype": "purifier",
        "specifications": {
            "type": "mounting",
            "subtype": "purifier",
        }
    },
    {
        "id": 217,
        "product_code": "815.01.19",
        "name": "Dispenser parfum",
        "type": "sensor",
        "subtype": "Dispenser parfum",
        "specifications": {
            "type": "sensor",
            "subtype": "Dispenser parfum",
        },
        "requirements": [
            {
                "type": "perfume",
                "subtype": "boreal",
                "variant": 120,
                "quantity": 0,
            },
            {
                "type": "perfume",
                "subtype": "cruise",
                "variant": 120,
                "quantity": 0,
            }
        ]
    },
    {
        "id": 218,
        "product_code": "811.21.50",
        "name": "Cutie suplimentara filtru F7",
        "type": "box",
        "subtype": "filter",
        "specifications": {
            "type": "box",
            "subtype": "filter",
            "variant": "F7"
        }
    },
    {
        "id": 219,
        "product_code": "815.02.19",
        "name": "Parfum Boreal 120 ml",
        "type": "perfume",
        "subtype": "boreal",
        "specifications": {
            "type": "perfume",
            "subtype": "boreal",
            "variant": 120,
        },
        "requirements": []
    },
    {
        "id": 220,
        "product_code": "815.03.19",
        "name": "Parfum Cruise 120 ml",
        "type": "perfume",
        "subtype": "cruise",
        "specifications": {
            "type": "perfume",
            "subtype": "cruise",
            "variant": 120,
        },
        "requirements": []
    },
    {
        "id": 221,
        "product_code": "809.55.32",
        "name": "Garnitura AKR 150/160",
        "type": "gasket",
        "subtype": "AKR",
        "specifications": {
            "type": "gasket",
            "subtype": "AKR",
        },
        "requirements": []
    },
    {
        "id": 222,
        "product_code": "809.55.66",
        "name": "TEU PVC DN 150",
        "type": "teu",
        "subtype": "flex",
        "specifications": {
            "type": "teu",
            "subtype": "flex",
        },
        "requirements": []
    },
    {
        "id": 39,
        "product_code": "809.02.66",
        "name": "Tub flexibil antifonic DM160 (10m)",
        "type": "Tub",
        "category": "Flexibil",
        "subtype": "antifonic",
        "specifications": {
            "variantKey": 4,
            "length": "10m",
            "type": "Tub",
            "subtype": "antifonic",
            "diameter": DN_VARIANTS.DN160,
        },
        "requirements": [
            {
                "type": "prezon",
                "subtype": "flexibil",
                "quantity": 10
            },
            {
                "type": "brida",
                "subtype": DN_VARIANTS.DN160,
                "quantity": 10,
            },
        ],
    },
    {
        "id": 223,
        "product_code": "808.15.60",
        "name": "Difuzor de ventilatie rotund DN125",
        "type": "grate",
        "subtype": "diffuser",
        "specifications": {
            "type": "grate",
            "subtype": "diffuser",
            "variant": "round",
        }
    },
    {
        "id": 224,
        "product_code": "808.17.60",
        "name": "Difuzor de ventilatie patrat DN125",
        "type": "grate",
        "subtype": "diffuser",
        "specifications": {
            "type": "grate",
            "subtype": "diffuser",
            "variant": "square",
        }
    },
    {
        "id": 225,
        "product_code": "809.34.66",
        "name": "Conector PVC DN200",
        "type": "conector",
        "category": "Conexiuni",
        "subtype": "PVC",
        "specifications": {
            "type": "conector",
            "subtype": "PVC",
            "diameter": DN_VARIANTS.DN200,
            "variantKey": 3
        }
    },
]