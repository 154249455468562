<template>
    <div class="page-not-found-container d-flex vh-100 align-items-start">
        <div class="p-5">
            <h2 v-if="isLocationNotFound">Locația specificată nu a fost găsită!</h2>
            <h2 v-else-if="isClientNotFound">Clientul specificat nu a fost găsit!</h2>
            <h2 v-else-if="isOfferNotFound">Oferta specificată nu a fost găsită!</h2>
            <h2 v-else>Pagina pe care încercați să o accesați nu a fost găsită!</h2>
            <p>Apăsați <a style="color:#4CAF50; cursor:pointer" @click="redirectToHomePage()">aici</a> pentru a fi redirecționat către pagina principală</p>
        </div>    
    </div>
</template>

<script>
export default {
    data() {
        return {
            redirectTimeout: null,
        }
    },
    computed: {
        isLocationNotFound() {
            return this.$route.query.error === 'location-not-found';
        },
        isClientNotFound() {
            return this.$route.query.error === 'client-not-found';
        },
        isOfferNotFound() {
            return this.$route.query.error === 'offer-not-found';
        }
    },
    created() {
        this.redirectTimeout = setTimeout(this.redirectToHomePage, 5000)
    },
    beforeUnmount() {
        clearTimeout(this.redirectTimeout)
    },
    methods: {
        redirectToHomePage() {
            this.$router.push('/home');
        }
    }
}
</script>
