<template>
    <v-container class="main-content d-flex align-items-center" fluid style="height: 100vh;">
        <v-row align="center" justify="center">
            <v-col cols="12" sm="8" md="4" class="d-flex align-items-center justify-content-center flex-column"
                   style="gap: 80px;">
                <v-container class="text-center d-flex flex-column">
                    <h2 class="text-white">Bun venit!</h2>
                    <span class="text-lead text-white intro-paragraph">Este responsabilitatea ta să faci clientul să te placă.</span>
                    <span class="text-lead text-white intro-paragraph">Dacă te plac o să te asculte.Dacă ascultă, vor crede.</span>
                    <span class="text-lead text-white intro-paragraph">Dacă cred vor cumpăra.</span>
                </v-container>
                <v-card elevation="12" width="100%">
                    <v-toolbar color="white" dark flat>
                        <v-toolbar-title color="white">Autentificare</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                        <v-alert type="error" v-if="error" dense>{{ error }}</v-alert>

                        <v-form @submit.prevent="handleSubmit">
                            <v-text-field
                                v-model="username"
                                label="Email"
                                prepend-icon="mdi-email"
                                type="email"
                                required
                            ></v-text-field>
                            <v-text-field
                                v-model="password"
                                label="Parola"
                                prepend-icon="mdi-lock"
                                type="password"
                                required
                            ></v-text-field>

                            <v-btn
                                :loading="loading"
                                type="submit"
                                block
                                style="background: #172b4d; color: white;"
                            >
                                Autentificare
                            </v-btn>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import apiClient, {deleteAuthToken, setAuthToken} from "@/utils/apiClient";
import {fetchData, fetchProducts, getUserData} from "@/utils/utils";

export default {
    name: 'UserLogin',
    data() {
        return {
            username: '',
            password: '',
            error: '',
            loading: false,
        }
    },
    created() {
        deleteAuthToken();
    },
    async mounted() {
        await apiClient.get('/api/clear_session_id/');
        sessionStorage.clear();
        localStorage.clear();
    },
    methods: {
        setDefaultValues() {
            //Set default values:
            let clientTypes = [
                {value: 1, text: 'Client Final'},
                {value: 2, text: 'Instalator'},
                {value: 3, text: 'Constructor'},
                {value: 4, text: 'Dezvoltator'},
                {value: 5, text: 'Magazin'},
                {value: 6, text: 'Agent'}
            ]
            sessionStorage.setItem('clientTypes', JSON.stringify(clientTypes))
        },
        async handleSubmit() {
            this.loading = true;
            const url = `/api/login/`
            const data = {
                username: this.username,
                password: this.password,
            }
            await apiClient
                .post(url, data)
                .then(async response => {

                    localStorage.setItem('token', response.data.token);
                    sessionStorage.setItem('userId', response.data.user_id);
                    sessionStorage.setItem('superUser', response.data.super_user);
                    sessionStorage.setItem('technicalUser', response.data.technical_user);
                    sessionStorage.setItem('userEmail', response.data.email);
                    if (response.data.user_id) {
                        await getUserData(this.$store, response.data.user_id);
                    }
                    this.$store.state.superUser = response.data.super_user
                    if (response.data.full_name !== '') {
                        sessionStorage.setItem('userFullName', response.data.full_name)
                    } else {
                        sessionStorage.setItem('userFullName', response.data.email)
                    }
                    setAuthToken();
                    this.setDefaultValues();
                    await fetchProducts();
                    // If the login was successful, redirect to the desired page
                    await fetchData('all').then(() => {
                        this.$router.push('/home')
                    })
                    this.loading = false;
                })
                .catch(error => {
                    // If the login failed, display an error message
                    console.log(error)
                    this.error = '  Invalid login credentials'
                    this.loading = false;
                })
        },
    },
}
</script>

<style scoped>
.main-content {
    width: 100%;
    height: 100vh;
    background: linear-gradient(177deg, #7963e4 50%, #172b4d calc(50% + 1px));
}

.intro-paragraph {
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.7;
    font-family: Open Sans, sans-serif;
}

</style>
